import Documents from "components/documents/Documents";
import { baseUrl } from "config/server";
import Video from "components/video/Video";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCourseById } from "reducers/courses";
import { eliminateDuplicates } from "utils/general";
import GoBack from "shared/go-back/GoBack";
import "./Course.scss";
import useTranslation from "utils/useTranslation";

const Course = () => {
  const { t } = useTranslation();
  const courseCode = useParams().courseCode;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCourseById(courseCode));
  }, []);

  const course = useSelector(({ courses }) => courses.singleCourse);
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";

  return (
    <div className="account-courses-single container">
      <div className="bb-dark">
        <GoBack />
      </div>

      {course && (
        <div className="account-courses-wrapper subsection-pad">
          <div className="account-courses-intro">
            <span className="category-course">
              {isIta ? course.categoryIt : course.categoryEn}
            </span>
            <h2> {isIta ? course.nameIt : course.nameEn}</h2>
            <img src={baseUrl + "/" + course.imgUrl} alt="" />
            {(isIta ? course?.descriptionIt : course?.descriptionEn)
              ?.split(";")
              .map((item, i) => (
                <p
                  className="course-description"
                  key={`key_coursedescription_${item}_${i}`}
                >
                  {item}
                </p>
              ))}
          </div>

          <Video list={course.video} />

          <Documents
            list={course.documents.filter((doc) =>
              isIta
                ? doc.categoryIt === "Pezzi di ricambio esplosi"
                : doc.categoryEn === "Exploded Spare Parts"
            )}
            title={t("ExplodedSpareParts", text)}
            subcategory={true}
          />
          <Documents
            list={course.documents.filter((doc) =>
              isIta
                ? doc.categoryIt === "Manuale d'Officina"
                : doc.categoryEn === "Workshop Manual"
            )}
            title={t("workshopManual", text)}
            subcategory={true}
          />
        </div>
      )}
    </div>
  );
};

export default Course;

const text = [
  {
    id: "workshopManual",
    en: "Workshop Manual",
    it: "Manuale D'Officina",
  },
  {
    id: "ExplodedSpareParts",
    en: "Exploded Spare Parts",
    it: "Esploso Ricambi",
  },
];
