import { Check, Close } from "assets/icons/icons";
import { Popup } from "components/popup/Popup";
import CountdownTimer from "components/timer/Timer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SingleDigitInput } from "shared/one-digit-input/OneDigitInput";
import useTranslation from "utils/useTranslation";
import "./OtpPopup.scss";

const otpInputs = [
  { target: 1 },
  { target: 2 },
  { target: 3 },
  { target: 4 },
  { target: 5 },
  { target: 6 },
];

export const OtpPopup = (props) => {
  const { t } = useTranslation();
  const errorOtp = useSelector(({ user }) => user.errorOtp);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState("unsetted");
  const [isRememberChecked, setIsRememberChecked] = useState(false);
  const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterFiveMinutes = NOW_IN_MS + FIVE_MINUTES_IN_MS;
  useEffect(() => {
    if (timeLeft === "unsetted") {
      setTimeLeft(dateTimeAfterFiveMinutes);
    }
  }, []);

  let defaultCode = otpInputs.reduce(
    (previousValue) => previousValue + "*",
    ""
  );
  const [otpCode, setOtpCode] = useState(defaultCode);

  const handleCodeChange = (target, value, e) => {
    if (value.length === 1) {
      let newCode = otpCode;
      newCode =
        newCode.substring(0, target - 1) + value + newCode.substring(target);
      setOtpCode(newCode);
    } else if (value.length > 1) {
      let numArray = value.split("");
      // if (!!otpCode[target] && otpCode[target] !== "*") {
      //   numArray.slice(1, 6);
      // }
      // setOtpCode(defaultCode);
      let newCode = otpCode;
      numArray.forEach((num, i) => {
        newCode = newCode.substring(0, i) + num + newCode.substring(i + 1);
      });
      setOtpCode(newCode.slice(0, 6));
    } else {
      let newCode = otpCode;
      newCode =
        newCode.substring(0, target - 1) + "*" + newCode.substring(target);
      setOtpCode(newCode);
    }
  };

  const handleNewOtpRequest = () => {
    setTimeLeft(dateTimeAfterFiveMinutes);
    setTimeout(() => {
      setOtpCode(defaultCode);
      setIsTimeExpired(false);
      props.handleRequest();
    }, 200);
  };

  return (
    <Popup bigPopup={true}>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!isTimeExpired && (
            <h2 style={{ marginBottom: 0 }}>{t("inserisciCodice", text)}</h2>
          )}
          {!isTimeExpired && timeLeft !== "unsetted" && (
            <CountdownTimer
              targetDate={timeLeft}
              setIsTimeExpired={setIsTimeExpired}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            fontSize: "0.9rem",
            flexWrap: "wrap",
            marginTop: "0.5rem",
          }}
        >
          {/* <Text
            label={props.mailSubtitle}
            style={{ paddingRight: 4, color: "var(--primary)" }}
          /> */}
          <p style={{ fontWeight: 600 }}>
            {/* {" "}
            <span style={{ color: "var(--primary)" }}> qua dove appare</span> */}
          </p>
        </div>

        <OtpInputBox
          otpInputs={otpInputs}
          handleCodeChange={handleCodeChange}
          disabled={isTimeExpired}
          otpCode={otpCode}
        />
        {!isTimeExpired && (
          <>
            <p
              style={{ fontSize: 12 }}
              onClick={() => props.createNewOtp()}
              className="request-otp-text"
            >
              {t("richiediAltroCodice", text)}
            </p>
            <div className="checkbox-form" style={{ paddingTop: "1rem" }}>
              <input
                checked={isRememberChecked}
                onChange={(e) => {
                  setIsRememberChecked(!isRememberChecked);
                }}
                type="checkbox"
                id="rememberOtp"
              />
              <label
                htmlFor="rememberOtp"
                style={{ marginTop: 4, marginLeft: "0.8rem" }}
              >
                {t("rememberOtp", text)}
              </label>
            </div>
          </>
        )}

        {props.errorVerification && !isTimeExpired && (
          <p
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "crimson",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {props.errorVerification}
          </p>
        )}
        {errorOtp && !isTimeExpired && (
          <p
            style={{
              paddingTop: "0.5rem",
              fontSize: "0.9rem",
              fontWeight: "500",
              color: "crimson",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {t("codiceErrato", text)}
          </p>
        )}
        {isTimeExpired && (
          <p
            style={{
              // fontSize: "0.8rem",
              fontWeight: "500",
              color: "crimson",
              textTransform: "uppercase",
              textAlign: "center",
              // paddingLeft: "56px",
            }}
          >
            {t("codiceScaduto", text)}
          </p>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
        >
          <Close
            onClick={() => props.setIsOtpPopupOpen(false)}
            width={24}
            height={24}
          />
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isTimeExpired ? (
            <div
              className={`btn primary pad ${
                !!otpCode && otpCode.includes("*") && "disabled"
              }`}
              style={{
                backgroundColor: props.verificated && "var(--secondary)",
                border: props.verificated && "1px solid var(--secondary)",
                position: "relative",
              }}
              disabled={!!otpCode && otpCode.includes("*")}
              onClick={() => {
                props.handleLoginWithOtp(otpCode, isRememberChecked);
              }}
            >
              <>
                {props.verificated ? (
                  <b>
                    {/* <Text
                      style={{ fontSize: 12, color: "var(--primary)" }}
                      label={"numeroVerificato"}
                    /> */}
                    {t("numeroVerificato", text)}
                  </b>
                ) : (
                  <b>
                    {/* <Text
                      style={{ fontSize: 12 }}
                      label={props.secondPageButtonLabel}
                    /> */}
                    {t("verifica", text)}
                  </b>
                )}
              </>

              {/* <Check
                stroke="var(--primary)"
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  right: 16,
                  top: 10,
                }}
              /> */}
            </div>
          ) : (
            <div onClick={() => props.createNewOtp()}>
              <b>
                <div className={`btn primary pad`} style={{ fontSize: 12 }}>
                  {" "}
                  {t("richiediAltroCodice", text)}
                </div>
              </b>
            </div>
          )}
        </div>
      </>
    </Popup>
  );
};

export const OtpInputBox = (props) => {
  // const setFocus = (i) => {
  //   i.current && i.current.focus();
  // };

  const handleKeyPress = (e) => {
    if (e.which === 8 && e.target.value === "") {
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else {
      if (e.target.value.length === 1 && e.which !== 8 && e.target.nextSibling)
        e.target.nextSibling.focus();
    }
  };

  const handleFocus = (e) => {
    if (e.target.value === "") {
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else {
      if (e.target.nextSibling) e.target.nextSibling.focus();
    }
  };
  return (
    <form
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {props.otpInputs.map((input) => {
        // const htmlElRef = useRef(null);
        return (
          <SingleDigitInput
            style={{ textAlign: "center" }}
            key={`key_${input.target}`}
            target={input.target}
            placeholder={input.placeholder}
            onKeyPress={handleKeyPress}
            handleChange={props.handleCodeChange}
            handleFocus={handleFocus}
            errorLabel={input.errorLabel}
            maxLength={"1"}
            value={
              props.otpCode[input.target - 1] !== "*" &&
              props.otpCode[input.target - 1]
            }
            disabled={props.disabled}
            // ref={}
          />
        );
      })}
    </form>
  );
};

const text = [
  {
    id: "codiceScaduto",
    en: "Code expired",
    it: "Codice scaduto",
  },
  {
    id: "inserisciCodice",
    en: "Insert code",
    it: "Inserisci il codice",
  },
  {
    id: "richiediAltroCodice",
    en: "Request new code",
    it: "Richiedi un altro codice",
  },
  {
    id: "codiceErrato",
    en: "Wrong code. Try again",
    it: "Codice errato. Riprova",
  },
  {
    id: "rememberOtp",
    en: "Remember for 30 days",
    it: "Ricorda per 30 giorni",
  },
];
