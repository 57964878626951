import { useState, useEffect, useRef } from "react";

const useComponentVisible = (initialIsVisible, esc) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const toggling = () => setIsComponentVisible((state) => !state);

  const handleEsc = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (!ref.current?.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    esc && document.addEventListener("keydown", handleEsc, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      esc && document.removeEventListener("keydown", handleEsc, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible, toggling };
};

export default useComponentVisible;
