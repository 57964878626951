import { File } from "assets/icons/icons";
import Select from "components/select/Select";
import { options } from "debug-items/select-options";
import useTranslation from "utils/useTranslation";
import { eliminateDuplicates } from "utils/general";
import "./Software.scss";
import { Popup } from "components/popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { baseUrl } from "config/server";
import { insertUserHistoryDownload } from "reducers/history";

const Software = ({ list, title }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const dispatch = useDispatch();
  const account = useSelector(({ user }) => user.data.user);
  const userHasDownload = useSelector(
    ({ user }) => user.data.user?.hasDownload
  );
  const options = list?.map((element, i) =>
    isIta ? element?.categoryIt : element?.categoryEn
  );
  const filteredOptions = eliminateDuplicates(options);

  const handleSetDownload = (content) => {
    dispatch(insertUserHistoryDownload(content));
  };

  return (
    <>
      {list?.length > 0 && (
        <div className="product-page-software dpv-section">
          {!!isPopupOpen && (
            <Popup handleClose={() => setIsPopupOpen(false)}>
              <h2 style={{ marginBottom: "1.5rem" }}>
                {t("attenzione", text)}
              </h2>
              <p>{t("attenzioneDescrizione", text)}</p>
            </Popup>
          )}
          <h2>{title || "software"}</h2>
          <div className="software-list">
            {list?.map((software, i) => (
              <div
                key={`key_softwarelist_${software.id}`}
                className="software-list__item bb-dark"
              >
                <div className="software-left">
                  <File />
                  <h4 className="no-mar">
                    {isIta ? software.nameIt : software.nameEn}
                  </h4>
                </div>
                {!!userHasDownload ? (
                  <a
                    href={baseUrl + "/" + software.softwareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="btn primary pad hover"
                    onClick={() =>
                      handleSetDownload({
                        userId: account.userId,
                        contentId: software.id,
                        contentType: "software",
                      })
                    }
                  >
                    download
                  </a>
                ) : (
                  <div
                    onClick={() => setIsPopupOpen(true)}
                    className="btn primary pad hover"
                  >
                    download
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Software;

const text = [
  {
    id: "attenzione",
    en: "Attention",
    it: "Attenzione",
  },
  {
    id: "attenzioneDescrizione",
    it: "Non sei autorizzato a scaricare il file. Contatta l'assistenza Suex.",
    en: "You are not authorized to download the file. Contact Suex support.",
  },
];
