import "./Intro.scss";
import GoBack from "shared/go-back/GoBack";
import useTranslation from "utils/useTranslation";
import { FullScreen } from "assets/icons/icons";
import Rotation from "react-rotation";
import { useSelector } from "react-redux";
import { baseUrl } from "config/server";

const Intro = ({ product, handleOpen }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  // const [imgBase64, setImgBase64] = useState("");
  const gallery3D = product?.gallery.filter((item) =>
    item.image?.includes("3D")
  );

  return (
    <div className="dpv__intro dpv-section" style={{ marginBottom: "2rem" }}>
      <GoBack />
      <div className="intro-dpv">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {gallery3D.length > 0 ? (
            <div style={{ position: "relative" }}>
              <Rotation
                cycle={true}
                className={`intro-dpv__img ${
                  product.productGroup.includes("DPD") && "scale"
                } ${
                  (product.productCode === "72292" ||
                    product.productCode === "72294" ||
                    product.productCode === "72007" ||
                    product.productCode === "72295") &&
                  "scale-more"
                } ${
                  (product.productCode === "72293" ||
                    product.productCode === "72296") &&
                  "scale-more-less"
                }`}
              >
                {gallery3D.map((item, i) => (
                  <img
                    id={`product-image_${i}`}
                    key={`key_gallery3dimage_${i}`}
                    src={`${baseUrl}/${item.image}`}
                    alt=""
                  />
                ))}
              </Rotation>
              <div className="drag pulse">
                <h4>DRAG</h4>
              </div>
            </div>
          ) : (
            <img
              id="product-image_0"
              src={`${baseUrl}/images/${product.productCode}.png`}
              alt=""
            />
          )}
          <FullScreen
            onClick={() => handleOpen()}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              cursor: "pointer",
              fill: "#fff",
              backgroundColor: "#4c4c4c",
              borderRadius: "50%",
              padding: 4,
            }}
            width={32}
            height={32}
          />
        </div>

        <div className="intro-dpv__detail">
          <div className="detail-dpv__heading">
            {activeLanguage === "it" ? (
              <h1>{product.nameIt}</h1>
            ) : (
              <h1>{product.nameEn}</h1>
            )}

            <span className="detail-dpv__code">Cod. {product.productCode}</span>
          </div>

          <p className="detail-dpv__description">
            {activeLanguage === "it" ? (
              <div
                dangerouslySetInnerHTML={{ __html: product.descriptionIt }}
                className="description-product"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "5px",
                }}
              ></div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "5px",
                }}
                className="description-product"
                dangerouslySetInnerHTML={{ __html: product.descriptionEn }}
              ></div>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Intro;
