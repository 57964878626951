import { useDispatch, useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "./AdminPanel.scss";
import { useEffect, useState } from "react";
import { getAllUsers, resetUserPassword, updateUser } from "reducers/user";
import { Popup } from "components/popup/Popup";
import RequestAccess from "views/welcome/navbar/request-access/RequestAccess";
import { Email, Search } from "assets/icons/icons";
import { getAllContacts } from "reducers/contacts";
import { getAllCourses, getCoursesByDistributorId } from "reducers/courses";
import { getAllLevels } from "reducers/levels";
import RequestRow from "./panel-components/RequestRow";
import UserRow from "./panel-components/UserRow";
import {
  getAllPricelist,
  getPricelistByDistributorId,
} from "reducers/priceList";
import { getAllFirmware, getFirmwareByDistributorId } from "reducers/firmware";
import Placeholders from "components/placeholder/Placeholder";
import {
  getAllDocuments,
  getAllDocumentsCourses,
  getDocumentsByDistributorId,
  getDocumentsCoursesByDistributorId,
} from "reducers/documents";
import { getAllSoftware, getSoftwareByDistributorId } from "reducers/software";

const AdminPanel = () => {
  const [filterUser, setFilterUser] = useState("");
  const account = useSelector(({ user }) => user.data.user);
  const userList = useSelector(({ user }) => user.userList);
  const courses = useSelector(({ courses }) => courses.data);
  const firmware = useSelector(({ firmware }) => firmware.data);
  const software = useSelector(({ software }) => software.data);
  const documents = useSelector(({ documents }) => documents.data);
  const documentCourses = useSelector(({ documents }) => documents.docsCourses);
  const pricelist = useSelector(({ pricelist }) => pricelist.data);
  const contactsList = useSelector(({ contacts }) =>
    contacts.contactsList.filter((contract) => contract.status === "pending")
  );

  const isDistributor = userList?.every(
    (user) => user.distributorId === account?.userId
  );
  const distributorCourses = useSelector(
    ({ courses }) => courses.distributorCourses
  );
  const distributorDocuments = useSelector(
    ({ documents }) => documents.distributorDocuments
  );
  const distributorDocumentsCourses = useSelector(
    ({ documents }) => documents.distributorDocsCourses
  );
  const distributorFirmware = useSelector(
    ({ firmware }) => firmware.distributorFirmware
  );
  const distributorSoftware = useSelector(
    ({ software }) => software.distributorSoftware
  );
  const distributorPricelist = useSelector(
    ({ pricelist }) => pricelist.distributorPricelist
  );

  const [onlyActiveUser, setOnlyActiveUser] = useState(true);
  const [isRequestComponentVisible, setIsRequestComponentVisible] =
    useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const FilteredUsers = userList?.filter((user) => {
    return (
      user.firstname
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterUser?.toLowerCase()) ||
      user.lastname
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterUser?.toLowerCase()) ||
      user.email
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterUser?.toLowerCase()) ||
      user.distributorId
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterUser?.toLowerCase()) ||
      user?.distributorName
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterUser?.toLowerCase())
    );
  });

  const RemovedSelfAccountUsers = FilteredUsers?.filter(
    (user) => user.userId !== account?.userId
  );

  useEffect(() => {
    if (!!account) {
      if (account?.role === "user" && isDistributor) {
        dispatch(getCoursesByDistributorId(account?.userId));
        dispatch(getFirmwareByDistributorId(account?.userId));
        dispatch(getSoftwareByDistributorId(account?.userId));
        dispatch(getDocumentsByDistributorId(account?.userId));
        dispatch(getDocumentsCoursesByDistributorId(account?.userId));
        dispatch(getPricelistByDistributorId(account?.userId));
      } else if (account?.role === "admin") {
        dispatch(getAllUsers());
        dispatch(getAllContacts());
        dispatch(getAllCourses());
        dispatch(getAllLevels());
        dispatch(getAllFirmware());
        dispatch(getAllSoftware());
        dispatch(getAllDocuments());
        dispatch(getAllDocumentsCourses());
        dispatch(getAllPricelist());
      }
    }
  }, [account]);

  //Raggruppare i firmware con lo stesso firmwareCode
  const firmwareGrouped = firmware.reduce((acc, curr) => {
    const found = acc.find((item) => item.firmwareCode === curr.firmwareCode);
    if (found) {
      found.firmwareUrl = curr.firmwareUrl;
      if (found.productCode.includes(curr.productCode)) return acc;
      found.productCode = found.productCode + " & " + curr.productCode;
      return acc;
    } else {
      return [...acc, curr];
    }
  }, []);

  //Raggruppare i firmware con lo stesso firmwareCode
  const distributorFirmwareGrouped = distributorFirmware.reduce((acc, curr) => {
    const found = acc.find((item) => item.firmwareCode === curr.firmwareCode);
    if (found) {
      found.firmwareUrl = curr.firmwareUrl;
      if (found.productCode.includes(curr.productCode)) return acc;
      found.productCode = found.productCode + " & " + curr.productCode;
      return acc;
    } else {
      return [...acc, curr];
    }
  }, []);

  const deactivateUser = (userId) => {
    dispatch(updateUser(userId, { isActive: false }));
  };
  const activateUser = (userId) => {
    dispatch(updateUser(userId, { isActive: true }));
  };
  const resetPassword = (user) => {
    dispatch(resetUserPassword(user));
  };

  return (
    <div className="admin-panel" style={{ gap: "0px" }}>
      {userList?.length > 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            className="btn primary short-pad space"
            onClick={() =>
              setIsRequestComponentVisible(!isRequestComponentVisible)
            }
          >
            <Email style={{ stroke: "#fff", fill: "#fff" }} />
            {t("requestFromDistributor", text)}
          </div>
        </div>
      )}

      {isRequestComponentVisible && (
        <Popup
          requestPopup={true}
          handleClose={() => setIsRequestComponentVisible(false)}
        >
          <RequestAccess distributorId={account.id} />
        </Popup>
      )}
      {/* ELENCO UTENTI */}
      <>
        <div
          className="admin-panel__title-container"
          style={{ marginBottom: "0px" }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <h3>{t("elencoUtenti", text)}</h3>
            <p
              className="admin-panel__title-container__show-toggle"
              onClick={() => setOnlyActiveUser(!onlyActiveUser)}
            >
              {onlyActiveUser
                ? t("mostraUtentiDisattivati", text)
                : t("nascondiUtentiDisattivati", text)}
            </p>
          </div>
          <div
            className="admin-panel__title-container admin-panel__title-container__title-search-container"
            style={{
              width: "50%",
              justifyContent: "flex-end",
              marginTop: "0px",
            }}
          >
            <div className="searchbar-container">
              <input
                type="text"
                value={filterUser}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterUser(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
        </div>

        {RemovedSelfAccountUsers?.length > 0 ? (
          <div>
            {RemovedSelfAccountUsers?.length < 4 ? (
              <div>
                {RemovedSelfAccountUsers.filter((user) =>
                  onlyActiveUser ? !!user.isActive : user
                ).map((user, i) => (
                  <UserRow
                    key={`key_user_${i}_${user.id}`}
                    user={user}
                    account={account}
                    isDistributor={isDistributor}
                    deactivateUser={deactivateUser}
                    activateUser={activateUser}
                    resetPassword={resetPassword}
                    courses={
                      account?.role === "admin"
                        ? courses
                        : isDistributor
                        ? distributorCourses
                        : []
                    }
                    firmware={
                      account?.role === "admin"
                        ? firmwareGrouped
                        : isDistributor
                        ? distributorFirmwareGrouped
                        : []
                    }
                    software={
                      account?.role === "admin"
                        ? software
                        : isDistributor
                        ? distributorSoftware
                        : []
                    }
                    pricelist={
                      account?.role === "admin"
                        ? pricelist
                        : isDistributor
                        ? distributorPricelist
                        : []
                    }
                    documents={
                      account?.role === "admin"
                        ? documents
                        : isDistributor
                        ? distributorDocuments
                        : []
                    }
                    documentsCourses={
                      account?.role === "admin"
                        ? documentCourses
                        : isDistributor
                        ? distributorDocumentsCourses
                        : []
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="overflow-pagination-user">
                {RemovedSelfAccountUsers.filter((user) =>
                  onlyActiveUser ? !!user.isActive : user
                ).map((user, i) => (
                  <UserRow
                    key={`key_user_2_${user.id}_${i}`}
                    user={user}
                    account={account}
                    isDistributor={isDistributor}
                    deactivateUser={deactivateUser}
                    activateUser={activateUser}
                    resetPassword={resetPassword}
                    courses={
                      account?.role === "admin"
                        ? courses
                        : isDistributor
                        ? distributorCourses
                        : []
                    }
                    firmware={
                      account?.role === "admin"
                        ? firmwareGrouped
                        : isDistributor
                        ? distributorFirmwareGrouped
                        : []
                    }
                    software={
                      account?.role === "admin"
                        ? software
                        : isDistributor
                        ? distributorSoftware
                        : []
                    }
                    pricelist={
                      account?.role === "admin"
                        ? pricelist
                        : isDistributor
                        ? distributorPricelist
                        : []
                    }
                    documents={
                      account?.role === "admin"
                        ? documents
                        : isDistributor
                        ? distributorDocuments
                        : []
                    }
                    documentsCourses={
                      account?.role === "admin"
                        ? documentCourses
                        : isDistributor
                        ? distributorDocumentsCourses
                        : []
                    }
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <Placeholders type={"userRow"} number={4} />
        )}
      </>

      {contactsList?.length > 0 && (
        <>
          <div className="admin-panel__title-container">
            <h3>{t("utentiAttesa", text)}</h3>
          </div>
          {contactsList?.length < 6 ? (
            <div>
              {contactsList.map((contact, i) => (
                <RequestRow
                  key={`key_contact_${i}_${contact.id}`}
                  contact={contact}
                />
              ))}
            </div>
          ) : (
            <div className="overflow-pagination">
              {contactsList.map((contact, i) => (
                <RequestRow
                  key={`key_contact_2_${contact.id}_${i}`}
                  contact={contact}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminPanel;

const text = [
  {
    id: "elencoUtenti",
    it: "Elenco Utenti",
    en: "Users List",
  },

  {
    id: "utentiAttesa",
    it: "Utenti in attesa di approvazione",
    en: "Users waiting for approval",
  },
  {
    id: "registeredFrom",
    en: "User registered from",
    it: "Utente registrato dal",
  },
  {
    id: "level",
    en: "Level",
    it: "Livello",
  },
  {
    id: "disattiva",
    it: "Disattiva",
    en: "Disable",
  },
  {
    id: "attiva",
    it: "Attiva",
    en: "Enable",
  },
  {
    id: "disattivaCorso",
    it: "Disattiva corso",
    en: "Disable course",
  },
  {
    id: "disattivato",
    it: "Disattivato",
    en: "Disabled",
  },
  {
    id: "corsoDisattivato",
    it: "Corso Disattivato",
    en: "Course Disabled",
  },
  {
    id: "vediCorsi",
    it: "Vedi Corsi",
    en: "View Courses",
  },
  {
    id: "mostraUtentiDisattivati",
    it: "Mostra utenti disattivati",
    en: "Show disabled users",
  },
  {
    id: "nascondiUtentiDisattivati",
    it: "Nascondi utenti disattivati",
    en: "Hide disabled users",
  },
  {
    id: "confermaDisattivazione",
    it: "Sei sicuro di voler disattivare l'utente? Questa operazione non può essere annullata.",
    en: "Are you sure you want to disable the user? This operation cannot be undone.",
  },
  {
    id: "confermaRifiuto",
    it: "Sei sicuro di voler rifutare la richiesta ? Questa operazione non può essere annullata.",
    en: "Are you sure you want to refuse the request? This operation cannot be undone.",
  },
  {
    id: "requestFromDistributor",
    it: "richiedi accesso nuovo utente",
    en: "request access new user",
  },
  {
    id: "richiestaFatta",
    it: "Richiesta fatta il",
    en: "Request made on",
  },
  {
    id: "richiestaDa",
    it: "Richiesta da",
    en: "Request from",
  },
  {
    id: "nuovoUtente",
    it: "Nuovo Utente",
    en: "New User",
  },
  {
    id: "rinnovo",
    it: "Rinnovo",
    en: "Renew",
  },
  {
    id: "attivaCorso",
    it: "Attiva corso",
    en: "Enable course",
  },
  {
    id: "richiestaDaUtente",
    it: "Richiesta da Utente",
    en: "Request from user",
  },
  {
    id: "richiestaDaDistributore",
    it: "Richiesta da distributore",
    en: "Request from ditributor",
  },
  {
    id: "firstname",
    it: "nome",
    en: "firstname",
  },
  {
    id: "lastname",
    it: "cognome",
    en: "lastname",
  },
  {
    id: "distributorName",
    it: "Nome distributore",
    en: "Distributor Name",
  },
  {
    id: "email",
    it: "email",
    en: "Email",
  },
  {
    id: "companyName",
    it: "Nome azienda",
    en: "Company name",
  },
  {
    id: "address",
    it: "Indirizzo",
    en: "Address",
  },
  {
    id: "telefono",
    it: "Telefono fisso",
    en: "phone",
  },
  {
    id: "cellulare",
    it: "Cellulare",
    en: "mobile",
  },
  {
    id: "postalCode",
    it: "Codice postale",
    en: "Zip Address",
  },
  {
    id: "city",
    it: "Città",
    en: "city",
  },
  {
    id: "country",
    it: "Nazione",
    en: "country",
  },
  {
    id: "text",
    it: "Testo richiesta",
    en: "Request text",
  },
  {
    id: "scegliLivello",
    it: "Scegli livello dell'utente",
    en: "Choose user level",
  },
  {
    id: "dataRichiesta",
    it: "Data richiesta",
    en: "Request date",
  },
  {
    id: "rinnova",
    it: "Rinnova",
    en: "Renew",
  },
];
