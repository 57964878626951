import {
  Email,
  Facebook,
  Location,
  Network,
  Phone,
  User,
} from "assets/icons/icons";

export const infoDealer = [
  {
    title: "firstname",
    img: <User />,
  },
  {
    title: "city",
    img: <Location />,
  },
  {
    title: "phone",
    img: <Phone />,
  },
  {
    title: "email",
    img: <Email />,
  },
];
