import axios from "axios";
import api from "../api/index";
var CryptoJS = require("crypto-js");

const GET_ALL_USERS_DOWNLOAD_HISTORY_REQUEST =
  "SUEX_MILITARY/GET_ALL_USERS_DOWNLOAD_HISTORY_REQUEST";
const GET_ALL_USERS_DOWNLOAD_HISTORY_SUCCESS =
  "SUEX_MILITARY/GET_ALL_USERS_DOWNLOAD_HISTORY_SUCCESS";
const GET_ALL_USERS_DOWNLOAD_HISTORY_FAILURE =
  "SUEX_MILITARY/GET_ALL_USERS_DOWNLOAD_HISTORY_FAILURE";

const INSERT_USER_DOWNLOAD_HISTORY_REQUEST =
  "SUEX_MILITARY/INSERT_USER_DOWNLOAD_HISTORY_REQUEST";
const INSERT_USER_DOWNLOAD_HISTORY_SUCCESS =
  "SUEX_MILITARY/INSERT_USER_DOWNLOAD_HISTORY_SUCCESS";
const INSERT_USER_DOWNLOAD_HISTORY_FAILURE =
  "SUEX_MILITARY/INSERT_USER_DOWNLOAD_HISTORY_FAILURE";

const initialState = {
  loading: false,
  error: false,
  userList: [],
};

const reducerHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_DOWNLOAD_HISTORY_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_USERS_DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
      };
    case GET_ALL_USERS_DOWNLOAD_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        userList: initialState.userList,
        error: action.payload,
      };
    case INSERT_USER_DOWNLOAD_HISTORY_REQUEST:
      return { ...state, loading: true, error: false };

    case INSERT_USER_DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case INSERT_USER_DOWNLOAD_HISTORY_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return state;
  }
};

export default reducerHistory;

export const getAllUsersDownloadHistory =
  ({ contentType, contentId }) =>
  async (dispatch) => {
    dispatch({
      type: GET_ALL_USERS_DOWNLOAD_HISTORY_REQUEST,
    });
    try {
      const { url, method, headers } = api.getAllUsersDownloadHistory(
        contentType,
        contentId
      );

      const response = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_USERS_DOWNLOAD_HISTORY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_DOWNLOAD_HISTORY_FAILURE,
        payload: error,
      });
    }
  };
export const insertUserHistoryDownload = ({
  userId,
  contentType,
  contentId,
}) => {
  return async (dispatch) => {
    dispatch({
      type: INSERT_USER_DOWNLOAD_HISTORY_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.insertUserHistoryDownload(
        userId,
        contentType,
        contentId
      );

      let history_download = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: INSERT_USER_DOWNLOAD_HISTORY_SUCCESS,
        payload: history_download.data,
      });

      return history_download.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: INSERT_USER_DOWNLOAD_HISTORY_FAILURE,
        payload: error.response.data,
      });
    }
  };
};
