import axios from "axios";
import api from "../api/index";
var CryptoJS = require("crypto-js");

const SEND_HELP_MESSAGE_REQUEST = "SUEX_MILITARY/SEND_HELP_MESSAGE_REQUEST";
const SEND_HELP_MESSAGE_SUCCESS = "SUEX_MILITARY/SEND_HELP_MESSAGE_SUCCESS";
const SEND_HELP_MESSAGE_FAILURE = "SUEX_MILITARY/SEND_HELP_MESSAGE_FAILURE";

const initialState = {
  loading: false,
  error: false,
  messageCreated: false,
};

const reducerHelp = (state = initialState, action) => {
  switch (action.type) {
    case SEND_HELP_MESSAGE_REQUEST:
      return { ...state, loading: true, error: false };

    case SEND_HELP_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        messageCreated: action.payload,
      };

    case SEND_HELP_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        messageCreated: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducerHelp;

export const sendHelpMessage = (helpData, userEmail) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_HELP_MESSAGE_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.sendHelpMessage(
        helpData,
        userEmail
      );

      let helpMessageData = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: SEND_HELP_MESSAGE_SUCCESS,
        payload: helpMessageData.data,
      });

      return helpMessageData.data;
    } catch (error) {
      console.log("error", error.response);

      dispatch({
        type: SEND_HELP_MESSAGE_FAILURE,
        payload: "error",
      });
    }
  };
};

export const resetMessageCreated = () => {
  return (dispatch) => {
    dispatch({
      type: SEND_HELP_MESSAGE_SUCCESS,
      payload: false,
    });
  };
};
