import axios from "axios";
import api from "../api/index";

const GET_ALL_PRICELIST_REQUEST = "SUEX_MILITARY/GET_ALL_PRICELIST_REQUEST";
const GET_ALL_PRICELIST_SUCCESS = "SUEX_MILITARY/GET_ALL_PRICELIST_SUCCESS";
const GET_ALL_PRICELIST_FAILURE = "SUEX_MILITARY/GET_ALL_PRICELIST_FAILURE";

const GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_REQUEST";
const GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_FAILURE";

const UPDATE_PRICELIST_REQUEST = "SUEX_MILITARY/UPDATE_PRICELIST_REQUEST";
const UPDATE_PRICELIST_SUCCESS = "SUEX_MILITARY/UPDATE_PRICELIST_SUCCESS";
const UPDATE_PRICELIST_FAILURE = "SUEX_MILITARY/UPDATE_PRICELIST_FAILURE";

const GET_PRICELIST_BY_ID_REQUEST = "SUEX_MILITARY/GET_PRICELIST_BY_ID_REQUEST";
const GET_PRICELIST_BY_ID_SUCCESS = "SUEX_MILITARY/GET_PRICELIST_BY_ID_SUCCESS";
const GET_PRICELIST_BY_ID_FAILURE = "SUEX_MILITARY/GET_PRICELIST_BY_ID_FAILURE";

const initialState = {
  error: false,
  loading: false,
  data: [],
  distributorPricelist: [],
};

export default function reducerPricelist(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRICELIST_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_PRICELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ALL_PRICELIST_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorPricelist: action.payload,
      };

    case GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        distributorPricelist: [],
        error: action.payload,
      };

    case UPDATE_PRICELIST_REQUEST:
      return { ...state, loading: true, error: false };

    case UPDATE_PRICELIST_SUCCESS:
      const updatedData = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        data: updatedData,
      };

    case UPDATE_PRICELIST_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const getAllPricelist = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_PRICELIST_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllPricelist();

      let pricelist = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_PRICELIST_SUCCESS,
        payload: pricelist.data,
      });

      return pricelist.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_PRICELIST_FAILURE,
        payload: error,
      });
    }
  };
};

export const getPricelistByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_REQUEST,
    });

    try {
      const { url, method, headers } =
        api.getPricelistByDistributorId(distributorId);

      let pricelist = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_SUCCESS,
        payload: pricelist.data,
      });

      return pricelist.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_PRICELIST_BY_DISTRIBUTOR_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getPricelistById = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PRICELIST_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getPricelistById(userId);

      let pricelist = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_PRICELIST_BY_ID_SUCCESS,
        payload: pricelist.data,
      });

      return pricelist.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_PRICELIST_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const updatePricelist = (id, updateData) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PRICELIST_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.updatePricelist(
        id,
        updateData
      );

      let pricelist = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_PRICELIST_SUCCESS,
        payload: pricelist.data,
      });

      return pricelist.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: UPDATE_PRICELIST_FAILURE,
        payload: error,
      });
    }
  };
};
