import React from "react";
import { Close } from "assets/icons/icons";

import "./Popup.scss";

export const Popup = (props) => {
  return (
    <div className="popup-container">
      <div
        className={
          props.requestPopup
            ? "popup request-popup"
            : props.bigPopup
            ? "big popup"
            : "popup"
        }
      >
        {props.handleClose && (
          <Close className="close-icon" onClick={() => props.handleClose()} />
        )}
        {props.children}
      </div>
    </div>
  );
};
