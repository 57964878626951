import { File } from "assets/icons/icons";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "./Specs.scss";
import { downloadDatasheet } from "reducers/documents";

const specs = [
  "productCode",
  "maxStaticThrust",
  "topSpeed",
  "runTimeFullTrigger",
  "rangeFullTrigger",
  "cruiseSpeed",
  "runTimeCruiseSpeed",
  "rangeCruiseSpeed",
  "maxOperationalDepth",
  "length",
  "width",
  "height",
  "weightWithBattery",
  "weightWithoutBattery",
  "inWaterUsageTemperature",
  "materialType",
  "batteryType",
  "nominalCapacity", //batteryNominalCapacity
  "nominalVoltage", //batteryNominalVoltage
  "maxRechargingTime",
  "chargerPowerSupply",
  "buoyancyTrim",

  // Cambiato ordine di quelli sopra, questi sotto non sono stati toccati
  "unitOfMeasure",
  "widthWithCases",
  "widthFolded",
  "lengthWithCases",
  "heightWithCases",
  "bodyDiameter",
  "weight",
  "weightMainPartsInAir",
  "weightWithBatterySaltWater",
  "weightWithCases",
  "testDepth",
  "pressureSensorType",
  "accuracyCalibratedRange",
  "calibratedPressureRange",
  "dpvInterface",
  "inAirUsageTemperature",
  "storageTemperature",
  "batterySize",
  "suitableBatteryType",
  "batteryOperatingLife",
  "maxVoltage",
  "maxChargingCurrent",
  "chargerCode",
  "plugExternalSupply",
  "frequencyBands",
  "byPass",
  "diveLogCapacity",
  "connectivity",
  "bluetoothSwitch",
  "bluetoothConnectionMotor",
  "bluetoothConnectionBattery",
  "compassResolution",
  "compassAccuracy",
  "compassTiltCompensation",
  "cutOffInside",
  "wristAttachment",
  "oringSize",
  "oringMaterial",
  "display",
  "displayType",
  "displayDimension",
];

const Specs = (props) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const user = useSelector(({ user }) => user.data.user);
  const dispatch = useDispatch();

  const renderData = (data) => {
    if (data === 1) {
      return t("yes", text);
    } else if (data === 0) {
      return t("no", text);
    } else {
      return data;
    }
  };

  const handleGetDatasheet = (productCode, language) => {
    dispatch(downloadDatasheet(productCode, user.userId, language));
  };

  const numberofSpecs = specs.filter((spec) => !!props.product[spec]).length;

  return (
    <>
      {numberofSpecs > 2 ? (
        <>
          {specs.find((spec) => !!props.product[spec]) && (
            <div className="dpv__specs dpv-section">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h2>{t("specs", text)}</h2>
                <div
                  className="btn tertiary short-pad left"
                  onClick={() =>
                    handleGetDatasheet(
                      props.product.productCode,
                      activeLanguage
                    )
                  }
                  style={{ marginBottom: "2.5rem", marginLeft: "1rem" }}
                >
                  <File />
                  <span>{t("datasheet", text)}</span>
                </div>
                {/* <a
                  href={`https://api.so.suex.it/catalogues/${props.product.productCode}-${activeLanguage}.pdf`}
                  target="_blank"
                  rel="noreferrer"
                  download
                  className="btn tertiary short-pad left"
                  style={{ marginBottom: "2.5rem", marginLeft: "1rem" }}
                >
                  <File />
                  <span>{t("datasheet", text)}</span>
                </a> */}
              </div>
              <div className="dpv-specs-list">
                {specs.map((spec, i) => {
                  return (
                    <Fragment key={`key_specs_${spec}_${i}`}>
                      {props.product[spec] !== null && (
                        <div className="specs-list-item bb-dark">
                          <h4 className="no-mar">{t(spec, text)}</h4>
                          <div className="spec-value">
                            {renderData(props.product[spec])}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Specs;

const text = [
  { id: "datasheet", en: "Datasheet", it: "Scheda tecnica" },
  { id: "productCode", en: "Code", it: "Codice" },
  { id: "width", en: "Width", it: "Larghezza" },
  {
    id: "widthWithCases",
    en: "Width (with case)",
    it: "Larghezza (con valigia/contenitore)",
  },
  { id: "widthFolded", en: "WidthFolded", it: "Larghezza da piegato" },
  { id: "length", en: "Length", it: "Lunghezza" },
  {
    id: "lengthWithCases",
    en: "Lenght (with case)",
    it: "Lunghezza (con valigia/contenitore)",
  },
  { id: "height", en: "Height", it: "Altezza" },
  {
    id: "heightWithCases",
    en: "Height (with case) ",
    it: "Altezza (con valigia/contenitore)",
  },
  { id: "bodyDiameter", en: "Body diameter", it: "Diametro corpo" },
  {
    id: "weight",
    en: "Weight",
    it: "Peso",
  },
  {
    id: "weightMainPartsInAir",
    en: "Weight main parts (In air)",
    it: "Peso componenti principali (in aria)",
  },
  {
    id: "weightWithoutBattery",
    en: "Weight without battery",
    it: "Peso totale s/batteria",
  },
  {
    id: "weightWithBattery",
    en: "Weight with battery",
    it: "Peso totale c/batteria",
  },
  {
    id: "weightWithBatterySaltWater",
    en: "Weight with battery for salt water",
    it: "Peso c/batteria per acqua salata",
  },
  {
    id: "weightWithCases",
    en: "Weight with cases",
    it: "Peso (con valigia/contenitore) ",
  },
  {
    id: "unitOfMeasure",
    en: "Unit of measure",
    it: "Unità di misura",
  },
  { id: "materialType", en: "Material type", it: "Tipo di materiale" },
  {
    id: "maxStaticThrust",
    en: "Max static thrust",
    it: "Spinta iniziale max",
  },
  { id: "topSpeed", en: "Top speed", it: "Velocità max" },
  { id: "cruiseSpeed", en: "CruiseSpeed", it: "Velocità di crociera" },
  {
    id: "rangeFullTrigger",
    en: "Range full trigger",
    it: "Range a velocità max",
  },
  {
    id: "runTimeFullTrigger",
    en: "Run time full trigger",
    it: "Autonomia a velocità max",
  },
  {
    id: "rangeCruiseSpeed",
    en: "Range cruise speed",
    it: "Range a velocità di crociera",
  },
  {
    id: "runTimeCruiseSpeed",
    en: "Run time cruise speed",
    it: "Autonomia a velocità di crociera",
  },
  {
    id: "maxOperationalDepth",
    en: "Max operational depth",
    it: "Profondità operativa max",
  },
  { id: "testDepth", en: "Test depth", it: "Profondità di test" },
  {
    id: "pressureSensorType",
    en: "Pressure (depth) sensor",
    it: "Sensore di pressione (profondità)",
  },
  {
    id: "accuracyCalibratedRange",
    en: "Accuracy into calibrated range",
    it: "Precisione nell'intervallo di calibrazione",
  },
  {
    id: "calibratedPressureRange",
    en: "Calibrated Pressiore (Depth) Range",
    it: "Intervallo di pressione (profondità) calibrato",
  },
  { id: "buoyancyTrim", en: "Buoyancy / Trim", it: "Bilanciamento / assetto" },
  { id: "dpvInterface", en: "Suex DPV interface", it: "Interfaccia Suex DPV" },
  {
    id: "inWaterUsageTemperature",
    en: "In water usage temperature",
    it: "Temperatura utilizzo in acqua",
  },
  {
    id: "inAirUsageTemperature",
    en: "In air usage temperature",
    it: "Intervallo di temperatura di funzionamento in aria",
  },
  {
    id: "storageTemperature",
    en: "Storage temperature",
    it: "Temperatura di stoccaggio",
  },
  { id: "batteryType", en: "Battery type", it: "Tipo di batteria" },
  { id: "batterySize", en: "Battery size", it: "Formato batteria" },
  {
    id: "suitableBatteryType",
    en: "Suitable for battery type",
    it: "Tipi di batteria compatibili",
  },
  {
    id: "batteryOperatingLife",
    en: "Battery operating life",
    it: "Durata della batteria",
  },
  { id: "maxVoltage", en: "Max. Voltage", it: "Voltaggio max" },
  {
    id: "maxChargingCurrent",
    en: "Charging current max",
    it: "Corrente di carica max.",
  },
  { id: "chargerCode", en: "Charger", it: "Caricatore" },
  {
    id: "nominalVoltage",
    en: "Battery Nominal voltage",
    it: "Voltaggio nominale batteria",
  },
  {
    id: "nominalCapacity",
    en: "Battery Nominal capacity",
    it: "Capacità nominale batteria",
  },
  {
    id: "chargerPowerSupply",
    en: "Charger power supply",
    it: "Alimentazione Caricabatteria",
  },
  {
    id: "maxRechargingTime",
    en: "Max recharging time",
    it: "Tempo max di ricarica",
  },
  {
    id: "plugExternalSupply",
    en: "Plug for external supply",
    it: "Connettore alimentazione esterna",
  },
  {
    id: "frequencyBands",
    en: "Frequency bands",
    it: "Bande di frequenza",
  },
  {
    id: "byPass",
    en: "Bypass",
    it: "Bypass",
  },
  {
    id: "diveLogCapacity",
    en: "Dive log capacity",
    it: "Capacità registro immersioni",
  },
  {
    id: "connectivity",
    en: "Connectivity",
    it: "Connettività",
  },
  {
    id: "bluetoothSwitch",
    en: "Bluetooth switch",
    it: "Interruttore bluetooth",
  },
  {
    id: "bluetoothConnectionMotor",
    en: "Bluetooth connect (motor)",
    it: "Connettività Bluetooth (motore) ",
  },
  {
    id: "bluetoothConnectionBattery",
    en: "Bluetooth connect (battery)",
    it: "Connettività Bluetooth (batteria)",
  },
  {
    id: "compassResolution",
    en: "Compass resolution",
    it: "Risoluzione della bussola",
  },
  {
    id: "compassAccuracy",
    en: "Compass accuracy",
    it: "Precisione della bussola",
  },
  {
    id: "compassTiltCompensation",
    en: "Compass tilt compensation",
    it: "Compensazione dell'inclinazione della bussola",
  },
  {
    id: "cutOffInside",
    en: "Cut off inside",
    it: "Cut off integrato",
  },
  {
    id: "wristAttachment",
    en: "Wrist attachment",
    it: "Attacco al polso",
  },
  {
    id: "oringSize",
    en: "Size O-ring",
    it: "O-RING del coperchio della batteria (dimensione)",
  },
  {
    id: "oringMaterial",
    en: "Material O-ring",
    it: "O-RING del coperchio della batteria (materiale)",
  },
  {
    id: "display",
    en: "Display",
    it: "Display",
  },
  {
    id: "displayType",
    en: "Display type",
    it: "Tipo di display",
  },
  {
    id: "displayDimension",
    en: "Display dimension",
    it: "Dimesioni display",
  },
];
