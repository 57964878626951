// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "./PermissionDeniedPage.scss";

const Info = () => {
  const user = useSelector(({ user }) => user.data.user);
  const loading = useSelector(({ user }) => user.loading);
  const { t } = useTranslation();

  return (
    <>
      {!loading && (
        <div className="perm-denied">
          {" "}
          {t("permissionDenied", text)}
          <p>{t("contactForAccess", text)}</p>
        </div>
      )}
    </>
  );
};

export default Info;

const text = [
  {
    id: "permissionDenied",
    it: "Il tuo account non è autorizzato a visualizzare questa pagina",
    en: "Your account is not authorized to view this page",
  },
  {
    id: "contactForAccess",
    en: "Contact us to request access to this page",
    it: "Contattaci per richiedere l'accesso a questa pagina",
  },
];
