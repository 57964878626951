import axios from "axios";
import api from "../api/index";

const GET_ALL_SOFTWARE_REQUEST = "SUEX_MILITARY/GET_ALL_SOFTWARE_REQUEST";
const GET_ALL_SOFTWARE_SUCCESS = "SUEX_MILITARY/GET_ALL_SOFTWARE_SUCCESS";
const GET_ALL_SOFTWARE_FAILURE = "SUEX_MILITARY/GET_ALL_SOFTWARE_FAILURE";

const GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_REQUEST =
  "SUEX_MILITARY/GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_REQUEST";
const GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_SUCCESS =
  "SUEX_MILITARY/GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_SUCCESS";
const GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_FAILURE =
  "SUEX_MILITARY/GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_FAILURE";

const UPDATE_SOFTWARE_REQUEST = "SUEX_MILITARY/UPDATE_SOFTWARE_REQUEST";
const UPDATE_SOFTWARE_SUCCESS = "SUEX_MILITARY/UPDATE_SOFTWARE_SUCCESS";
const UPDATE_SOFTWARE_FAILURE = "SUEX_MILITARY/UPDATE_SOFTWARE_FAILURE";

const GET_SOFTWARE_BY_ID_REQUEST = "SUEX_MILITARY/GET_SOFTWARE_BY_ID_REQUEST";
const GET_SOFTWARE_BY_ID_SUCCESS = "SUEX_MILITARY/GET_SOFTWARE_BY_ID_SUCCESS";
const GET_SOFTWARE_BY_ID_FAILURE = "SUEX_MILITARY/GET_SOFTWARE_BY_ID_FAILURE";

const initialState = {
  error: false,
  loading: false,
  data: [],
  distributorSoftware: [],
};

export default function reducerSoftware(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SOFTWARE_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_SOFTWARE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ALL_SOFTWARE_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorSoftware: action.payload,
      };

    case GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        distributorSoftware: [],
        error: action.payload,
      };

    case GET_SOFTWARE_BY_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_SOFTWARE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_SOFTWARE_BY_ID_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case UPDATE_SOFTWARE_REQUEST:
      return { ...state, loading: true, error: false };

    case UPDATE_SOFTWARE_SUCCESS:
      const updatedData = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        data: updatedData,
      };

    case UPDATE_SOFTWARE_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const getAllSoftware = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_SOFTWARE_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllSoftware();

      let software = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_SOFTWARE_SUCCESS,
        payload: software.data,
      });

      return software.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_SOFTWARE_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSoftwareByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_REQUEST,
    });

    try {
      const { url, method, headers } =
        api.getSoftwareByDistributorId(distributorId);

      let software = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_SUCCESS,
        payload: software.data,
      });

      return software.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_SOFTWARE_BY_DISTRIBUOTR_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSoftwareById = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SOFTWARE_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getSoftwareById(userId);

      let software = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_SOFTWARE_BY_ID_SUCCESS,
        payload: software.data,
      });

      return software.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_SOFTWARE_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};
export const updateSoftware = (id, updateData) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SOFTWARE_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.updateSoftware(id, updateData);

      let software = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_SOFTWARE_SUCCESS,
        payload: software.data,
      });

      return software.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: UPDATE_SOFTWARE_FAILURE,
        payload: error,
      });
    }
  };
};
