import "./Features.scss";
import { ArrowLeft, ArrowRight } from "assets/icons/icons";
// import { features } from "debug-items/features";

import { formatNumber } from "utils/general";
import useSwiper from "utils/useSwiper";
import useTranslation from "utils/useTranslation";
import NavigationArrow from "components/navigation/NavigationArrow";
import { useSelector } from "react-redux";
import { baseUrl } from "config/server";

const Features = ({ product }) => {
  const features = product.features;
  const activeLanguage = useSelector(({ language }) => language);
  const { activeFeature, handleSwiper } = useSwiper(features);
  const { t } = useTranslation();

  return (
    <>
      {!!features && features.length > 0 && (
        <div className="dpv__features dpv-section">
          <h2>{t("features", text)} </h2>
          <div className="dpv__features__image-container">
            {activeFeature > 0 && (
              <>
                {" "}
                <img
                  className="image-left"
                  src={`${baseUrl}/${features[activeFeature - 1]?.iconUrl}`}
                  alt=""
                />
                <div
                  onClick={() => handleSwiper(-1)}
                  className="arrow-container"
                >
                  <ArrowLeft />
                </div>
              </>
            )}
            {/* <img src={placeholderImages[activeFeature]} alt="" /> */}
            <img src={`${baseUrl}/${features[activeFeature].iconUrl}`} alt="" />
            {activeFeature + 1 < features.length && (
              <>
                <div
                  onClick={() => handleSwiper(1)}
                  className="arrow-container"
                >
                  <ArrowRight />
                </div>
                <img
                  className="image-right"
                  src={`${baseUrl}/${features[activeFeature + 1]?.iconUrl}`}
                  alt=""
                />
              </>
            )}
          </div>

          <div
            style={{
              width: "70%",
            }}
            className="dpv-features-block"
          >
            {/* <img src={features[activeFeature]?.img} alt="" /> */}

            <div className="dpv-features-description">
              {activeLanguage === "it" ? (
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  {features[activeFeature]?.descriptionIt}
                </p>
              ) : (
                <p style={{ textAlign: "center" }}>
                  {features[activeFeature]?.descriptionEn}
                </p>
              )}

              <div className="active-feature">
                <span>{formatNumber(activeFeature + 1)}</span> /{" "}
                {formatNumber(features.length)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Features;

const text = [
  {
    id: "features",
    en: "features",
    it: "caratteristiche",
  },
];
