import Select from "components/select/Select";
import { baseUrl } from "config/server";
import { options } from "debug-items/select-options";
import { useState } from "react";
import { useSelector } from "react-redux";
import { eliminateDuplicates } from "utils/general";
import useTranslation from "utils/useTranslation";
import { Search } from "../../assets/icons/icons";
import "./Video.scss";

const Video = ({ list }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const options = list.map((element, i) =>
    isIta ? element.categoryIt : element.categoryEn
  );
  const filteredOptions = eliminateDuplicates(options);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState(list);

  const FilterVideo = (value) => {
    setFilterText(value);
    const filteredData = list.filter((item) =>
      (isIta ? item.nameIt : item.nameEn)
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  return (
    <>
      {list?.length > 0 && (
        <div className="product-page-video dpv-section">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ margin: 0 }}>video</h2>
            <div className="search-container">
              <input
                type="text"
                value={filterText}
                placeholder="search"
                className="search-input"
                onChange={(e) => FilterVideo(e.target.value)}
              />
              <Search className="search-icon" />
            </div>
          </div>
          {filteredOptions?.length > 1 && (
            <div className="sort-select-options">
              <Select
                setSelectedCategory={setSelectedCategory}
                placeholder={t("selectCategory")}
                options={filteredOptions}
              />
            </div>
          )}
          <div className="video-list">
            {filteredData
              ?.filter((video) => {
                if (selectedCategory) {
                  return (
                    (isIta ? video.categoryIt : video.categoryEn) ===
                    selectedCategory
                  );
                } else return video;
              })
              .map((video, i) => (
                <div key={`key_video_${i}`} className="video-list__item">
                  {/* <iframe
                    width="420"
                    height="315"
                    src={baseUrl + '/' + video.videoUrl}
                  ></iframe> */}
                  <video
                    controls="controls"
                    height={320}
                    name={isIta ? video.nameIt : video.nameEn}
                  >
                    <source src={`${baseUrl + "/" + video.videoUrl}#t=5`} />
                  </video>
                  <h4 className="no-mar">
                    {isIta ? video.nameIt : video.nameEn}
                  </h4>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Video;
