import axios from "axios";
import api from "../api/index";

const GET_ALL_PRODUCTS_REQUEST = "SUEX_MILITARY/GET_ALL_PRODUCTS_REQUEST";
const GET_ALL_PRODUCTS_SUCCESS = "SUEX_MILITARY/GET_ALL_PRODUCTS_SUCCESS";
const GET_ALL_PRODUCTS_FAILURE = "SUEX_MILITARY/GET_ALL_PRODUCTS_FAILURE";

const GET_PRODUCT_BY_ID_REQUEST = "SUEX_MILITARY/GET_PRODUCT_BY_ID_REQUEST";
const GET_PRODUCT_BY_ID_SUCCESS = "SUEX_MILITARY/GET_PRODUCT_BY_ID_SUCCESS";
const GET_PRODUCT_BY_ID_FAILURE = "SUEX_MILITARY/GET_PRODUCT_BY_ID_FAILURE";

const RESET_PRODUCT = "SUEX_MILITARY/RESET_PRODUCT";

const initialState = {
  loading: false,
  loadingSingle: false,
  data: [],
  error: false,
  singleProduct: null,
};

export default function reducerProducts(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        singleProduct: null,
      };

    case GET_ALL_PRODUCTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_PRODUCT_BY_ID_REQUEST:
      return { ...state, loadingSingle: true, error: false };

    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleProduct: action.payload,
      };

    case GET_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        loadingSingle: false,
        singleProduct: null,
        error: action.payload,
      };

    case RESET_PRODUCT:
      return {
        ...state,
        singleProduct: null,
      };

    default:
      return state;
  }
}

export const getAllProducts = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_PRODUCTS_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllProducts();

      let products = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_PRODUCTS_SUCCESS,
        payload: products.data,
      });

      return products.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_PRODUCTS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getProductById = (productCode, userLevel) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PRODUCT_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.getProductById(
        productCode,
        userLevel
      );
      let product = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: GET_PRODUCT_BY_ID_SUCCESS,
        payload: product.data,
      });

      return product.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_PRODUCT_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const resetSingleProduct = () => {
  return {
    type: RESET_PRODUCT,
  };
};
