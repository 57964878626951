import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { getUser } from "reducers/user";
import "styles/general.scss";
import MyHelmet from "utils/Helmet";
import Portal from "views/portal/Portal";
import Welcome from "views/welcome/Welcome";

const App = () => {
  const dispatch = useDispatch();
  const userAS = localStorage.getItem("user");
  let localToken = "";

  if (userAS) {
    const user = JSON.parse(userAS);
    localToken = user?.authToken;
  }

  const token = useSelector(({ user }) => user.data?.authToken) || localToken;

  return (
    <BrowserRouter>
      <MyHelmet />

      {token ? (
        <Portal />
      ) : (
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Redirect to="/" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
