import "./Components.scss";
import { Link } from "react-router-dom";
import useTranslation from "utils/useTranslation";
import { useSelector } from "react-redux";
import { baseUrl } from "config/server";

const ComponentsList = ({ list }) => {
  const activeLanguage = useSelector(({ language }) => language);

  const { t } = useTranslation();

  return (
    <div className="components-component">
      <div className="components-component__list">
        <h2>{t("components", text)}</h2>
        <div className="list subsection-pad">
          {list.map((element, i) => (
            <div
              key={`key_componentslist_${element.id}_${i}`}
              className={`list__dpv`}
            >
              <Link
                className="we"
                to={
                  element.productGroup.includes("FRAME")
                    ? `/frames/${element.dpdCode}`
                    : `/${element.productGroup}/${element.dpdCode}`
                }
              >
                <div className="image-container">
                  <img
                    src={`${baseUrl}/images/${element.dpdCode}.png`}
                    alt={`${element.nameEn}_image`}
                  />
                </div>

                <span className="text-dark">Cod. {element.dpdCode}</span>

                {activeLanguage.includes("it") ? (
                  <h4 className="no-mar">{element.nameIt}</h4>
                ) : (
                  <h4 className="no-mar">{element.nameEn}</h4>
                )}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComponentsList;

const text = [
  {
    id: "components",
    en: "components",
    it: "componenti",
  },
];
