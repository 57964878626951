import { combineReducers } from "redux";

import user from "./user";
import language from "./language";
import products from "./products";
import courses from "./courses";
import contacts from "./contacts";
import levels from "./levels";
import firmware from "./firmware";
import documents from "./documents";
import software from "./software";
import pricelist from "./priceList";
import history from "./history";
import help from "./help";

export default combineReducers({
  user,
  language,
  products,
  courses,
  contacts,
  levels,
  firmware,
  documents,
  software,
  pricelist,
  history,
  help,
});
