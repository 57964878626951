import axios from "axios";
import api from "../api/index";
import FileSaver from "file-saver";

const GET_ALL_DOCUMENTS_REQUEST = "SUEX_MILITARY/GET_ALL_DOCUMENTS_REQUEST";
const GET_ALL_DOCUMENTS_SUCCESS = "SUEX_MILITARY/GET_ALL_DOCUMENTS_SUCCESS";
const GET_ALL_DOCUMENTS_FAILURE = "SUEX_MILITARY/GET_ALL_DOCUMENTS_FAILURE";

const GET_ALL_DOCUMENTS_COURSES_REQUEST =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_COURSES_REQUEST";
const GET_ALL_DOCUMENTS_COURSES_SUCCESS =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_COURSES_SUCCESS";
const GET_ALL_DOCUMENTS_COURSES_FAILURE =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_COURSES_FAILURE";

const GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_REQUEST";
const GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_FAILURE";

const GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_REQUEST";
const GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_FAILURE";

const UPDATE_DOCUMENTS_REQUEST = "SUEX_MILITARY/UPDATE_DOCUMENTS_REQUEST";
const UPDATE_DOCUMENTS_SUCCESS = "SUEX_MILITARY/UPDATE_DOCUMENTS_SUCCESS";
const UPDATE_DOCUMENTS_FAILURE = "SUEX_MILITARY/UPDATE_DOCUMENTS_FAILURE";

const GET_DOCUMENTS_BY_ID_REQUEST = "SUEX_MILITARY/GET_DOCUMENTS_BY_ID_REQUEST";
const GET_DOCUMENTS_BY_ID_SUCCESS = "SUEX_MILITARY/GET_DOCUMENTS_BY_ID_SUCCESS";
const GET_DOCUMENTS_BY_ID_FAILURE = "SUEX_MILITARY/GET_DOCUMENTS_BY_ID_FAILURE";

const DOWNLOAD_DOCUMENT_REQUEST = "SUEX_MILITARY/DOWNLOAD_DOCUMENT_REQUEST";
const DOWNLOAD_DOCUMENT_SUCCESS = "SUEX_MILITARY/DOWNLOAD_DOCUMENT_SUCCESS";
const DOWNLOAD_DOCUMENT_FAILURE = "SUEX_MILITARY/DOWNLOAD_DOCUMENT_FAILURE";

const DOWNLOAD_DATASHEET_REQUEST = "SUEX_MILITARY/DOWNLOAD_DATASHEET_REQUEST";
const DOWNLOAD_DATASHEET_SUCCESS = "SUEX_MILITARY/DOWNLOAD_DATASHEET_SUCCESS";
const DOWNLOAD_DATASHEET_FAILURE = "SUEX_MILITARY/DOWNLOAD_DATASHEET_FAILURE";

const initialState = {
  error: false,
  loading: false,
  loadingPdf: false,
  loadingDatasheet: false,
  data: [],
  docsCourses: [],
  distributorDocuments: [],
  distributorDocsCourses: [],
  userDocuments: [],
  dataPdf: [],
  dataDataseet: [],
};

export default function reducerDocuments(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DOCUMENTS_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ALL_DOCUMENTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case GET_ALL_DOCUMENTS_COURSES_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_DOCUMENTS_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        docsCourses: action.payload,
      };

    case GET_ALL_DOCUMENTS_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        docsCourses: [],
        error: action.payload,
      };

    case GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorDocuments: action.payload,
      };

    case GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        distributorDocuments: [],
        error: action.payload,
      };

    case GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorDocsCourses: action.payload,
      };

    case GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        distributorDocsCourses: [],
        error: action.payload,
      };

    case GET_DOCUMENTS_BY_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_DOCUMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        userDocuments: action.payload,
      };

    case GET_DOCUMENTS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        userDocuments: [],
        error: action.payload,
      };

    case DOWNLOAD_DOCUMENT_REQUEST:
      return { ...state, loadingPdf: true, error: false };

    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loadingPdf: false,
        dataPdf: action.payload,
      };

    case DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingPdf: false,
        dataPdf: [],
        error: action.payload,
      };

    case UPDATE_DOCUMENTS_REQUEST:
      return { ...state, loading: true, error: false };

    case UPDATE_DOCUMENTS_SUCCESS:
      const updatedData = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        data: updatedData,
      };

    case UPDATE_DOCUMENTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const getAllDocuments = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_DOCUMENTS_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllDocuments();

      let documents = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_DOCUMENTS_SUCCESS,
        payload: documents.data,
      });

      return documents.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_DOCUMENTS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getAllDocumentsCourses = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_DOCUMENTS_COURSES_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllDocumentsCourses();

      let docsCourses = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_DOCUMENTS_COURSES_SUCCESS,
        payload: docsCourses.data,
      });

      return docsCourses.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_DOCUMENTS_COURSES_FAILURE,
        payload: error,
      });
    }
  };
};

export const getDocumentsByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_REQUEST,
    });

    try {
      const { url, method, headers } =
        api.getDocumentsByDistributorId(distributorId);

      let documents = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_SUCCESS,
        payload: documents.data,
      });

      return documents.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_DOCUMENTS_BY_DISTRIBUTOR_ID_FAILURE,
        payload: error,
      });
    }
  };
};
export const getDocumentsCoursesByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_REQUEST,
    });

    try {
      const { url, method, headers } =
        api.getDocumentsCoursesByDistributorId(distributorId);

      let distributorDocsCourses = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_SUCCESS,
        payload: distributorDocsCourses.data,
      });

      return distributorDocsCourses.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_DOCUMENTS_COURSES_BY_DISTRIBUTOR_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getDocumentsById = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_DOCUMENTS_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getDocumentsById(userId);

      let documents = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_DOCUMENTS_BY_ID_SUCCESS,
        payload: documents.data,
      });

      return documents.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_DOCUMENTS_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const downloadDocument = (documentCode, userId) => {
  return async (dispatch) => {
    dispatch({
      type: DOWNLOAD_DOCUMENT_REQUEST,
    });

    try {
      const { url, method, headers } = api.downloadDocument(
        documentCode,
        userId
      );

      let documents = await axios({
        url,
        method,
        headers,
        responseType: "blob", // Imposta il tipo di risposta come Blob
      });

      const blob = new Blob([documents.data], { type: "application/pdf" });

      // Avvia il download del documento PDF
      FileSaver.saveAs(blob, "documento.pdf");

      dispatch({
        type: DOWNLOAD_DOCUMENT_SUCCESS,
        payload: documents.data,
      });

      return documents.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: DOWNLOAD_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const downloadDatasheet = (productCode, userId, language) => {
  return async (dispatch) => {
    dispatch({
      type: DOWNLOAD_DATASHEET_REQUEST,
    });

    try {
      const { url, method, headers } = api.downloadDatasheet(
        productCode,
        userId,
        language
      );

      let documents = await axios({
        url,
        method,
        headers,
        responseType: "blob", // Imposta il tipo di risposta come Blob
      });

      const blob = new Blob([documents.data], { type: "application/pdf" });

      // Avvia il download del documento PDF
      FileSaver.saveAs(blob, productCode + "-" + language + ".pdf");

      dispatch({
        type: DOWNLOAD_DATASHEET_SUCCESS,
        payload: documents.data,
      });

      return documents.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: DOWNLOAD_DATASHEET_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateDocument = (id, updateData) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DOCUMENTS_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.updateDocument(id, updateData);

      let document = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_DOCUMENTS_SUCCESS,
        payload: document.data,
      });

      return document.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: UPDATE_DOCUMENTS_FAILURE,
        payload: error,
      });
    }
  };
};
