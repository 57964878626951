import React, { useRef } from "react";
import useTranslation from "utils/useTranslation";
import WatchInAction from "../single-product/watch-in-action/WatchInAction";
import Certification9001 from "../../../assets/certification_9001.jpg";
import Certification14001 from "../../../assets/certification_14001.jpg";
// import DpvBg from "assets/copertina.jpg";
import DpvDetail from "assets/dettaglio.jpg";
import { Minus, ArrowDown } from "assets/icons/icons";
import { useSelector } from "react-redux";
import { baseUrl } from "config/server";

const Company = () => {
  const { t } = useTranslation();

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const activeLanguage = useSelector(({ language }) => language);

  return (
    <>
      <div className="mb-4" style={{ marginTop: "-4rem" }}>
        <div
          style={{
            height: "75vh",
            width: "100%",
            position: "relative",
          }}
        >
          <img
            height="100%"
            width="100%"
            style={{ objectFit: "cover" }}
            src={`${baseUrl}/images/companyJumbo.jpg`}
            alt=""
          />
        </div>
        <h1
          style={{
            color: "white",
            position: "absolute",
            top: "10%",
            left: "40px",
            fontSize: 43,
            lineHeight: 1.12,
            width: "50%",
          }}
        >
          <span>SUEX</span>
          <br />
          {t("jumboTitle", text)}
        </h1>
        {/*      <p
          style={{
            color: "white",
            position: "absolute",
            top: "85%",
            left: "40px",
            fontSize: 32,
          }}
        >
          Dive in the fast lane
          <br /> since 2000
        </p> */}
        <div
          style={{
            position: "absolute",
            top: "90%",
            right: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <p
            style={{
              color: "white",
              fontSize: 16,
              marginRight: 16,
            }}
          >
            {t("scorri", text)}
          </p> */}
          {/* <div
            onClick={() => scrollToBottom()}
            style={{
              position: "relative",
              border: "1px solid white",
              borderRadius: "50%",
              height: 64,
              width: 64,
              cursor: "pointer",
            }}
          >
            <ArrowDown
              style={{
                fill: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div> */}
        </div>
      </div>
      <div
        ref={messagesEndRef}
        style={{ position: "relative" }}
        className="container mb-6"
      >
        <h4 style={{ marginBottom: "1.5rem", paddingTop: "1rem" }}>
          {t("aboutUs", text)}
        </h4>
        <h1
          style={{ fontSize: 40, position: "absolute", top: 32 }}
          className="mb-2 title-shadow"
        >
          {t("excellenceTitle", text)}
        </h1>
        <div className="flex-row justify-between align-center">
          <div style={{ width: "40%", marginRight: 8 }}>
            <p
              style={{
                lineHeight: "1.5rem",
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              {t("excellenceText1", text)}
            </p>
            <br />
            <p
              style={{
                lineHeight: "1.5rem",
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              {t("excellenceText2", text)}
            </p>
          </div>
          <img style={{ width: "50%" }} src={DpvDetail} alt="" />
        </div>
      </div>
      <WatchInAction
        title={t("cuttingEdge", text)}
        list={[
          {
            videoUrl: "videos/company_video.mp4",
          },
        ]}
      />
      <div className="container mb-4">
        <div className="flex-row justify-between align-center mb-4">
          <div style={{ width: "50%", marginRight: 8 }}>
            <h4 style={{ marginBottom: "0.5rem" }}>
              {" "}
              {t("DPV DIVER PROPULSION VEHICLE", text)}
            </h4>
            <h2 style={{ lineHeight: "1.5rem", fontSize: "1.2rem" }}>
              {t("dpvTitle", text)}
            </h2>
          </div>
          <div style={{ width: "50%", marginRight: 8 }}>
            <p style={{ lineHeight: "1.5rem", fontSize: "1.2rem" }}>
              {t("dpvText1", text)}
            </p>
            <p style={{ lineHeight: "1.5rem", fontSize: "1.2rem" }}>
              {t("dpvText2", text)}
            </p>
          </div>
        </div>
        <h1 className="mb-2" style={{ textAlign: "center" }}>
          {t("certifications", text)}
        </h1>
        <div className="flex-row justify-between align-center mb-4">
          {/*<a
            href={
              activeLanguage === "it"
                ? `${baseUrl}/documents/iso/SUEX_Certificato_ISO9001_ITA.png`
                : `${baseUrl}/documents/iso/SUEX_Certificate_ISO9001_ENG.png`
            }
          >*/}
          {/*<a
            href={
              activeLanguage === "it"
                ? `${baseUrl}/documents/iso/SUEX_Certificato_ISO9001_ITA.png`
                : `${baseUrl}/documents/iso/SUEX_Certificate_ISO9001_ENG.png`
            }
          >*/}
            <a
            target="_blank"
            href={
               `${baseUrl}/documents/iso/SUEX_ISO14001_CERTIFICATE.pdf`
            }
          >
            <img
              style={{ width: "200px", height: "200px" }}
              src={Certification9001}
              alt=""
            />
          </a>
         {/* <a
            href={
              activeLanguage === "it"
                ? `${baseUrl}/documents/iso/SUEX_Certificato_ISO14001_ITA.jpg`
                : `${baseUrl}/documents/iso/SUEX_Certificate_ISO14001_ENG.jpg`
            }
          >*/}
          <a
            target="_blank"
            href={
               `${baseUrl}/documents/iso/SUEX_ISO9001_CERTIFICATE.pdf`
            }
          >
            <img
              style={{ width: "200px", height: "200px" }}
              src={Certification14001}
              alt=""
            />
          </a>
          <a
            target="_blank"
            href={
               `${baseUrl}/documents/iso/SUEX_ISO27001_CERTIFICATE.pdf`
            }
          >
            <img
              style={{ width: "200px", height: "200px" }}
              src={
               `${baseUrl}/documents/iso/images/certification_27001.jpg`
            }
              alt=""
            />
          </a>
        </div>
        <h4>{t("policyTitle", text)}</h4>
        <p className="mb-2">{t("policyText1", text)}</p>
        <p className="mb-2">{t("policyText2", text)}</p>
        {[1, 2, 3, 4, 5, 6].map((item, i) => (
          <div
            key={`key_array_${item}_${i}`}
            className="flex-row align-center mb-1"
          >
            <Minus
              style={{ marginRight: 12, fill: "var(--blue)", width: 12 }}
            />
            <p>{t(`policyList${i + 1}`, text)}</p>
          </div>
        ))}
        <p className="mb-2">{t("objectivesText", text)}</p>
        <h4>{t("longObjTitle", text)}</h4>
        <p>{t("longObjList1", text)}</p>
        <p>{t("longObjList2", text)}</p>
        <p>{t("longObjList3", text)}</p>
        <p className="mb-1">{t("longObjList4", text)}</p>
        <p className="mb-2">{t("longObjText", text)}</p>
        <h4>{t("shortObjTitle", text)}</h4>
        <p className="mb-2">{t("shortObjText1", text)}</p>
        <p className="mb-2">{t("shortObjText2", text)}</p>
      </div>
    </>
  );
};

export default Company;

const text = [
  {
    id: "aboutUs",
    en: "About us",
    it: "Chi siamo",
  },
  {
    id: "excellenceTitle",
    en: "20 years of tecnichal excellence",
    it: "20 anni di eccellenza tecnica",
  },
  {
    id: "excellenceText1",
    en: "SUEX was founded in 2000 with the aim of producing high-performance underwater vehicles designed for long-range technical and professional diving. Today, Suex represents cutting-edge technology in underwater mobility. The evolution of SUEX systems over the years has succeeded in meeting the complex requirements of the extreme technical and professional applications.",
    it: "SUEX è stata fondata nel 2000 con l'obiettivo di produrre veicoli subacquei ad alte prestazioni progettati per immersioni tecniche e professionali a lungo raggio. Oggi rappresentiamo una tecnologia all'avanguardia nella mobilità subacquea. L'evoluzione dei sistemi SUEX nel corso degli anni è riuscita a soddisfare i complessi requisiti per le applicazioni tecniche e professionali più estreme.",
  },
  {
    id: "excellenceText2",
    en: "SUEX systems combine technology and reliability for high performance in absolute safety. Suex tests each model by simulating real-world conditions to verify its performance, reliability and manoeuvrability, and refining its design and equipment.",
    it: "I sistemi SUEX combinano tecnologia e affidabilità per ottenere prestazioni elevate in assoluta sicurezza. Testiamo ogni modello simulando le condizioni reali per verificarne le prestazioni, l'affidabilità e la manovrabilità, perfezionandone il design e l'equipaggiamento.",
  },
  {
    id: "cuttingEdge",
    en: "Cutting-edge technology",
    it: "Tecnologia all’avanguardia",
  },
  {
    id: "dpvTitle",
    en: "PERFORMANCE, RELIABILITY AND MANEUVERABILITY",
    it: "PRESTAZIONI, AFFIDABILITÀ E MANOVRABILITÀ",
  },
  {
    id: "dpvText1",
    en: "SUEX DPVs combine technology and reliability for high performance in absolute safety. We test each model by simulating real-world conditions to verify its performance, reliability and maneuverability, and refining its design and equipment.",
    it: "I DPV SUEX racchiudono tecnologia ed affidabilità per una high-performance in assoluta sicurezza. Testiamo ogni modello simulando le reali condizioni d’utilizzo per verificarne prestazioni, affidabilità e manovrabilità, affinando design e dotazioni.",
  },
  {
    id: "dpvText2",
    en: "DPV and state-of-the-art integrated communication systems offer a unique underwater experience. Innovation makes the experience simple.",
    it: "DPV e sistemi di comunicazione integrata all’avanguardia offrono una underwater experience unica. L’innovazione rende semplice l’esperienza.",
  },
  {
    id: "certifications",
    en: "Certifications",
    it: "Certificazioni",
  },
  {
    id: "policyTitle",
    en: "QUALITY AND ENVIRONMENTAL POLICY",
    it: "POLITICA PER LA QUALITÀ-AMBIENTE",
  },
  {
    id: "policyText1",
    en: "Suex’s primary objective is to manufacture products that fully satisfy the demands of its Customers. The company’s aim is for the Quality and Environmental protection offered by its products and services to make it synonymous with a high level of quality and compliance with environmental regulations.",
    it: "Suex ha come obiettivo primario la realizzazione di prodotti che soddisfino pienamente la richiesta dei Clienti e vuole che, attraverso le caratteristiche di Qualità e di protezione dell’Ambiente del prodotto/servizio, il proprio nome diventi sinonimo, sul mercato, di elevato livello qualitativo e di rispetto delle normative ambientali.",
  },
  {
    id: "policyText2",
    en: "Specifically, the company believes it is of fundamental importance to:",
    it: "In particolare la Società ritiene sia di fondamentale importanza:",
  },
  {
    id: "policyList1",
    en: "Provide products that comply with the order specifications formulated by the customer;",
    it: "Fornire ai propri Clienti prodotti conformi alle specifiche d’ordine formulate dal Cliente;",
  },
  {
    id: "policyList2",
    en: "Value the link with its own context and always operate in keeping with the risks and opportunities;",
    it: "Valorizzare il legame con il proprio contesto ed operare sempre in coerenza con l’analisi dei rischi e delle opportunità, garantire la coerenza della politica con l’analisi del contesto, con le strategie dell’azienda, con gli impatti ambientali delle attività, prodotti e servizi;",
  },
  {
    id: "policyList3",
    en: "Constantly pursue compliance with the law;",
    it: "Costante perseguimento della conformità legislativa;",
  },
  {
    id: "policyList4",
    en: "Continuously strive to improve environmental performance, especially in the areas of greatest impact;",
    it: "Impegno al miglioramento continuo delle proprie prestazioni ambientali specialmente negli ambiti di maggior impatto;",
  },
  {
    id: "policyList5",
    en: "Constantly pursue environmental protection by reducing waste and limiting the consumption of non-renewable resources;",
    it: "Perseguimento costante protezione dell’ambiente improntata alla riduzione dei rifiuti econtenimento dei consumi di risorse non rinnovabili;",
  },
  {
    id: "policyList6",
    en: "Ensure complete respect for the ecosystem, always striving to protect and preserve it;",
    it: "Rispetto totale dell’ecosistema favorendone sempre la tutela e la preservazione;",
  },
  {
    id: "objectivesText",
    en: "In order to achieve this objective, the Company declares the following commitments, divided by the time required to implement them:",
    it: "Per raggiungere questo obiettivo la Società dichiara i seguenti impegni, divisi per l’orizzonte temporale di attuabilità degli stessi:",
  },
  {
    id: "longObjTitle",
    en: "Long-term objectives",
    it: "Obiettivi di lungo periodo",
  },
  {
    id: "longObjList1",
    en: "1) To set up, maintain and review, at established intervals, a documented Quality and Environment System, in compliance with the reference standard (UNI EN ISO 9001 – UNI EN ISO 14001);",
    it: "1) Istituire, mantenere e riesaminare, ad intervalli predefiniti, un Sistema Qualità e Ambiente documentato, conforme allo standard di riferimento (UNI EN ISO 9001 – UNI EN ISO 14001);",
  },
  {
    id: "longObjList2",
    en: "2) To operate in compliance with contractual specifications, always pursuing full customer satisfaction;",
    it: "2) Operare in conformità alle specifiche contrattuali perseguendo sempre la piena soddisfazione del cliente;",
  },
  {
    id: "longObjList3",
    en: "3) To fully comply with the mandatory regulations in the sector to which it belongs, always striving to exceed the minimum regulatory requirements;",
    it: "3) Rispettare pienamente le normative cogenti nel settore di appartenenza operando sempre nella direzione del superamento dei requisiti minimi imposti dalle stesse;",
  },
  {
    id: "longObjList4",
    en: "4) To work constantly towards improving the efficacy of the Quality and Environmental management system, with systematic reference to the context in which the company operates.",
    it: "4) Operare costantemente nell’ottica del miglioramento continuo dell’efficacia del sistema di gestione per la Qualità e Ambiente con sistematico riferimento al contesto in cui opera l’azienda.",
  },
  {
    id: "longObjText",
    en: "The long-term objectives are verified by the Management during the System Review activities; as they are of a strategic nature, they are renewable yearly.",
    it: "Gli obiettivi di lungo termine vengono verificati dalla Direzione in occasione delle attività di Riesame del Sistema; poiché sono di carattere strategico sono rinnovabili di anno in anno.",
  },
  {
    id: "shortObjTitle",
    en: "Short-term objectives",
    it: "Obiettivi di breve periodo",
  },
  {
    id: "shortObjText1",
    en: "These are contained in the management review report. In view of the variability of the contents of this document, it was not considered appropriate to include it in this chapter.",
    it: "Sono contenuti all’interno del verbale di riesame della Direzione. In considerazione della variabilità dei contenuti di tale documento, non se ne è ritenuto opportuno l’inserimento all’interno del presente Capitolo.",
  },
  {
    id: "shortObjText2",
    en: "The Management is committed to ensuring that all its staff, at all levels, are engaged with and fully aware of the fundamental principles of Quality and Environment management.",
    it: "La Direzione si impegna a far si che tutto il proprio personale, a tutti i livelli, sia coinvolto e pienamente cosciente dei principi fondamentali della Qualità e Ambiente.",
  },
  {
    id: "scorri",
    en: "SCROLL",
    it: "SCORRI",
  },
  {
    id: "jumboTitle",
    en: "SUEX ADVANCED DIVING VEHICLES, CUTTING EDGE TECHNOLOGY OF UNDERWATER MOBILITY",
    it: "VEICOLI SUBACQUEI AVANZATI, TECNOLOGIA ALL’AVANGUARDIA NELLA MOBILITÀ SUBACQUEA",
  },
];
