import { ArrowLeft } from "assets/icons/icons";
import useGoBack from "utils/useGoBack";
import useTranslation from "utils/useTranslation";
import "./GoBack.scss";

const GoBack = () => {
  const { goBack } = useGoBack();
  const { t } = useTranslation();

  return (
    <div className="back-container">
      <div className="go-back-button" onClick={goBack}>
        <ArrowLeft size={12} />
        <span>{t("back", text)}</span>
      </div>
    </div>
  );
};

export default GoBack;

const text = [
  {
    id: "back",
    en: "back",
    it: "indietro",
  },
];
