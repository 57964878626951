import ComparisonPage from "components/comparison-page/ComparisonPage";
import "./DpvComparison.scss";

const DpvComparison = (props) => {
  return (
    <div className="container">
      <div className="dpv-comparison">
        <ComparisonPage list={props.location.state} />
      </div>
    </div>
  );
};

export default DpvComparison;
