import "./SingleProduct.scss";
import Intro from "./intro/Intro";
import Highlights from "./highlights/Highlights";
import Features from "./features/Features";
import WatchInAction from "./watch-in-action/WatchInAction";
import Specs from "./specs/Specs";
import Accessories from "./accessories/Accessories";
import Video from "components/video/Video";
import Documents from "components/documents/Documents";
import Firmware from "components/firmware/Firmware";
import Software from "components/software/Software";
// import { videos } from "debug-items/video";
// import { firmwares } from "debug-items/firmware";
// import { itemSimilars } from "debug-items/dpv";
// import ProductList from "components/product-list/ProductList";
import TroubleshootingFaq from "./troubleshooting-faq/TroubleshootingFaq";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import {
  // getAllProducts,
  getProductById,
  resetSingleProduct,
} from "reducers/products";
import Placeholders from "components/placeholder/Placeholder";
import ComponentsList from "./components/Components";
import FullscreenImage from "./fullscreen-image/FullScreenImage";
import { useState } from "react";

const SingleProduct = () => {
  const code = useParams().model;
  const dispatch = useDispatch();
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getProductById(code, user.userLevel));
    }
    return () => {
      dispatch(resetSingleProduct());
    };
  }, []);

  const product = useSelector(({ products }) => products.singleProduct);
  const loadingSingle = useSelector(({ products }) => products.loadingSingle);
  const user = useSelector(({ user }) => user.data.user);

  // const imageUrl =
  //   "https://api.suexmilitary.thecove.it/catalogues/" +
  //   product?.productCode +
  //   ".png";

  return (
    <div>
      {!product || loadingSingle ? (
        <Placeholders type={"singleProduct"} number={1} />
      ) : (
        <div className="dpd-single-page">
          <div className="container">
            {isFullScreenOpen && (
              <FullscreenImage
                product={product}
                handleClose={() => setIsFullScreenOpen(false)}
              />
            )}
            <Intro
              product={product}
              handleOpen={() => setIsFullScreenOpen(true)}
            />
            {product?.dpd.length > 0 && <ComponentsList list={product?.dpd} />}

            <Accessories
              product={product}
              list={product?.includedAccessories}
              type="includedAccessories"
            />
          </div>
          {/* <Highlights product={product} /> */}
          <Features product={product} />
          {product?.video?.length > 0 && (
            <WatchInAction list={product?.video} />
          )}
          <div className="container">
            {<Specs product={product} />}
            {user?.userLevel >= 2 && (
              <>
                <Documents list={product?.documents} subcategory={false} />
                <Firmware list={product?.firmware} />
                <Software list={product?.software} />
              </>
            )}
            {/* <Video list={product?.video} /> */}
            <Accessories
              product={product}
              list={product?.compatibleAccessories.filter(
                (acc) => acc.type === "product"
              )}
              type="prodottiComp"
            />
            <Accessories
              product={product}
              list={product?.compatibleAccessories.filter(
                (acc) => acc.type === "accessory"
              )}
              type="compatibledAccessories"
            />
            <Accessories
              product={product}
              list={product?.compatibleAccessories.filter(
                (acc) => acc.type === "replacement"
              )}
              type="replacement"
            />
            <TroubleshootingFaq product={product} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProduct;
