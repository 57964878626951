import { File } from "assets/icons/icons";
import Select from "components/select/Select";
import { options } from "debug-items/select-options";
import useTranslation from "utils/useTranslation";
import { eliminateDuplicates } from "utils/general";
import "./Firmware.scss";
import { Popup } from "components/popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { baseUrl } from "config/server";
import { insertUserHistoryDownload } from "reducers/history";

const Firmware = ({ list, title }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const dispatch = useDispatch();
  const account = useSelector(({ user }) => user.data.user);
  const userHasDownload = useSelector(
    ({ user }) => user.data.user?.hasDownload
  );
  const options = list.map((element, i) =>
    isIta ? element.categoryIt : element.categoryEn
  );
  const filteredOptions = eliminateDuplicates(options);

  const handleSetDownload = (content) => {
    dispatch(insertUserHistoryDownload(content));
  };

  return (
    <>
      {list?.length > 0 && (
        <div className="product-page-firmware dpv-section">
          {!!isPopupOpen && (
            <Popup handleClose={() => setIsPopupOpen(false)}>
              <h2 style={{ marginBottom: "1.5rem" }}>
                {t("attenzione", text)}
              </h2>
              <p>{t("attenzioneDescrizione", text)}</p>
            </Popup>
          )}
          <h2>{title || "firmware"}</h2>
          {filteredOptions?.length > 1 && (
            <div className="sort-select-options">
              <Select
                placeholder={t("selectCategory")}
                options={filteredOptions}
              />
            </div>
          )}
          <div className="firmware-list">
            {list?.map((firmware, i) => (
              <div
                key={`key_firmwarelist_${firmware.id}`}
                className="firmware-list__item bb-dark"
              >
                <div className="firmware-left">
                  <File />
                  <h4 className="no-mar">
                    {isIta ? firmware.nameIt : firmware.nameEn}
                  </h4>
                </div>
                {!!userHasDownload ? (
                  <a
                    href={baseUrl + "/" + firmware.firmwareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="btn primary pad hover"
                    onClick={() =>
                      handleSetDownload({
                        userId: account.userId,
                        contentId: firmware.id,
                        contentType: "firmware",
                      })
                    }
                  >
                    download
                  </a>
                ) : (
                  <div
                    onClick={() => setIsPopupOpen(true)}
                    className="btn primary pad hover"
                  >
                    {" "}
                    download
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Firmware;

const text = [
  {
    id: "attenzione",
    en: "Attention",
    it: "Attenzione",
  },
  {
    id: "attenzioneDescrizione",
    it: "Non sei autorizzato a scaricare il file. Contatta l'assistenza Suex.",
    en: "You are not authorized to download the file. Contact Suex support.",
  },
];
