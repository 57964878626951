import ProductList from "components/product-list/ProductList";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect, useState } from "react";

const Frames = () => {
  const dispatch = useDispatch();
  const [isAllVisible, setIsAllVisible] = useState(true);
  const [filteredFrames, setFilteredFrames] = useState([]);
  const isAdmin = useSelector(({ user }) => user.data.user.role === "admin");

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);

  const products = useSelector(({ products }) => products.data);
  const loading = useSelector(({ products }) => products.loading);
  const frames = products.filter(
    (product) => product.productGroup === "COUPLING FRAMES"
  );
  const framesFilter = frames?.filter((frame) => frame.isActive !== 0);

  const handleSetIsAllVisible = () => {
    setIsAllVisible(!isAllVisible);
  };

  useEffect(() => {
    if (!isAllVisible) {
      setFilteredFrames(frames);
    } else {
      setFilteredFrames(framesFilter);
    }
  }, [isAllVisible]);

  return (
    <div className="container">
      {loading ? (
        <>
          <div className="heading-portal">
            <h1>Frames</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </>
      ) : (
        <ProductList
          compare
          list={filteredFrames.length > 0 ? filteredFrames : framesFilter}
          productGroup="frames"
          title="Frames"
          isAllVisible={isAllVisible}
          handleSetIsAllVisible={handleSetIsAllVisible}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default Frames;
