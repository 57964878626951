import ProductList from "components/product-list/ProductList";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect, useState } from "react";
import useTranslation from "utils/useTranslation";

const Accessories = () => {
  const { t } = useTranslation();
  const [isAllVisible, setIsAllVisible] = useState(true);
  const [filteredAccessory, setFilteredAccessory] = useState([]);
  const isAdmin = useSelector(({ user }) => user.data.user.role === "admin");
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);

  const products = useSelector(({ products }) => products.data);
  const loading = useSelector(({ products }) => products.loading);
  const accessories = products.filter(
    (product) =>
      product.productGroup === "TRANSPORT CASES" ||
      product.productGroup === "HARNESSES AND ATTACHMENT HARDWARE" ||
      product.productGroup === "ACCESSORIES"
  );
  const orderedAccessory = accessories?.sort((a, b) => {
    if (a.productCode === "72297") return -1;
    if (b.productCode === "72297") return 1;
    if (a.productCode === "71165") return -1;
    if (b.productCode === "71165") return 1;
    if (a.productCode === "71592") return -1;
    if (b.productCode === "71592") return 1;
    if (a.productCode === "72180") return -1;
    if (b.productCode === "72180") return 1;
    if (a.productCode === "72299") return -1;
    if (b.productCode === "72299") return 1;
    if (a.productCode === "71636") return -1;
    if (b.productCode === "71636") return 1;
    if (a.productCode === "70335") return -1;
    if (b.productCode === "70335") return 1;
    if (a.productCode === "70747") return -1;
    if (b.productCode === "70747") return 1;
    if (a.productCode === "70816") return -1;
    if (b.productCode === "70816") return 1;
    if (a.productCode === "70738") return -1;
    if (b.productCode === "70738") return 1;
    if (a.productCode === "71528") return -1;
    if (b.productCode === "71528") return 1;
    if (a.productCode === "70900") return -1;
    if (b.productCode === "70900") return 1;
    if (a.productCode === "71262") return -1;
    if (b.productCode === "71262") return 1;
    if (a.productCode === "71577") return -1;
    if (b.productCode === "71577") return 1;
    if (a.productCode === "70876") return -1;
    if (b.productCode === "70876") return 1;
    if (a.productCode === "71576") return -1;
    if (b.productCode === "71576") return 1;
    if (a.productCode === "70623-71357-70861-71346-71465-72279") return -1;
    if (b.productCode === "70623-71357-70861-71346-71465-72279") return 1;
    if (a.productCode === "71566") return -1;
    if (b.productCode === "71566") return 1;
    if (a.productCode === "71569") return -1;
    if (b.productCode === "71569") return 1;
    if (a.productCode === "71349") return -1;
    if (b.productCode === "71349") return 1;
    if (a.productCode === "70815") return -1;
    if (b.productCode === "70815") return 1;
    if (a.productCode === "71505") return -1;
    if (b.productCode === "71505") return 1;
    if (a.productCode === "71882") return -1;
    if (b.productCode === "71882") return 1;
    return 0;
  });

  const accessoryFilter = orderedAccessory?.filter(
    (product) => product.isActive !== 0
  );

  const handleSetIsAllVisible = () => {
    setIsAllVisible(!isAllVisible);
  };

  useEffect(() => {
    if (!isAllVisible) {
      setFilteredAccessory(orderedAccessory);
    } else {
      setFilteredAccessory(accessoryFilter);
    }
  }, [isAllVisible]);

  return (
    <div className="dpv-page container">
      {loading ? (
        <>
          <div className="heading-portal">
            <h1>{t("accessories", text)}</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </>
      ) : (
        <ProductList
          compare
          list={
            filteredAccessory.length > 0 ? filteredAccessory : accessoryFilter
          }
          productGroup="accessories"
          title={t("accessories", text)}
          isAllVisible={isAllVisible}
          handleSetIsAllVisible={handleSetIsAllVisible}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default Accessories;

const text = [
  {
    id: "accessories",
    en: "accessories",
    it: "accessori",
  },
];
