const SET_LANGUAGE = "SET_LANGUAGE";

export const languageOptions = ["en", "it"];

// const checkLanguage = (lang) =>
//   languageOptions.some((element) => lang === element) ? lang : "en";

const checkLanguages = (array) => {
  const checkedLanguages = array
    .map((we) => we.split("-")[0])
    .filter((element) => {
      return languageOptions.some((we) => element === we);
    });

  if (checkedLanguages.length === 0) {
    return "en";
  } else {
    return checkedLanguages[0];
  }
};

const initialLang = () => {
  let lang = localStorage.getItem("lang");
  if (!lang) {
    lang = checkLanguages(navigator.languages);
    localStorage.setItem("lang", lang);
  }
  return lang;
};

const initialState = initialLang();

export default function reducerLanguage(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}

export const setLanguage = (lan) => (dispatch) => {
  localStorage.setItem("lang", lan);
  dispatch({
    type: SET_LANGUAGE,
    payload: lan,
  });
};
