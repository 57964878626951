import { baseUrl } from "../config/server";

const API_ROOT_URL = baseUrl;
// const API_ROOT_URL = "http://localhost:8087";

export const getHeaders = (language) => {
  const lan = language || localStorage.getItem("lan") || navigator.language;
  const headers = {
    "Content-Type": "application/json",
    "Accept-Language": lan,
  };
  return headers;
};

const getAuthHeaders = () => {
  const userAS = localStorage.getItem("user");
  let token = "";

  if (userAS) {
    const user = JSON.parse(userAS);
    token = user.authToken;
  }

  return {
    ...getHeaders(),
    Authorization: "Bearer " + token,
  };
};

const requests = {
  // SESSIONS

  login: (credentials) => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },
  loginWithOtp: (credentials) => {
    return {
      url: `${API_ROOT_URL}/account/otp`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },
  checkEmail: (credentials) => {
    return {
      url: `${API_ROOT_URL}/account/check-email`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },
  getUser: () => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  createNewOtp: (email) => {
    return {
      url: `${API_ROOT_URL}/otp/create`,
      method: "POST",
      headers: getHeaders(),
      data: email,
    };
  },

  // PRODUCTS

  getAllProducts: () => {
    return {
      url: `${API_ROOT_URL}/products`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getProductById: (id, userLevel) => {
    return {
      url: `${API_ROOT_URL}/product/${id}`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userLevel },
    };
  },

  // COURSES

  getAllCourses: () => {
    return {
      url: `${API_ROOT_URL}/courses`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getCoursesByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/courses/distributor/${distributorId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getCourseById: (id) => {
    return {
      url: `${API_ROOT_URL}/course/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getUserActiveCourses: (userId) => {
    return {
      url: `${API_ROOT_URL}/courses/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // LEVELS

  getAllLevels: () => {
    return {
      url: `${API_ROOT_URL}/levels`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  // CONTACT
  createContact: (data) => {
    return {
      url: `${API_ROOT_URL}/contact`,
      method: "POST",
      headers: getHeaders(),
      data,
    };
  },
  updateContact: (contactId, data) => {
    return {
      url: `${API_ROOT_URL}/account/authorize/${contactId}`,
      method: "POST",
      headers: getAuthHeaders(),
      data,
    };
  },
  createRenewContact: async (email) => {
    return {
      url: `${API_ROOT_URL}/contact/renew`,
      method: "POST",
      headers: getHeaders(),
      data: { email },
    };
  },
  getAllContacts: () => {
    return {
      url: `${API_ROOT_URL}/contacts`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  // getContactsByDistributorId: (distributorId) => {
  //   return {
  //     url: `${API_ROOT_URL}/contacts`,
  //     method: "POST",
  //     headers: getAuthHeaders(),
  //     data: { ...distributorId },
  //   };
  // },

  // USERS
  getAllUsers: () => {
    return {
      url: `${API_ROOT_URL}/users`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getUsersByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/users`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { ...distributorId },
    };
  },
  updateUser: (userId, updateData) => {
    return {
      url: `${API_ROOT_URL}/user/${userId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },

  activeUserAllCourses: (userId) => {
    return {
      url: `${API_ROOT_URL}/user/courses/${userId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  activeUserAllDocumentsUser: (userId) => {
    return {
      url: `${API_ROOT_URL}/user/documents/user/${userId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  activeUserAllDocumentsCourses: (userId) => {
    return {
      url: `${API_ROOT_URL}/user/documents/courses/${userId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  activeUserAllFirmware: (userId) => {
    return {
      url: `${API_ROOT_URL}/user/firmware/${userId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  activeUserAllSoftware: (userId) => {
    return {
      url: `${API_ROOT_URL}/user/software/${userId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  activeUserAllPricelists: (userId) => {
    return {
      url: `${API_ROOT_URL}/user/pricelists/${userId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  updateUserCourse: (userCourseId, updateData) => {
    return {
      url: `${API_ROOT_URL}/user/course/${userCourseId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  createUserCourse: (userId, courseId) => {
    return {
      url: `${API_ROOT_URL}/user/course`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userId, courseId },
    };
  },
  updateUserFirmware: (userFirmwareId, updateData) => {
    return {
      url: `${API_ROOT_URL}/user/firmware/${userFirmwareId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  createUserFirmware: (userId, firmwareCode) => {
    return {
      url: `${API_ROOT_URL}/user/firmware`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userId, firmwareCode },
    };
  },
  updateUserDocument: (userDocumentId, updateData) => {
    return {
      url: `${API_ROOT_URL}/user/documents/${userDocumentId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  createUserDocument: (userId, documentCode) => {
    return {
      url: `${API_ROOT_URL}/user/documents`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userId, documentCode },
    };
  },
  updateUserSoftware: (userSoftwareId, updateData) => {
    return {
      url: `${API_ROOT_URL}/user/software/${userSoftwareId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  createUserSoftware: (userId, softwareCode) => {
    return {
      url: `${API_ROOT_URL}/user/software`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userId, softwareCode },
    };
  },
  updateUserPricelist: (userPricelistId, updateData) => {
    return {
      url: `${API_ROOT_URL}/user/pricelists/${userPricelistId}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  createUserPricelist: (userId, pricelistCode) => {
    return {
      url: `${API_ROOT_URL}/user/pricelists`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userId, pricelistCode },
    };
  },
  resetUserPassword: (user) => {
    return {
      url: `${API_ROOT_URL}/user/reset/password/${user.userId}`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { user },
    };
  },

  // FIRMWARE
  getAllFirmware: () => {
    return {
      url: `${API_ROOT_URL}/firmware`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getFirmwareByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/firmware/distributor/${distributorId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getFirmwareById: (userId) => {
    return {
      url: `${API_ROOT_URL}/firmware/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updateFirmware: (firmwareId, updateData) => {
    return {
      url: `${API_ROOT_URL}/firmware/${firmwareId}`,
      method: "POST",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  // DOCUMENTS
  getAllDocuments: () => {
    return {
      url: `${API_ROOT_URL}/documents`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getAllDocumentsCourses: () => {
    return {
      url: `${API_ROOT_URL}/docs-courses`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getDocumentsByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/docs/${distributorId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getDocumentsCoursesByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/docs/courses/${distributorId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getDocumentsById: (userId) => {
    return {
      url: `${API_ROOT_URL}/docs/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  downloadDocument: (documentCode, userId) => {
    return {
      url: `${API_ROOT_URL}/document/download/${documentCode}/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  downloadDatasheet: (productCode, userId, lang) => {
    return {
      url: `${API_ROOT_URL}/document/download/${productCode}/${userId}/${lang}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updateDocument: (documentId, updateData) => {
    return {
      url: `${API_ROOT_URL}/document/${documentId}`,
      method: "POST",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },

  // SOFTWARE
  getAllSoftware: () => {
    return {
      url: `${API_ROOT_URL}/software`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getSoftwareByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/software/distributor/${distributorId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getSoftwareById: (userId) => {
    return {
      url: `${API_ROOT_URL}/software/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updateSoftware: (softwareId, updateData) => {
    return {
      url: `${API_ROOT_URL}/software/${softwareId}`,
      method: "POST",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },

  // PRICE LIST
  getAllPricelist: () => {
    return {
      url: `${API_ROOT_URL}/pricelist`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getPricelistByDistributorId: (distributorId) => {
    return {
      url: `${API_ROOT_URL}/pricelist/distributor/${distributorId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getPricelistById: (userId) => {
    return {
      url: `${API_ROOT_URL}/pricelist/${userId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updatePricelist: (pricelistId, updateData) => {
    return {
      url: `${API_ROOT_URL}/pricelist/${pricelistId}`,
      method: "POST",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },

  // HISTORY
  getAllUsersDownloadHistory: (contentType, contentId) => {
    return {
      url: `${API_ROOT_URL}/history-download/${contentType}/${contentId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  // HISTORY
  insertUserHistoryDownload: (userId, contentType, contentId) => {
    return {
      url: `${API_ROOT_URL}/history-download`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { userId, contentType, contentId },
    };
  },
  // HELP
  sendHelpMessage: (helpData, userEmail) => {
    return {
      url: `${API_ROOT_URL}/help/message`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { helpData, userEmail },
    };
  },
};

export default requests;
