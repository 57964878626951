import React from "react";
import "./FullScreenImage.scss";
import Rotation from "react-rotation";
import { Close } from "assets/icons/icons";
import { baseUrl } from "config/server";

const FullscreenImage = ({ product, handleClose }) => {
  const gallery3D = product?.gallery.filter((item) =>
    item.image?.includes("3D")
  );

  return (
    <div className="fullscreen-layer">
      <div className="image-container">
        {" "}
        {gallery3D.length > 0 ? (
          <div
            style={{
              position: "relative",
              width: getImgWidth(product.productCode),
            }}
          >
            <Rotation cycle={true}>
              {gallery3D.map((item, i) => (
                <img
                  key={`key_fullscreenimage_${i}`}
                  src={`${baseUrl}/${item.image}`}
                  alt=""
                />
              ))}
            </Rotation>
          </div>
        ) : (
          <img
            style={{
              position: "relative",
              width: getImgWidth(product.productCode),
            }}
            src={`${baseUrl}/images/${product.productCode}.png`}
            alt=""
          />
        )}
        <Close className="close" onClick={() => handleClose()} />
      </div>
    </div>
  );
};

export default FullscreenImage;

const getImgWidth = (code) => {
  switch (code) {
    // 70212
    case "70212":
      return "90%";
    // 70278
    case "70728":
      return "90%";
    // 70335
    case "70335":
      return "90%";
    // 70738
    case "70738":
      return "60%";
    // 70747
    case "70747":
      return "60%";
    // 70816
    case "70816":
      return "90%";
    // 70876
    case "70876":
      return "70%";
    // 70900
    case "70900":
      return "70%";
    // 71174
    case "71174":
      return "90%";
    // 71196
    case "71196":
      return "90%";
    // 71278
    case "71278":
      return "40%";
    // 71290
    case "71290":
      return "70%";
    // 71293
    case "71293-71294":
      return "60%";
    // 71347
    case "71347":
      return "60%";
    // 71398
    case "71398":
      return "70%";
    // 71403
    case "71403":
      return "90%";
    // 71405
    case "71405":
      return "70%";
    // 71455-71456
    case "71455-71456":
      return "40%";
    // 71528
    case "71528":
      return "70%";
    // 71529
    case "71529":
      return "90%";
    // 71562
    case "71562":
      return "70%";
    // 71571-71572
    case "71571-71572":
      return "40%";
    // 71576
    case "71576":
      return "40%";
    // 71577
    case "71577":
      return "70%";
    // 71621
    case "71621":
      return "90%";
    // 71624
    case "71624":
      return "90%";
    // 71625
    case "71625":
      return "90%";
    // 71641
    case "71641":
      return "70%";
    // 71736
    case "71736":
      return "60%";
    // 71869
    case "71869":
      return "70%";
    // 71869
    case "72169":
      return "60%";
    // 71869
    case "72292":
      return "100%";
    // 71869
    case "72293":
      return "100%";
    // 71869
    case "72294":
      return "100%";
    // 71869
    case "72295":
      return "100%";
    // 71869
    case "72296":
      return "100%";

    default:
      return "100%";
  }
};
