import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Sidebar from "components/sidebar/Sidebar";
import "./Portal.scss";
import Dpv from "./dpv/Dpv";
import Dpd from "./dpd/Dpd";
import NavigationSystem from "./navigation-systems/NavigationSystem";
import DryTransportContainers from "./dry-transport-containers/DryTransportContainers";
import Accessories from "./accessories/Accessories";
import Training from "./training/Training";
import Frames from "./frames/Frames";
import SpareParts from "./spare-parts/SpareParts";
import Catalog from "./catalog/Catalog";
import Assistenza from "./help/Help";
import Profilo from "./account/Account";
import SingleProduct from "./single-product/SingleProduct";
import DpvComparazione from "./dpv/dpv-comparison/DpvComparison";
import PermissionDenied from "./permission-denied-page/PermissionDeniedPage";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Close } from "assets/icons/icons";
import moment from "moment";
import useTranslation from "utils/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "reducers/user";
import Company from "./company/Company";
// import { createRenewContact } from "reducers/contacts";
// import Spinner from "components/spinner/Spinner";
// import { getAllProducts } from "reducers/products";

const routes = [
  { path: "/dpv/comparazione", component: DpvComparazione, minLevel: 1 },
  { path: "/dpv/:model", component: SingleProduct, minLevel: 1 },
  { path: "/dpv", component: Dpv, minLevel: 1 },
  { path: "/dpd/comparazione", component: DpvComparazione, minLevel: 1 },
  { path: "/dpd/:model", component: SingleProduct, minLevel: 1 },
  { path: "/dpd", component: Dpd, minLevel: 1 },
  {
    path: "/navigation-systems/comparazione",
    component: DpvComparazione,
    minLevel: 1,
  },
  { path: "/navigation-systems/:model", component: SingleProduct, minLevel: 1 },
  { path: "/navigation-systems", component: NavigationSystem, minLevel: 1 },
  {
    path: "/dry-transport-containers/comparazione",
    component: DpvComparazione,
    minLevel: 1,
  },
  {
    path: "/dry-transport-containers/:model",
    component: SingleProduct,
    minLevel: 1,
  },
  {
    path: "/dry-transport-containers",
    component: DryTransportContainers,
    minLevel: 1,
  },
  {
    path: "/accessories/comparazione",
    component: DpvComparazione,
    minLevel: 1,
  },
  { path: "/training/:model", component: SingleProduct, minLevel: 1 },
  { path: "/training", component: Training, minLevel: 1 },
  { path: "/accessories/:model", component: SingleProduct, minLevel: 1 },
  { path: "/accessories", component: Accessories, minLevel: 1 },
  {
    path: "/frames/comparazione",
    component: DpvComparazione,
    minLevel: 1,
  },
  { path: "/frames/:model", component: SingleProduct, minLevel: 1 },
  { path: "/frames", component: Frames, minLevel: 1 },
  {
    path: "/spare-parts/comparazione",
    component: DpvComparazione,
    minLevel: 1,
  },
  { path: "/spare-parts/:model", component: SingleProduct, minLevel: 1 },
  { path: "/spare-parts", component: SpareParts, minLevel: 1 },
  { path: "/catalog", component: Catalog, minLevel: 1 },
  { path: "/help", component: Assistenza, minLevel: 1 },
  { path: "/company", component: Company, minLevel: 1 },
  { path: "/account", component: Profilo, minLevel: 1 },
];

const Portal = () => {
  const user = useSelector(({ user }) => user.data.user);
  const contact = useSelector(({ contacts }) => contacts.data);
  const loading = useSelector(({ contacts }) => contacts.loading);
  const { pathname } = useLocation();
  const ref = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  //get user from local storage
  const userFromStorage = JSON.parse(localStorage.getItem("user"));

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [daysToExpirationDate, setDaysToExpirationDate] = useState(null);

  const isPopupNotSeenToday = () => {
    const today = moment().format("YYYY-MM-DD");

    const lastSeen =
      sessionStorage.getItem("popupSeen") &&
      moment(sessionStorage.getItem("popupSeen")).format("YYYY-MM-DD");

    return !lastSeen || today > lastSeen;
  };

  useEffect(() => {
    const days = moment(user?.validTo).diff(moment(), "days");
    setDaysToExpirationDate(days);
  }, []);

  useEffect(() => {
    if (
      daysToExpirationDate &&
      daysToExpirationDate < 30 &&
      isPopupNotSeenToday()
    ) {
      setIsPopupOpen(true);
    }
  }, [daysToExpirationDate]);

  const text = [
    {
      id: "info",
      it: `Attenzione, le tue credenziali scadranno tra ${
        daysToExpirationDate + 1
      } giorni`,
      en: `Warning, your credentials will expire in ${
        daysToExpirationDate + 1
      } days`,
    },
    {
      id: "infoLastDay",
      it: `Attenzione, le tue credenziali scadranno domani`,
      en: `Warning, your credentials will expire tomorrow`,
    },
    {
      id: "rinnova",
      it: "richiedi rinnovo",
      en: "request renewal",
    },
  ];

  const closePopup = () => {
    // sessionStorage.setItem("popupSeen", moment().format("YYYY-MM-DD"));
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (contact) {
      setTimeout(() => {
        closePopup();
      }, 3000);
    }
  }, [contact]);

  useEffect(() => ref.current.scrollTo(0, 0), [pathname]);

  const setOneHourCookie = () => {
    // check if cookie exists
    if (document.cookie.indexOf("activityCookie") >= 0) {
      const date = new Date();
      date.setTime(date.getTime() + 60 * 60 * 1000);
      document.cookie =
        "activityCookie=cookieValue;expires=" + date.toUTCString();
    } else {
      dispatch(logoutUser(history));
    }
  };

  return (
    <div onClick={() => setOneHourCookie()} className="portal-page">
      {!!isPopupOpen && (
        // <div className="layer">
        <div className="warning-popup">
          {daysToExpirationDate === 1
            ? t("infoLastDay", text)
            : t("info", text)}

          <Close className="close-icon" onClick={() => closePopup()} />
        </div>
        // </div>
      )}
      <Sidebar />

      <div
        className="section-content"
        ref={ref}
        style={{ overflowX: "hidden", border: "1px solid goldenrod" }}
      >
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={`key_route_${index}`}
              path={route.path}
              component={
                user?.userLevel >= route.minLevel
                  ? route.component
                  : PermissionDenied
              }
            />
          ))}
          <Redirect to="/dpd" />
        </Switch>
      </div>
    </div>
  );
};

export default Portal;
