import { Fragment, useEffect, useState } from "react";

function renderPlaceholder(type) {
  switch (type) {
    case "productsList":
      return (
        <>
          <div style={{ display: "flex", paddingTop: "1rem" }}>
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case "coursesList":
      return (
        <>
          <div style={{ display: "flex", paddingTop: "1rem" }}>
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 150 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 150 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 150 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="ph-item" style={{ flex: 5 }}>
              <div className="ph-col-12">
                <div className="ph-picture" style={{ height: 200 }}></div>
                <div className="ph-row">
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty "></div>
                  <div className="ph-col-6 big"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );

    case "singleProduct":
      return (
        <>
          <div style={{ display: "flex", paddingTop: "3rem", width: "100%" }}>
            <div className="ph-item" style={{ width: "100%" }}>
              <div className="ph-col-6">
                <div className="ph-picture" style={{ height: 440 }}></div>
              </div>
              <div className="ph-col-6">
                <div className="ph-row">
                  <div className="ph-col-12 big" style={{ height: 40 }}></div>
                  <div className="ph-col-2 big"></div>
                  <div className="ph-col-10 empty "></div>
                  <div className="ph-col-12 empty"></div>
                  <div className="ph-col-12 empty"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                  <div className="ph-col-12"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case "userRow":
      return (
        <>
          <div className="ph-item">
            <div
              className="ph-col-5 "
              style={{ backgroundColor: "#f5f5f5" }}
            ></div>
            <div className="ph-col-2 empty"></div>
            <div
              className="ph-col-5 "
              style={{ backgroundColor: "#f5f5f5" }}
            ></div>
          </div>
        </>
      );

    default:
  }
}

export default function Placeholders({ type, number }) {
  const [placeholders, setPlaceholders] = useState([]);

  useEffect(() => {
    const list = [];

    for (let i = 0; i < number; i++) {
      list.push(renderPlaceholder(type));
    }

    setPlaceholders(list);
  }, [number, type]);

  return placeholders.map((element, i) => (
    <Fragment key={`key_palceholders_${i}`}>{element}</Fragment>
  ));
}
