import useComponentVisible from "utils/useComponentVisible";
import { useDispatch, useSelector } from "react-redux";
import { languageOptions, setLanguage } from "reducers/language";
import "./SelectLanguage.scss";

const SelectLanguage = () => {
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false, true);
  const activeLanguage = useSelector(({ language }) => language);
  const dispatch = useDispatch();

  const handleChange = (lan) => {
    setIsComponentVisible(false);
    dispatch(setLanguage(lan));
  };

  const filterOptions = () =>
    languageOptions.filter((value) => value !== activeLanguage);

  return (
    <div
      ref={ref}
      className={`select-language ${isComponentVisible ? "active" : ""}`}
    >
      <div className="btn secondary light placeholder" onClick={toggling}>
        {activeLanguage}
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {filterOptions().map((lan, i) => (
            <div
              key={`key_optionlanguage_${lan}_${i}`}
              onClick={() => handleChange(lan)}
              className="option btn secondary light"
            >
              {lan}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLanguage;
