import { NavLink, useHistory } from "react-router-dom";
import "./Sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUser, logoutUser } from "reducers/user";
import useTranslation from "utils/useTranslation";
import { User } from "assets/icons/icons";
import SelectLanguage from "../../views/welcome/navbar/select-language/SelectLanguage";
import Logo from "shared/logo/Logo";
import { useEffect } from "react";

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.data.user);
  const history = useHistory();
  const { t } = useTranslation();

  const isUser = user?.role === "user";
  const isAdmin = user?.role === "admin";
  const isDistributor = user?.role === "distributor";

  const logout = () => dispatch(logoutUser(history));
  useEffect(() => {
    if (!user) {
      console.log("get account from sidebar", user);
      dispatch(getUser());
    }
  }, []);

  return (
    <div className="sidebar">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Logo />
        <SelectLanguage />
      </div>

      <div className="sidebar__section user-section bb-light">
        <h5>{t("welcome", text)}</h5>

        <div className="user-info">
          <User />

          {!!user && (
            <div className="user-info__detail">
              <div className="info-name">
                {isDistributor
                  ? `${user.name}`
                  : `${user.firstname} ${user.lastname}`}
              </div>

              {isDistributor ? (
                <div className="info-level">{t("distributor", text)}</div>
              ) : isAdmin ? (
                <div className="info-level">{t("admin", text)}</div>
              ) : (
                <div className="info-level">
                  {t("level", text)} {user.level?.userLevel}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="sidebar__section menu-section bb-light">
        <div className="menu-section__sections">
          {sections.map(({ to, title, minLevel }, i) => {
            return (
              user?.userLevel >= minLevel && (
                <NavLink
                  to={to}
                  key={`key_section_${i}`}
                  className="btn secondary light"
                  activeClassName="primary"
                >
                  {t(title, text)}
                </NavLink>
              )
            );
          })}
        </div>

        <div className="menu-section__account">
          <NavLink
            className="btn tertiary"
            activeClassName="primary"
            to="/account"
          >
            {t("account")}
          </NavLink>
          <div className="btn secondary light logout" onClick={logout}>
            {t("logout", text)}
          </div>
        </div>
      </div>

      <div className="sidebar__section trademark-section">® Suex Srl</div>
    </div>
  );
};

export default Sidebar;

const sections = [
  {
    title: "dpd",
    to: "/dpd",
    minLevel: 1,
  },
  {
    title: "frames",
    to: "/frames",
    minLevel: 1,
  },
  {
    title: "dpv",
    to: "/dpv",
    minLevel: 1,
  },
  {
    title: "navigationSystems",
    to: "/navigation-systems",
    minLevel: 1,
  },
  {
    title: "dryTransportContainers",
    to: "/dry-transport-containers",
    minLevel: 1,
  },
  // {
  //   title: "training",
  //   to: "/training",
  //   minLevel: 1,
  // },
  {
    title: "accessories",
    to: "/accessories",
    minLevel: 1,
  },
  {
    title: "spareParts",
    to: "/spare-parts",
    minLevel: 1,
  },
  // {
  //   title: "catalog",
  //   to: "/catalog",
  //   minLevel: 1,
  // },
  {
    title: "company",
    to: "/company",
    minLevel: 1,
  },
  {
    title: "help",
    to: "/help",
    minLevel: 1,
  },
];

const text = [
  {
    id: "dpv",
    en: "dpv",
    it: "dpv",
  },
  {
    id: "welcome",
    en: "welcome",
    it: "benvenuto",
  },
  {
    id: "navigationSystems",
    en: "navigation systems",
    it: "sistemi di navigazione",
  },
  {
    id: "dryTransportContainers",
    en: "dry transport containers",
    it: "dry transport containers",
  },
  {
    id: "accessories",
    en: "accessories",
    it: "accessori",
  },
  {
    id: "spareParts",
    en: "spare parts",
    it: "ricambi",
  },
  {
    id: "frames",
    en: "frames",
    it: "frames",
  },
  {
    id: "catalog",
    en: "catalog",
    it: "catalogo",
  },
  {
    id: "help",
    en: "help",
    it: "aiuto",
  },
  {
    id: "logout",
    en: "logout",
    it: "esci",
  },
  {
    id: "distributor",
    en: "distributor",
    it: "distributore",
  },
  {
    id: "admin",
    en: "admin",
    it: "amministratore",
  },
  {
    id: "company",
    en: "company",
    it: "azienda",
  },
];
