import { useSelector } from "react-redux";

export const useTranslation = () => {
  const activeLanguage = useSelector(({ language }) => language);
  const t = (id, array = generalTexts) => {
    const text = array.find((text) => text.id === id);
    // return text ? text[activeLanguage] : `${id}[toTranslate]`;
    return text ? text[activeLanguage] : id;
  };
  return { t };
};

export default useTranslation;

const generalTexts = [
  {
    id: "request",
    en: "request access",
    it: "richiedi accesso",
  },
  {
    id: "login",
    en: "login",
    it: "accedi",
  },
  {
    id: "courses",
    en: "courses",
    it: "corsi",
  },
  {
    id: "account",
    en: "account",
    it: "profilo utente",
  },
  {
    id: "firstname",
    en: "name",
    it: "nome",
  },
  {
    id: "lastname",
    en: "lastname",
    it: "cognome",
  },
  {
    id: "company",
    en: "company",
    it: "azienda",
  },
  {
    id: "address",
    en: "address",
    it: "indirizzo",
  },
  {
    id: "phone",
    en: "phone",
    it: "fisso",
  },
  {
    id: "mobile",
    en: "mobile",
    it: "cellulare",
  },
  {
    id: "city",
    en: "city",
    it: "città",
  },
  {
    id: "zip",
    en: "zip code",
    it: "cap",
  },
  {
    id: "country",
    en: "COUNTRY",
    it: "PAESE",
  },
  {
    id: "request textasterisco apice",
    en: "request",
    it: "richiesta",
  },
  {
    id: "message",
    en: "message",
    it: "messaggio",
  },
  {
    id: "privacy",
    en: "I Agree to Privacy Policy*",
    it: "Accetto i termini sulla privacy*",
  },
  {
    id: "marketing",
    en: "I Agree to receive marketing communications",
    it: "Do il consenso a ricevere comunicazioni commerciali",
  },
  {
    id: "send",
    en: "send request",
    it: "invia richiesta",
  },
  {
    id: "selectCategory",
    en: "select category",
    it: "seleziona la categoria",
  },
  {
    id: "sortByProduct",
    en: "sort by product",
    it: "ordina per prodotto",
  },
  {
    id: "sortByType",
    en: "sort by type",
    it: "ordina per tipologia",
  },
  {
    id: "play",
    en: "play video",
    it: "riproduci video",
  },
  {
    id: "troubleshooting",
    en: "troubleshooting / faq",
    it: "troubleshooting / faq",
  },
  {
    id: "taxcode",
    en: "tax code",
    it: "codice fiscale",
  },
  {
    id: "campiObbligatori",
    en: "* required fields",
    it: "* campi obbligatori",
  },
];
