import { ArrowDown, ArrowUp, Minus } from "assets/icons/icons";
// import { faqs } from "debug-items/faq";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "./TroubleshootingFaq.scss";

const TroubleshootingFaq = ({ product }) => {
  const faqs = product.faqs;
  const { t } = useTranslation();
  const [activeFaq, setActiveFaq] = useState(null);
  const activeLanguage = useSelector(({ language }) => language);

  const handleToggle = (i) => setActiveFaq(i === activeFaq ? null : i);
  return (
    <>
      {!!faqs && faqs.length > 0 && (
        <div className="dpv__troubleshooting-faq dpv-section">
          <h2>{t("troubleshooting")}</h2>

          <div className="troubleshooting-list">
            <div className="troubleshooting-list-wrapper subsection-pad">
              {faqs.map(({ questionIt, questionEn, answers }, i) => (
                <div
                  key={`key_faqslist_${i}`}
                  className="troubleshooting-list__faq"
                >
                  <div
                    onClick={() => handleToggle(i)}
                    className={`question bb-dark ${
                      activeFaq === i ? "opacity" : ""
                    }`}
                  >
                    {activeLanguage === "it" ? (
                      <h4 className="no-mar">{questionIt}</h4>
                    ) : (
                      <h4 className="no-mar">{questionEn}</h4>
                    )}

                    {activeFaq === i ? <ArrowUp /> : <ArrowDown />}
                  </div>

                  {i === activeFaq && (
                    <div className="answer-list">
                      {answers.map((answer, i) => (
                        <div
                          key={`key_faqs_2_${i}`}
                          className="answer-list__answer"
                        >
                          {answers.length > 1 && (
                            <Minus style={{ marginRight: 8 }} />
                          )}
                          {activeLanguage === "it" ? (
                            <div className="answer-text">{answer.answerIt}</div>
                          ) : (
                            <div className="answer-text">{answer.answerEn}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <p>{t("centroAssistenza", text)}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TroubleshootingFaq;

const text = [
  {
    id: "centroAssistenza",
    it: "* operazioni da eseguire in centro di assistenza autorizzata SUEX.",
    en: "* operations to be carried out in an authorized Suex service center.",
  },
];
