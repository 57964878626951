import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import favicon32 from "assets/favicon/favicon-32.png";
import favicon180 from "assets/favicon/favicon-180.png";
import favicon192 from "assets/favicon/favicon-192.png";
import favicon270 from "assets/favicon/favicon-270.png";

const MyHelmet = () => {
  const [path, setPath] = useState(formatAddress());
  const { pathname } = useLocation();

  useEffect(() => setPath(formatAddress()), [pathname]);

  return (
    <Helmet>
      <title>{path}Suex Special Operations</title>
      <link rel="icon" href={favicon32} sizes="32x32" />
      <link rel="icon" href={favicon180} sizes="192x192" />
      <link rel="apple-touch-icon" href={favicon192} />
      <meta name="msapplication-TileImage" content={favicon270} />
    </Helmet>
  );
};

export default MyHelmet;

const formatAddress = () => {
  let address = window.location.pathname.split("/");
  if (address[1] !== "") {
    let arr = [];
    let exludeWords = ["e", "di"];
    arr = address[1].split("-");
    return (
      arr
        .map((word, i) => {
          return exludeWords.includes(word) && i !== 0
            ? [word]
            : word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ") + " - "
    );
  } else {
    return "";
  }
};
