import ProductList from "components/product-list/ProductList";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect, useState } from "react";
import useTranslation from "utils/useTranslation";

const NavigationSystem = () => {
  const { t } = useTranslation();
  const [isAllVisible, setIsAllVisible] = useState(true);
  const [filteredNavSys, setFilteredNavSys] = useState([]);
  const isAdmin = useSelector(({ user }) => user.data.user.role === "admin");
  const dispatch = useDispatch();
  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);
  const products = useSelector(({ products }) => products.data);
  const loading = useSelector(({ products }) => products.loading);
  const navigationSystems = products.filter(
    (product) => product.productGroup === "NAVIGATION SYSTEMS"
  );

  const navSysFilter = navigationSystems?.filter(
    (product) => product.isActive !== 0
  );

  const handleSetIsAllVisible = () => {
    setIsAllVisible(!isAllVisible);
  };

  useEffect(() => {
    if (!isAllVisible) {
      setFilteredNavSys(navigationSystems);
    } else {
      setFilteredNavSys(navSysFilter);
    }
  }, [isAllVisible]);

  return (
    <div>
      {loading ? (
        <div className="container">
          <div className="heading-portal">
            <h1>{t("navigationSystem", text)}</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </div>
      ) : (
        <div className="container">
          <ProductList
            compare
            list={filteredNavSys.length > 0 ? filteredNavSys : navSysFilter}
            productGroup="navigation-systems"
            title={t("navigationSystem", text)}
            isAllVisible={isAllVisible}
            handleSetIsAllVisible={handleSetIsAllVisible}
            isAdmin={isAdmin}
          />
        </div>
      )}
    </div>
  );
};

export default NavigationSystem;

const text = [
  {
    id: "navigationSystem",
    en: "navigation system",
    it: "sistemi di navigazione",
  },
];
