import axios from "axios";
import api from "../api/index";

const GET_ALL_COURSES_REQUEST = "SUEX_MILITARY/GET_ALL_COURSES_REQUEST";
const GET_ALL_COURSES_SUCCESS = "SUEX_MILITARY/GET_ALL_COURSES_SUCCESS";
const GET_ALL_COURSES_FAILURE = "SUEX_MILITARY/GET_ALL_COURSES_FAILURE";

const GET_ALL_COURSES_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_ALL_COURSES_BY_DISTRIBUTOR_ID_REQUEST";
const GET_ALL_COURSES_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_ALL_COURSES_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_ALL_COURSES_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_ALL_COURSES_BY_DISTRIBUTOR_ID_FAILURE";

const GET_COURSE_BY_ID_REQUEST = "SUEX_MILITARY/GET_COURSE_BY_ID_REQUEST";
const GET_COURSE_BY_ID_SUCCESS = "SUEX_MILITARY/GET_COURSE_BY_ID_SUCCESS";
const GET_COURSE_BY_ID_FAILURE = "SUEX_MILITARY/GET_COURSE_BY_ID_FAILURE";

const GET_USER_COURSES_REQUEST = "SUEX_MILITARY/GET_USER_COURSES_REQUEST";
const GET_USER_COURSES_SUCCESS = "SUEX_MILITARY/GET_USER_COURSES_SUCCESS";
const GET_USER_COURSES_FAILURE = "SUEX_MILITARY/GET_USER_COURSES_FAILURE";

const RESET_COURSE = "SUEX_MILITARY/RESET_COURSE";

const initialState = {
  loading: false,
  loadingSingle: false,
  loadingUserCourses: false,
  data: [],
  distributorCourses: [],
  error: false,
  singleCourse: null,
  userCourses: [],
};

export default function reducerCourses(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COURSES_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        singleCourse: null,
      };

    case GET_ALL_COURSES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_ALL_COURSES_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_COURSES_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorCourses: action.payload,
        singleCourse: null,
      };

    case GET_ALL_COURSES_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        distributorCourses: [],
        error: action.payload,
      };
    case GET_COURSE_BY_ID_REQUEST:
      return { ...state, loadingSingle: true, error: false };

    case GET_COURSE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleCourse: action.payload,
      };

    case GET_COURSE_BY_ID_FAILURE:
      return {
        ...state,
        loadingSingle: false,
        singleCourse: null,
        error: action.payload,
      };

    case GET_USER_COURSES_REQUEST:
      return { ...state, loadingUserCourses: true, error: false };

    case GET_USER_COURSES_SUCCESS:
      return {
        ...state,
        loadingUserCourses: false,
        userCourses: action.payload,
      };

    case GET_USER_COURSES_FAILURE:
      return {
        ...state,
        loadingUserCourses: false,
        userCourses: null,
        error: action.payload,
      };

    case RESET_COURSE:
      return {
        ...state,
        singleCourse: null,
      };

    default:
      return state;
  }
}

export const getAllCourses = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_COURSES_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllCourses();

      let courses = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_COURSES_SUCCESS,
        payload: courses.data,
      });

      return courses.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_COURSES_FAILURE,
        payload: error,
      });
    }
  };
};
export const getCoursesByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_COURSES_BY_DISTRIBUTOR_ID_REQUEST,
    });

    try {
      const { url, method, headers } =
        api.getCoursesByDistributorId(distributorId);

      let courses = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_COURSES_BY_DISTRIBUTOR_ID_SUCCESS,
        payload: courses.data,
      });

      return courses.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_COURSES_BY_DISTRIBUTOR_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getCourseById = (courseCode) => {
  return async (dispatch) => {
    dispatch({
      type: GET_COURSE_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getCourseById(courseCode);

      let course = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_COURSE_BY_ID_SUCCESS,
        payload: course.data,
      });

      return course.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_COURSE_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};
export const getUserActiveCourses = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_COURSES_REQUEST,
    });

    try {
      const { url, method, headers } = api.getUserActiveCourses(userId);

      let course = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_USER_COURSES_SUCCESS,
        payload: course.data,
      });

      return course.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_USER_COURSES_FAILURE,
        payload: error,
      });
    }
  };
};

export const resetSingleCourse = () => {
  return {
    type: RESET_COURSE,
  };
};
