import Select from "components/select/Select";
import { baseUrl } from "config/server";
import { useState } from "react";
import useTranslation from "utils/useTranslation";
import "./WatchInAction.scss";

const WatchInAction = ({ list, title }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="watch-in-action__container dpv-section">
        {/* <iframe className="watch-in-action__video"  src={list[0]?.videoUrl}></iframe> */}
        <video
          autoPlay="autoplay"
          muted
          className="watch-in-action__video"
          controls="controls"
          name={list[0]?.name}
        >
          <source src={`${baseUrl}/${list[0]?.videoUrl}`} />
        </video>
        <h4 className="no-mar watch-in-action__title">
          {title || t("guarda", text)}
        </h4>
      </div>
    </>
  );
};

export default WatchInAction;

const text = [
  {
    id: "guarda",
    en: "Watch in action",
    it: "Guarda in azione",
  },
];
