import NavigationArrow from "components/navigation/NavigationArrow";
import { baseUrl } from "config/server";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useSwiper from "utils/useSwiper";
import useTranslation from "utils/useTranslation";
import "./Accessories.scss";

const Accessories = ({ type, list, product }) => {
  const activeLanguage = useSelector(({ language }) => language);
  const { t } = useTranslation();
  const { startFrom, handleStart } = useSwiper(list);
  const size = 4;
  // order accessories by specific productCode

  const orderedAccessories = list.sort((a, b) => {
    if (a.accessoryCode === "71196") return -1;
    if (b.accessoryCode === "71196") return 1;
    if (a.accessoryCode === "71168") return -1;
    if (b.accessoryCode === "71168") return 1;
    if (a.accessoryCode === "70335") return -1;
    if (b.accessoryCode === "70335") return 1;
    if (a.accessoryCode === "71262") return -1;
    if (b.accessoryCode === "71262") return 1;
    return 0;
  });

  return (
    <>
      {list?.length > 0 && (
        <div className="product-page-accessories dpv-section">
          <h2>{t(type, text)}</h2>
          <div className="accessories-list">
            {orderedAccessories
              ?.slice(startFrom, startFrom + size)
              .map((element, i) => (
                <Link
                  to={`/accessories/${element.accessoryCode}`}
                  key={`key_orderedaccessory_${element.accessoryCode}_${i}`}
                  className="accessories-list__item"
                >
                  <div className="image-container">
                    <img
                      src={`${baseUrl}/images/${element.accessoryCode}.png`}
                      alt={`${element.nameEn}_image`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>

                  <span className="text-dark">
                    Cod. {element.accessoryCode}
                  </span>

                  {activeLanguage.includes("it") ? (
                    <h4 className="no-mar">{element.nameIt}</h4>
                  ) : (
                    <h4 className="no-mar">{element.nameEn}</h4>
                  )}
                </Link>
              ))}
            {list.length > 4 && (
              <NavigationArrow handleIndex={handleStart} size={size} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Accessories;

const text = [
  {
    id: "includedAccessories",
    en: "included accessories",
    it: "accessori inclusi",
  },
  {
    id: "compatibledAccessories",
    en: "compatibled accessories",
    it: "accessori compatibili",
  },
  {
    id: "prodottiComp",
    en: "compatible products",
    it: "prodotti compatibili",
  },
  {
    id: "replacement",
    en: "spare parts",
    it: "ricambi",
  },
];
