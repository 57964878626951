import "./ProductList.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import useTranslation from "utils/useTranslation";
import { Check, Dots } from "assets/icons/icons";
import { useSelector } from "react-redux";
import { baseUrl } from "config/server";

const getSubValues = (list) => {
  const values = [];

  list?.forEach((element) => {
    values.push(element?.productSubgroup);
  });
  //FILTER UNIQUE VALUES
  return [...new Set(values)];
};

const ProductList = ({
  list,
  compare,
  productGroup,
  title,
  handleSetIsAllVisible,
  isAllVisible,
  isAdmin,
}) => {
  const [compareList, setCompareList] = useState([]);
  const activeLanguage = useSelector(({ language }) => language);
  const checkActive = (el) => compareList.find((value) => value === el);
  const { t } = useTranslation();

  const handleCompareList = (el) => {
    if (compareList.length < 3 && !compareList.includes(el)) {
      setCompareList([...compareList, el]);
    } else {
      setCompareList(compareList.filter((value) => value !== el));
    }
  };

  const subGroupList = getSubValues(list);

  return (
    <div className="product-list-component">
      {compare && (
        <div className="heading-portal">
          <h1>{title}</h1>
          {isAdmin && (
            <>
              {isAllVisible && isAdmin ? (
                <div
                  className="btn primary pad dark uppercase"
                  onClick={() => handleSetIsAllVisible(false)}
                >
                  {t("showNotListed", text)}
                </div>
              ) : (
                <div
                  className="btn primary pad dark uppercase"
                  onClick={() => handleSetIsAllVisible(true)}
                >
                  {t("hideNotListed", text)}
                </div>
              )}
            </>
          )}
          {compareList.length > 1 && (
            <Link
              to={{
                pathname: `${productGroup}/comparazione`,
                state: compareList,
              }}
              className="compare-cta"
            >
              <span>{t("compare", text)}</span>

              <Dots />
            </Link>
          )}
        </div>
      )}

      <div className="product-list-component__list">
        {subGroupList.length > 1 ? (
          subGroupList.map((subGroup) => {
            return (
              <>
                <h2 style={{ marginTop: "1.5rem" }}>{subGroup}</h2>
                <div className="list subsection-pad">
                  {list
                    .filter((element) => element.productSubgroup === subGroup)
                    .map((element, i) => (
                      <div
                        className={`list__dpv ${
                          checkActive(element.productCode) ? "active" : ""
                        }`}
                        key={`product_${element.productCode}_${i}`}
                      >
                        {compare && (
                          <Check
                            className="select-compare"
                            onClick={() =>
                              handleCompareList(element.productCode)
                            }
                          />
                        )}

                        <Link
                          className="we"
                          to={`/${productGroup}/${
                            element.dpdCode || element.productCode
                          }`}
                        >
                          <div className="image-container">
                            <img
                              src={`${baseUrl}/catalogues/${element.productCode}.png`}
                              alt={`${element.nameEn}_image`}
                              className={`${
                                element.productCode ===
                                "70623-71357-70861-71346-71465-72279"
                                  ? "bigger-img"
                                  : ""
                              } `}
                              style={
                                element.productCode === "70816"
                                  ? { transform: "scale(0.8)" }
                                  : {}
                              }
                            />
                          </div>

                          <span className="text-dark">
                            Cod. {element.dpdCode || element.productCode}
                          </span>

                          {activeLanguage.includes("it") ? (
                            <h4 className="no-mar">{element.nameIt}</h4>
                          ) : (
                            <h4 className="no-mar">{element.nameEn}</h4>
                          )}
                        </Link>
                      </div>
                    ))}
                </div>
              </>
            );
          })
        ) : (
          <div className="list subsection-pad">
            {list?.map((element, i) => (
              <div
                className={`list__dpv ${
                  checkActive(element?.productCode) ? "active" : ""
                }`}
                key={`${i}_${element?.productCode}`}
              >
                {compare && (
                  <Check
                    className="select-compare"
                    onClick={() => handleCompareList(element?.productCode)}
                  />
                )}

                <Link
                  className="we"
                  to={`/${productGroup}/${
                    element?.dpdCode || element?.productCode
                  }`}
                >
                  <div className="image-container">
                    <img
                      src={`${baseUrl}/catalogues/${element?.productCode}.png`}
                      alt={`${element?.nameEn}_image`}
                      // fix provvisorio per problema di dimensione immagine
                      style={{
                        transform: `scale(${getScale(element?.productCode)})`,
                      }}
                    />
                  </div>

                  <span className="text-dark">
                    Cod. {element?.dpdCode || element?.productCode}
                  </span>

                  {activeLanguage.includes("it") ? (
                    <h4 className="no-mar">{element?.nameIt}</h4>
                  ) : (
                    <h4 className="no-mar">{element?.nameEn}</h4>
                  )}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;

const text = [
  {
    id: "compare",
    en: "compare",
    it: "compara",
  },
  {
    id: "showNotListed",
    en: "show products not listed",
    it: "visualizza prodotti non a listino",
  },
  {
    id: "hideNotListed",
    en: "hide products not listed",
    it: "nascondi prodotti non a listino",
  },
];

const getScale = (code) => {
  switch (code) {
    case "71624":
    case "71174":
    case "71629":
    case "71622":
    case "71683":
    case "71623":
    case "71684":
    case "71621":
    case "71529":
      return 1.1;
    case "71618-71619":
      return 0.85;
    case "71845":
    case "71512":
      return 1.2;
    case "72292":
      return 1;
    case "72293":
      return 1.4;
    case "72294":
      return 1.1;
    case "72295":
      return 1.1;
    case "72296":
      return 1.4;
    default:
      return 1;
  }
};
