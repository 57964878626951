import ProductList from "components/product-list/ProductList";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect } from "react";
import useTranslation from "utils/useTranslation";

const Training = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);
  const products = useSelector(({ products }) => products.data);
  const loading = useSelector(({ products }) => products.loading);
  const training = products.filter(
    (product) => product.productGroup === "TRAINING"
  );
  return (
    <div className="container">
      {loading ? (
        <>
          <div className="heading-portal">
            <h1>{t("training", text)}</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </>
      ) : (
        <ProductList
          compare
          list={training}
          productGroup="training"
          title={t("training", text)}
        />
      )}
    </div>
  );
};

export default Training;

const text = [
  {
    id: "training",
    en: "Training",
    it: "Training",
  },
];
