import { baseUrl } from "config/server";
import { useSelector } from "react-redux";
import "./Catalog.scss";
const Catalog = () => {
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  return (
    <object
      className="pdf-container"
      data={
        isIta
          ? `${baseUrl}/catalogues/CATALOGO_MILITARE_ITA.pdf`
          : `${baseUrl}/catalogues/CATALOGO_MILITARE_ENG.pdf`
      }
      type="application/pdf"
    >
      <div style={{ width: "100%", height: "100%" }}>
        No online PDF viewer installed
      </div>
    </object>
  );
};

export default Catalog;
