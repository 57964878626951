import ProductList from "components/product-list/ProductList";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect, useState } from "react";
import useTranslation from "utils/useTranslation";

const SpareParts = () => {
  const { t } = useTranslation();
  const [isAllVisible, setIsAllVisible] = useState(true);
  const [filteredSpareParts, setFilteredSpareParts] = useState([]);
  const isAdmin = useSelector(({ user }) => user.data.user.role === "admin");
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);
  const products = useSelector(({ products }) => products.data);
  const loading = useSelector(({ products }) => products.loading);
  const spareParts = products.filter(
    (product) =>
      product.productGroup === "SPARE KITS" ||
      product.productGroup === "PROPELLERS" ||
      product.productGroup === "BATTERIES" ||
      product.productGroup === "CHARGERS"
  );

  const sparePartsFilter = spareParts?.filter(
    (product) => product.isActive !== 0
  );

  const handleSetIsAllVisible = () => {
    setIsAllVisible(!isAllVisible);
  };

  useEffect(() => {
    if (!isAllVisible) {
      setFilteredSpareParts(spareParts);
    } else {
      setFilteredSpareParts(sparePartsFilter);
    }
  }, [isAllVisible]);

  return (
    <div className="container">
      {loading ? (
        <>
          <div className="heading-portal">
            <h1>{t("spareParts", text)}</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </>
      ) : (
        <ProductList
          compare
          list={
            filteredSpareParts.length > 0
              ? filteredSpareParts
              : sparePartsFilter
          }
          productGroup="spare-parts"
          title={t("spareParts", text)}
          isAllVisible={isAllVisible}
          handleSetIsAllVisible={handleSetIsAllVisible}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default SpareParts;

const text = [
  {
    id: "spareParts",
    en: "Spare Parts",
    it: "Ricambi",
  },
];
