import "./Help.scss";
import useTranslation from "utils/useTranslation";
import { Location, Phone } from "assets/icons/icons";
import { resetMessageCreated, sendHelpMessage } from "reducers/help";
import { Profiler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "reducers/user";

const Help = () => {
  const [helpData, setHelpData] = useState({
    email: "",
    phone: "",
    message: "",
    privacy: false,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(({ user }) => user.data.user);
  const messageCreated = useSelector(({ help }) => help.messageCreated);

  useEffect(() => {
    if (!userData) {
      console.log("get user from help");
      dispatch(getUser());
    }
  }, []);

  useEffect(() => {
    console.log("messageCreated", messageCreated);
    if (messageCreated === true) {
      setTimeout(() => {
        dispatch(resetMessageCreated());
      }, 6500);
      setHelpData({
        email: "",
        phone: "",
        message: "",
        privacy: false,
      });
    }
  }, [messageCreated]);

  const handleChange = (target, value) => {
    setHelpData((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const handleSendMessage = () => {
    dispatch(sendHelpMessage(helpData, userData.email));
  };

  const _isDisabled = () => {
    if (
      helpData.message === "" ||
      helpData.privacy === false ||
      messageCreated === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="help-page container">
      {messageCreated === true && (
        <div className="message-created">
          <h3>{t("messageSent", text)}</h3>
        </div>
      )}
      <h1 className="border">{t("help", text)}</h1>

      <div className="help-page__content">
        <div className="help-content-wrapper subsection-pad">
          <div className="help__contacts">
            <div className="mini-section">
              <h5>suex srl</h5>

              <div className="mini-section__list bb-dark">
                <div className="item-contact">
                  <Location />

                  <div className="text">
                    Via Roma 261/35
                    <br />
                    31020 Villorba (TV) - Italy
                  </div>
                </div>

                <div className="item-contact">
                  <Phone />

                  <div className="text">+39 0422 444849</div>
                </div>
              </div>

              <p>{t("timeContact", text)}</p>
            </div>
          </div>

          <div className="help__forms">
            <p>{t("introForm", text)}</p>
            <span
              style={{
                fontWeight: "500",
                fontSize: "0.8rem",
                textTransform: "uppercase",
                textDecoration: "underline",
              }}
            >
              {t("mandatoryData", text)}
            </span>

            <div className="form-help">
              <div className="form-input">
                <input
                  id="email"
                  type="email"
                  value={helpData?.email}
                  placeholder={t("email", text)}
                  className="searchbar-container__searchbar-input"
                  onChange={(e) =>
                    handleChange("email", e.target.value?.toLowerCase())
                  }
                />
                <input
                  id="phone"
                  type="number"
                  value={helpData?.phone}
                  placeholder={t("phone", text)}
                  className="searchbar-container__searchbar-input"
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
                <input
                  id="message"
                  type="text"
                  value={helpData?.message}
                  placeholder={t("message", text) + "*"}
                  className="searchbar-container__searchbar-input"
                  onChange={(e) =>
                    handleChange("message", e.target.value?.toLowerCase())
                  }
                />
              </div>

              <div className="checkbox">
                <div className="checkbox-form">
                  <input
                    id="privacy"
                    checked={helpData?.privacy}
                    onChange={(e) => {
                      handleChange("privacy", e.target.checked);
                    }}
                    type="checkbox"
                  />
                  <label htmlFor="privacy">{t("privacy")}</label>
                </div>
              </div>
            </div>

            <div
              id="sendButton"
              className={
                _isDisabled()
                  ? "btn tertiary pad left disabled"
                  : "btn tertiary pad left"
              }
              onClick={() => handleSendMessage()}
            >
              {t("sendMessage", text)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;

const text = [
  {
    id: "timeContact",
    en: "You can contact us from Monday to Friday from 8:30 to 13:00 and from 14:00 to 17:30",
    it: "Puoi contattarci dal Lunedi al Venerdi dalle 8:30 alle 13:00 e dalle 14:00 alle 17:30",
  },
  {
    id: "introForm",
    en: "Fill out the form and send us your request for any information",
    it: "Compila il modulo e inviaci la richiesta per qualsiasi informazione",
  },
  {
    id: "sendMessage",
    en: "send message",
    it: "invia messaggio",
  },
  {
    id: "help",
    en: "help",
    it: "aiuto",
  },
  {
    id: "name",
    en: "name",
    it: "nome",
  },
  {
    id: "surname",
    en: "surname",
    it: "cognome",
  },
  {
    id: "phone",
    en: "phone",
    it: "telefono",
  },
  {
    id: "message",
    en: "message",
    it: "messaggio",
  },
  {
    id: "mandatoryData",
    en: "mandatory information *",
    it: "informazioni obbligatorie *",
  },
  {
    id: "messageSent",
    en: "Message Sent",
    it: "Messaggio Inviato",
  },
];
