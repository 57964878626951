import { ArrowLeft, ArrowRight } from "assets/icons/icons";
import "./NavigationArrow.scss";

const NavigationArrow = ({ handleIndex, size }) => {
  return (
    <div className="navigation-arrows-component">
      <div className="arrow-container">
        <ArrowLeft onClick={() => handleIndex(-size)} />
      </div>
      <div className="arrow-container">
        <ArrowRight onClick={() => handleIndex(size)} />
      </div>
    </div>
  );
};

export default NavigationArrow;
