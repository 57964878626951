import ProductList from "components/product-list/ProductList";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect, useState } from "react";

const DryTransportContainers = () => {
  const dispatch = useDispatch();
  const [isAllVisible, setIsAllVisible] = useState(true);
  const [filteredDryTContainers, setFilteredDryTContainers] = useState([]);
  const isAdmin = useSelector(({ user }) => user.data.user.role === "admin");
  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);

  const products = useSelector(({ products }) => products.data);
  const loading = useSelector(({ products }) => products.loading);
  const dryTransportContainers = products.filter(
    (product) => product.productGroup === "DRY TRANSPORT CONTAINERS"
  );

  const dryTContainersFilter = dryTransportContainers?.filter(
    (product) => product.isActive !== 0
  );

  const handleSetIsAllVisible = () => {
    setIsAllVisible(!isAllVisible);
  };

  useEffect(() => {
    if (!isAllVisible) {
      setFilteredDryTContainers(dryTransportContainers);
    } else {
      setFilteredDryTContainers(dryTContainersFilter);
    }
  }, [isAllVisible]);

  return (
    <div>
      {loading ? (
        <div className="container">
          <div className="heading-portal">
            <h1>Dry Transport Container</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </div>
      ) : (
        <div className="container">
          <ProductList
            compare
            list={
              filteredDryTContainers.length > 0
                ? filteredDryTContainers
                : dryTContainersFilter
            }
            productGroup="dry-transport-containers"
            title="Dry Transport Container"
            isAllVisible={isAllVisible}
            handleSetIsAllVisible={handleSetIsAllVisible}
            isAdmin={isAdmin}
          />
        </div>
      )}
    </div>
  );
};

export default DryTransportContainers;
