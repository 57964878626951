import useTranslation from "utils/useTranslation";
import "../AdminPanel.scss";
import { useEffect, useMemo, useState } from "react";
import { Popup } from "components/popup/Popup";
import UserDetailRow from "./UserDetailRow";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "reducers/user";
import Spinner from "components/spinner/Spinner";
import { MagnifyingGlass } from "assets/icons/icons";
import moment from "moment";

const UserRow = ({
  user,
  deactivateUser,
  activateUser,
  resetPassword,
  courses,
  firmware,
  software,
  pricelist,
  documents,
  documentsCourses,
  account,
  isDistributor,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isResetPopupOpen, setIsResetPopupOpen] = useState(false);
  const [selectedUserResetPassword, setSelectedUserResetPassword] = useState(0);
  const [isDetailsPopupOpen, setIsDetailsPopupOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const activeLanguage = useSelector(({ language }) => language);
  const resetLoading = useSelector(({ user }) => user.resetLoading);
  const isIta = activeLanguage === "it";

  const handleUserDownloadPermission = (userId, status) => {
    dispatch(updateUser(userId, { hasDownload: status }));
  };

  const filteredList = useMemo(() => {
    if (
      selectedDetails?.type === "pricelist" &&
      isDistributor &&
      filter === ""
    ) {
      return selectedDetails?.list
        ?.filter((item) =>
          isIta ? item.nameIt?.toLowerCase() : item.nameEn?.toLowerCase()
        )
        .filter((item) => item.isActive);
    }
    if (filter === "") {
      return selectedDetails?.list;
    }

    if (selectedDetails?.type === "pricelist" && !isDistributor) {
      return selectedDetails?.list
        ?.filter((item) =>
          isIta
            ? item.nameIt?.toLowerCase().includes(filter?.toLowerCase())
            : item.nameEn?.toLowerCase().includes(filter?.toLowerCase())
        )
        .filter((item) => item.isActive);
    }

    return selectedDetails?.list?.filter((item) =>
      isIta
        ? item.nameIt?.toLowerCase().includes(filter?.toLowerCase())
        : item.nameEn?.toLowerCase().includes(filter?.toLowerCase())
    );
  }, [filter, selectedDetails]);

  useEffect(() => {
    console.log("filteredList", filteredList);
  }, [filteredList]);

  return (
    <div
      className={`user-row ${!user.isActive && "disabled"} ${
        !user.isActive && account.role !== "admin" && "disabled"
      }`}
    >
      {isPopupOpen && (
        <Popup
          className="popup__text"
          handleClose={() => setIsPopupOpen(false)}
        >
          <div> {t("confermaDisattivazione", text)} </div>
          <div className="popup__button-container">
            <div
              className="btn secondary pad dark"
              onClick={() => setIsPopupOpen(false)}
            >
              {t("annulla")}{" "}
            </div>
            <div
              className="btn primary pad"
              onClick={() => {
                deactivateUser(user.userId);
                setIsPopupOpen(false);
              }}
            >
              {t("conferma")}{" "}
            </div>
          </div>
        </Popup>
      )}
      {isResetPopupOpen && (
        <Popup
          className="popup__text"
          handleClose={() => setIsResetPopupOpen(false)}
        >
          <div> {t("confermaResetPassword", text)} </div>
          <div className="popup__button-container">
            <div
              className="btn secondary pad dark"
              onClick={() => setIsResetPopupOpen(false)}
            >
              {t("annulla")}
            </div>
            <div
              className="btn primary pad"
              onClick={() => {
                resetPassword(user);
                setSelectedUserResetPassword(user.userId);
                setIsResetPopupOpen(false);
              }}
            >
              {t("conferma")}
            </div>
          </div>
        </Popup>
      )}
      {isDetailsPopupOpen && (
        <Popup
          className="popup__text"
          handleClose={() => setIsDetailsPopupOpen(false)}
        >
          <h4>{t("dettagliUtente", text)}</h4>
          <div className="popup__details-container">
            {user?.userId && (
              <div>
                <span className="popup__details-container__label">
                  {t("userUserId", text)}:{" "}
                </span>
                {user?.userId}
              </div>
            )}
            {user?.sapId && (
              <div>
                <span className="popup__details-container__label">
                  {t("userSapId", text)}:{" "}
                </span>
                {user?.sapId}
              </div>
            )}
            {user?.firstname && (
              <div>
                <span className="popup__details-container__label">
                  {t("userFirstname", text)}:{" "}
                </span>
                {user?.firstname}
              </div>
            )}
            {user?.lastname && (
              <div>
                <span className="popup__details-container__label">
                  {t("userLastname", text)}:{" "}
                </span>
                {user?.lastname}
              </div>
            )}
            {user?.email && (
              <div>
                <span className="popup__details-container__label">
                  {t("userEmail", text)}:{" "}
                </span>
                {user?.email}
              </div>
            )}
            {user?.mobile && (
              <div>
                <span className="popup__details-container__label">
                  {t("userMobile", text)}:{" "}
                </span>
                {user?.mobile}
              </div>
            )}
            {user?.city && (
              <div>
                <span className="popup__details-container__label">
                  {t("userCity", text)}:{" "}
                </span>
                {user?.city}
              </div>
            )}
            {user?.companyName && (
              <div>
                <span className="popup__details-container__label">
                  {t("userCompany", text)}:{" "}
                </span>
                {user?.companyName}
              </div>
            )}
            {user?.country && (
              <div>
                <span className="popup__details-container__label">
                  {t("userLanguage", text)}:{" "}
                </span>
                {user?.country}
              </div>
            )}
            {user?.distributorId && (
              <div>
                <span className="popup__details-container__label">
                  {t("userDistributor", text)}:{" "}
                </span>
                {user?.distributorId}
              </div>
            )}
            {user?.userLevel && (
              <div>
                <span className="popup__details-container__label">
                  {t("userLevel", text)}:{" "}
                </span>
                {user?.userLevel}
              </div>
            )}
            {user?.postalCode && (
              <div>
                <span className="popup__details-container__label">
                  {t("userPostalCode", text)}:{" "}
                </span>
                {user?.postalCode}
              </div>
            )}
            {user?.createdAt && (
              <div>
                <span className="popup__details-container__label">
                  {t("userCreated", text)}:{" "}
                </span>
                {moment(user?.createdAt).format("DD/MM/YYYY")}
              </div>
            )}
          </div>
        </Popup>
      )}
      <div
        className="row-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* ACCOUNT DETAILS */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "15%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "left",
              gap: "3px",
            }}
          >
            {user?.firstname && (
              <span style={{ fontWeight: "600" }}>
                {user?.firstname?.toUpperCase()}
              </span>
            )}
            {user?.lastname && (
              <span style={{ fontWeight: "600" }}>
                {user?.lastname?.toUpperCase()}
              </span>
            )}
          </div>
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {user?.email?.toLowerCase()}
          </span>
          {user?.companyName && (
            <span style={{ fontSize: "12px", fontWeight: "400" }}>
              {user?.companyName}
            </span>
          )}
          {user?.distributorId && (
            <span style={{ fontSize: "12px", fontWeight: "400" }}>
              {user?.distributorId}
            </span>
          )}
          <MagnifyingGlass
            height={30}
            style={{
              width: "30px",
              cursor: "pointer",
              fill: "var(--darkGrey)",
              borderRadius: "50%",
              border: "1px solid var(--darkGrey)",
            }}
            onClick={() => setIsDetailsPopupOpen(true)}
          />
        </div>
        {/* ACCOUNT LEVEL */}
        <div
          className="account-info__level"
          style={{
            width: "5%",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "300",
          }}
        >
          {user?.level?.userLevel}
        </div>
        {/* ACCOUNT CONTENTS */}
        <div
          className="row-container__right-section"
          style={{
            width: "75%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* ROW ACTIONS */}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "5px",
            }}
          >
            {/* DISABLE / ENABLE DOWNLOAD */}
            {(account?.role === "admin" || isDistributor) && (
              <>
                {!user?.hasDownload ? (
                  <div
                    onClick={() =>
                      handleUserDownloadPermission(user.userId, true)
                    }
                    className="btn tertiary pad hover"
                    style={{
                      fontSize: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <span>{t("abilita", text)}</span>
                    <span>{t("donwload", text)}</span>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      handleUserDownloadPermission(user.userId, false)
                    }
                    className="btn tertiary pad hover"
                    style={{
                      fontSize: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <span>{t("disabilita", text)}</span>
                    <span>{t("download", text)}</span>
                  </div>
                )}
              </>
            )}
            {/* DISABLE / ENABLE USER */}
            {user?.isActive ? (
              <div
                onClick={() =>
                  account?.role === "admin"
                    ? deactivateUser(user.userId)
                    : setIsPopupOpen(true)
                }
                className="btn secondary pad danger"
                style={{
                  fontSize: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <span>{t("disattiva", text)}</span>
                <span>{t("utente", text)}</span>
              </div>
            ) : (
              <>
                {account?.role === "admin" || isDistributor ? (
                  <div
                    onClick={() => activateUser(user.userId)}
                    className="btn secondary pad danger"
                    style={{
                      fontSize: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <span>{t("attiva", text)}</span>
                    <span>{t("utente", text)}</span>
                  </div>
                ) : (
                  <div className="btn pad">{t("disattivato", text)}</div>
                )}
              </>
            )}
            {/* RESET PASSWORD */}
            {account?.role === "admin" && (
              <div
                onClick={() => setIsResetPopupOpen(true)}
                className="btn tertiary pad hover"
                style={{
                  fontSize: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {selectedUserResetPassword === user.userId && resetLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <span>{t("reset", text)}</span>
                    <span>{t("password", text)}</span>
                  </>
                )}
              </div>
            )}
          </div>
          <hr />
          {/* ROW CONTENTS */}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {/* CORSI */}
            <div
              onClick={() => {
                setSelectedDetails({ type: "courses", list: courses });
                setIsToggleOpen(!isToggleOpen);
              }}
              className={`btn tertiary pad hover ${
                isToggleOpen && selectedDetails?.type === "courses"
                  ? "active"
                  : ""
              } ${
                user?.courses?.length === 0 &&
                account?.role !== "admin" &&
                !isDistributor
                  ? "disabled"
                  : ""
              }`}
              style={{ flex: 1, fontSize: "9px" }}
            >
              {t("corsi", text)}
            </div>
            {/* DOCUMENTI */}
            <div
              onClick={() => {
                setSelectedDetails({ type: "documents", list: documents });
                setIsToggleOpen(!isToggleOpen);
              }}
              className={`btn tertiary pad hover ${
                isToggleOpen && selectedDetails?.type === "documents"
                  ? "active"
                  : ""
              } ${
                user?.documents?.length === 0 &&
                account?.role !== "admin" &&
                !isDistributor
                  ? "disabled"
                  : ""
              }`}
              style={{ flex: 1, fontSize: "9px" }}
            >
              {t("documents", text)}
            </div>
            {/* DOCUMENTI CORSI */}
            <div
              onClick={() => {
                setSelectedDetails({
                  type: "documentsCourses",
                  list: documentsCourses,
                });
                setIsToggleOpen(!isToggleOpen);
              }}
              className={`btn tertiary pad hover ${
                isToggleOpen && selectedDetails?.type === "documentsCourses"
                  ? "active"
                  : ""
              } ${
                user?.documents?.length === 0 &&
                account?.role !== "admin" &&
                !isDistributor
                  ? "disabled"
                  : ""
              }`}
              style={{ flex: 1, fontSize: "9px" }}
            >
              {t("documentsCourses", text)}
            </div>
            {/* FIRMWARE */}
            <div
              onClick={() => {
                setSelectedDetails({ type: "firmware", list: firmware });
                setIsToggleOpen(!isToggleOpen);
              }}
              className={`btn tertiary pad hover ${
                isToggleOpen && selectedDetails?.type === "firmware"
                  ? "active"
                  : ""
              } ${
                user?.firmware?.length === 0 &&
                account?.role !== "admin" &&
                !isDistributor
                  ? "disabled"
                  : ""
              }`}
              style={{ flex: 1, fontSize: "9px" }}
            >
              {t("firmware", text)}
            </div>
            {/* SOFTWARE */}
            <div
              onClick={() => {
                setSelectedDetails({ type: "software", list: software });
                setIsToggleOpen(!isToggleOpen);
              }}
              className={`btn tertiary pad hover ${
                isToggleOpen && selectedDetails?.type === "software"
                  ? "active"
                  : ""
              } ${
                user?.software?.length === 0 &&
                account?.role !== "admin" &&
                !isDistributor
                  ? "disabled"
                  : ""
              }`}
              style={{ flex: 1, fontSize: "9px" }}
            >
              {t("software", text)}
            </div>
            {/* PRICELIST */}
            {account?.role === "admin" && (
              <div
                onClick={() => {
                  setSelectedDetails({ type: "pricelist", list: pricelist });
                  setIsToggleOpen(!isToggleOpen);
                }}
                className={`btn tertiary pad hover ${
                  isToggleOpen && selectedDetails?.type === "pricelist"
                    ? "active"
                    : ""
                } ${
                  user?.pricelist?.length === 0 &&
                  account?.role !== "admin" &&
                  !isDistributor
                    ? "disabled"
                    : ""
                }`}
                style={{
                  flex: 1,
                  fontSize: "9px",
                }}
              >
                {t("pricelist", text)}
              </div>
            )}
          </div>
        </div>
      </div>
      {isToggleOpen &&
        (account?.role === "admin" || isDistributor ? (
          <div className="user-data-container">
            <UserDetailRow
              detail={"detail"}
              user={user}
              isActiveAll
              detailsType={selectedDetails?.type}
              setFilter={setFilter}
              filter={filter}
            />
            {filteredList.length > 0 &&
              filteredList?.map((detail, i) => (
                <UserDetailRow
                  key={`key_${
                    detail.courseCode ||
                    detail.documentCode ||
                    detail.firmwareCode ||
                    detail.softwareCode ||
                    detail.pricelistCode
                  }_${i}`}
                  detail={detail}
                  account={account}
                  userCourses={user?.courses}
                  userFirmware={user?.firmware}
                  userSoftware={user?.software}
                  userPricelist={user?.pricelist}
                  userDocuments={user?.documents}
                  user={user}
                  detailsType={selectedDetails?.type}
                  setFilter={setFilter}
                  filter={filter}
                />
              ))}
          </div>
        ) : account?.role === "distributor" ? (
          <div className="user-data-container">
            {account[selectedDetails.type].map((detail, i) => (
              <UserDetailRow
                key={`key_${detail.id}_${detail.nameIt}_${detail.nameEn}_${i}`}
                detail={detail}
                user={user}
                userCourses={user?.courses}
                userFirmware={user?.firmware}
                userPricelist={user?.pricelist}
                userDocuments={user?.documents}
                detailsType={selectedDetails?.type}
                setFilter={setFilter}
                filter={filter}
              />
            ))}
          </div>
        ) : (
          !!user &&
          user[selectedDetails.type]?.length > 0 && (
            <div className="user-data-container">
              {user[selectedDetails.type]
                .filter((detail) => !!detail.isActive)
                .map((detail, i) => (
                  <UserDetailRow
                    key={`key_userdetails_2_${detail.id}_${detail.nameIt}_${detail.nameEn}_${i}`}
                    detail={detail}
                    user={user}
                    detailsType={selectedDetails?.type}
                    setFilter={setFilter}
                    filter={filter}
                  />
                ))}
            </div>
          )
        ))}
    </div>
  );
};

const text = [
  {
    id: "registeredFrom",
    en: "User registered from",
    it: "Utente registrato dal",
  },
  {
    id: "userUserId",
    en: "ID",
    it: "ID",
  },
  {
    id: "userSapId",
    en: "SAP ID",
    it: "SAP ID",
  },
  {
    id: "userFirstname",
    en: "Firstname",
    it: "Nome",
  },
  {
    id: "userLastname",
    en: "Lastname",
    it: "Cognome",
  },
  {
    id: "userEmail",
    en: "Email",
    it: "Email",
  },
  {
    id: "userCity",
    en: "City",
    it: "Città",
  },
  {
    id: "userCompany",
    en: "Company",
    it: "Azienda",
  },
  {
    id: "userLanguage",
    en: "Language",
    it: "Lingua",
  },
  {
    id: "userCreated",
    en: "Created on",
    it: "Creato il",
  },
  {
    id: "userDistributor",
    en: "Distributor",
    it: "Distributore",
  },
  {
    id: "userMobile",
    en: "Mobile",
    it: "Telefono",
  },
  {
    id: "userPostalCode",
    en: "Postal code",
    it: "CAP",
  },
  {
    id: "userLevel",
    en: "Level",
    it: "Livello",
  },

  {
    id: "dettagliUtente",
    en: "User details",
    it: "Dettagli utente",
  },
  {
    id: "level",
    en: "Level",
    it: "Livello",
  },
  {
    id: "disattiva",
    it: "Disattiva",
    en: "Disable",
  },
  {
    id: "utente",
    it: "utente",
    en: "user",
  },
  {
    id: "attiva",
    it: "Attiva utente",
    en: "Enable user",
  },
  {
    id: "abilita",
    it: "Abilita",
    en: "Enable",
  },
  {
    id: "disabilita",
    it: "Disabilita",
    en: "Disable",
  },
  {
    id: "download",
    it: "Download",
    en: "Download",
  },
  {
    id: "disattivato",
    it: "Disattivato",
    en: "Disabled",
  },
  {
    id: "corsi",
    it: "Corsi",
    en: "Courses",
  },
  {
    id: "documents",
    it: "Documenti utente",
    en: "Documents User",
  },
  {
    id: "documentsCourses",
    it: "Documenti Corsi",
    en: "Documents Courses",
  },
  {
    id: "software",
    it: "Software",
    en: "Software",
  },
  {
    id: "pricelist",
    it: "Listino Prezzi",
    en: "Price List",
  },
  {
    id: "confermaDisattivazione",
    it: "Sei sicuro di voler disattivare l'utente? Questa operazione non può essere annullata.",
    en: "Are you sure you want to disable the user? This operation cannot be undone.",
  },
  {
    id: "confermaResetPassword",
    it: "Sei sicuro di voler resettare la password dell'utente? Questa operazione non può essere annullata.",
    en: "Are you sure you want to reset the password of the user? This operation cannot be undone.",
  },
  {
    id: "disabilitaDownload",
    it: "Disattiva download",
    en: "Disable download",
  },
  {
    id: "abilitaDownload",
    it: "Abilita download",
    en: "Enable download",
  },
  {
    id: "reset",
    it: "Reset",
    en: "Reset",
  },
  {
    id: "password",
    it: "Password",
    en: "Password",
  },
];

export default UserRow;
