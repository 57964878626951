import Navbar from "./navbar/Navbar";
import "./Welcome.scss";
import useTranslation from "utils/useTranslation";
import { Media } from "assets/icons/icons";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className="welcome-page">
      <Navbar />

      {/* <div className="welcome-page__play-popup">
        <Media />
        <span>{t("play")}</span>
      </div> */}

      {/* <h1>
        suex special
        <br />
        operations
      </h1> */}
    </div>
  );
};

export default Welcome;
