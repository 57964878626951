import { useState } from "react";

const useSwiper = (list) => {
  const [activeFeature, setActiveFeature] = useState(0);

  const handleSwiper = (delta) => {
    if (activeFeature === list.length - 1 && delta === 1) {
      setActiveFeature(0);
    } else if (activeFeature === 0 && delta === -1) {
      setActiveFeature(list.length - 1);
    } else {
      setActiveFeature(activeFeature + delta);
    }
  };

  const [startFrom, setStartFrom] = useState(0);

  const handleStart = (delta) => {
    if ((startFrom === 0) & (delta < 0)) {
      setStartFrom(list.length - (list.length % Math.abs(delta)));
    } else if (
      (list.length - (list.length % Math.abs(delta)) === startFrom) &
      (delta > 0)
    ) {
      setStartFrom(0);
    } else {
      setStartFrom(startFrom + delta);
    }
  };

  return { activeFeature, handleSwiper, startFrom, handleStart };
};

export default useSwiper;
