import { File } from "assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import NavigationArrow from "components/navigation/NavigationArrow";
import { baseUrl } from "config/server";
import { eliminateDuplicates } from "utils/general";
import useSwiper from "utils/useSwiper";
import useTranslation from "utils/useTranslation";
import "./Documents.scss";
import { useEffect } from "react";
import { insertUserHistoryDownload } from "reducers/history";
import { downloadDocument } from "reducers/documents";

const Documents = ({ list, title, subcategory }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const { startFrom, handleStart } = useSwiper(list);
  const dispatch = useDispatch();
  const account = useSelector(({ user }) => user.data.user);
  const options = list.map((element, i) =>
    isIta ? element.categoryIt : element.categoryEn
  );
  const secondOptions = list.map((element, i) =>
    isIta ? element.subcategoryIt : element.subcategoryEn
  );
  const filteredOptions = eliminateDuplicates(options).filter(
    (option) =>
      option !== "Manuale d'Officina" &&
      option !== "Pezzi di ricambio esplosi" &&
      option !== "Workshop Manual" &&
      option !== "Exploded Spare Parts"
  );

  const filteredSecondOptions = eliminateDuplicates(secondOptions);
  const size = 12;

  const handleSetDownload = (content) => {
    dispatch(insertUserHistoryDownload(content));
  };

  return (
    <>
      {list?.length > 0 && (
        <div className="product-page-documents dpv-section">
          {title ? <h2>{title}</h2> : <h2>{t("documents", text)}</h2>}
          {subcategory === true ? (
            <div>
              {filteredSecondOptions?.map((option, i) => (
                <div key={`${option}-${i}`}>
                  <h3 style={{ marginBottom: "5px" }}>
                    {option.toUpperCase()}
                  </h3>
                  <hr
                    style={{
                      marginBottom: "30px",
                      width: "30%",
                      height: "3px",
                      backgroundColor: "#000",
                    }}
                  />
                  <div className="documents-list">
                    {list
                      ?.filter(
                        (doc) =>
                          (isIta ? doc.subcategoryIt : doc.subcategoryEn) ===
                          option
                      )
                      .map((document, i) => (
                        <div
                          key={`${
                            isIta ? document.nameIt : document.nameEn
                          }_${i}`}
                          className="documents-list__item"
                        >
                          <div className="document-preview-icon">
                            <File />

                            <div
                              className="btn primary pad hover"
                              onClick={() => {
                                dispatch(
                                  downloadDocument(
                                    document.documentCode,
                                    account.userId
                                  )
                                );
                                handleSetDownload({
                                  userId: account.userId,
                                  contentId: document.id,
                                  contentType: "document",
                                });
                              }}
                            >
                              download
                            </div>
                          </div>

                          <h5 className="no-mar">
                            {isIta ? document.nameIt : document.nameEn}
                          </h5>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {filteredOptions.map((option, i) => (
                <>
                  <h3>{option.toUpperCase()}</h3>
                  <div className="documents-list">
                    {list
                      ?.slice(startFrom, startFrom + size)
                      .filter(
                        (doc) =>
                          (isIta ? doc.categoryIt : doc.categoryEn) === option
                      )
                      .map((document, i) => (
                        <div
                          key={`key_list_1_${document.documentCode}`}
                          className="documents-list__item"
                        >
                          <div className="document-preview-icon">
                            <File />

                            <div
                              className="btn primary pad hover"
                              onClick={() => {
                                dispatch(
                                  downloadDocument(
                                    document.documentCode,
                                    account.userId
                                  )
                                );
                                handleSetDownload({
                                  userId: account.userId,
                                  contentId: document.id,
                                  contentType: "document",
                                });
                              }}
                            >
                              download
                            </div>
                          </div>

                          <h5 className="no-mar">
                            {isIta ? document.nameIt : document.nameEn}
                          </h5>
                        </div>
                      ))}

                    {list
                      ?.slice(startFrom, startFrom + size)
                      .filter(
                        (doc) =>
                          (isIta ? doc.categoryIt : doc.categoryEn) === option
                      ).length > 4 && (
                      <NavigationArrow handleIndex={handleStart} size={size} />
                    )}
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Documents;

const text = [
  {
    id: "documents",
    en: "documents",
    it: "documenti",
  },
];
