import axios from "axios";
import api from "../api/index";

const GET_ALL_FIRMWARE_REQUEST = "SUEX_MILITARY/GET_ALL_FIRMWARE_REQUEST";
const GET_ALL_FIRMWARE_SUCCESS = "SUEX_MILITARY/GET_ALL_FIRMWARE_SUCCESS";
const GET_ALL_FIRMWARE_FAILURE = "SUEX_MILITARY/GET_ALL_FIRMWARE_FAILURE";

const GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_REQUEST";
const GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_FAILURE";

const UPDATE_FIRMWARE_REQUEST = "SUEX_MILITARY/UPDATE_FIRMWARE_REQUEST";
const UPDATE_FIRMWARE_SUCCESS = "SUEX_MILITARY/UPDATE_FIRMWARE_SUCCESS";
const UPDATE_FIRMWARE_FAILURE = "SUEX_MILITARY/UPDATE_FIRMWARE_FAILURE";

const GET_FIRMWARE_BY_ID_REQUEST = "SUEX_MILITARY/GET_FIRMWARE_BY_ID_REQUEST";
const GET_FIRMWARE_BY_ID_SUCCESS = "SUEX_MILITARY/GET_FIRMWARE_BY_ID_SUCCESS";
const GET_FIRMWARE_BY_ID_FAILURE = "SUEX_MILITARY/GET_FIRMWARE_BY_ID_FAILURE";

const initialState = {
  error: false,
  loading: false,
  data: [],
  distributorFirmware: [],
};

export default function reducerFirmware(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FIRMWARE_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_FIRMWARE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ALL_FIRMWARE_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        distributorFirmware: action.payload,
      };

    case GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        distributorFirmware: [],
        error: action.payload,
      };
    case GET_FIRMWARE_BY_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_FIRMWARE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_FIRMWARE_BY_ID_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case UPDATE_FIRMWARE_REQUEST:
      return { ...state, loading: true, error: false };

    case UPDATE_FIRMWARE_SUCCESS:
      const updatedData = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        data: updatedData,
      };

    case UPDATE_FIRMWARE_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const getAllFirmware = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_FIRMWARE_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllFirmware();

      let firmware = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_FIRMWARE_SUCCESS,
        payload: firmware.data,
      });

      return firmware.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_FIRMWARE_FAILURE,
        payload: error,
      });
    }
  };
};
export const getFirmwareByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_REQUEST,
    });

    try {
      const { url, method, headers } =
        api.getFirmwareByDistributorId(distributorId);

      let firmware = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_SUCCESS,
        payload: firmware.data,
      });

      return firmware.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_FIRMWARE_BY_DISTRIBUTOR_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getFirmwareById = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_FIRMWARE_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getFirmwareById(userId);
      console.log("url", url);
      let firmware = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_FIRMWARE_BY_ID_SUCCESS,
        payload: firmware.data,
      });

      return firmware.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_FIRMWARE_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateFirmware = (id, updateData) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FIRMWARE_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.updateFirmware(id, updateData);

      let firmware = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_FIRMWARE_SUCCESS,
        payload: firmware.data,
      });

      return firmware.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: UPDATE_FIRMWARE_FAILURE,
        payload: error,
      });
    }
  };
};
