import "./Download.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFirmware, getFirmwareById } from "reducers/firmware";
import {
  downloadDocument,
  getAllDocuments,
  getAllDocumentsCourses,
  getDocumentsByDistributorId,
  getDocumentsById,
  getDocumentsCoursesByDistributorId,
} from "reducers/documents";
import { getAllSoftware, getSoftwareById } from "reducers/software";
import { insertUserHistoryDownload } from "reducers/history";
import useTranslation from "utils/useTranslation";
import { baseUrl } from "config/server";
import { Search } from "assets/icons/icons";
import { PDFDocument, rgb, degrees } from "pdf-lib";
import fs from "fs";

const Download = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const account = useSelector(({ user }) => user.data.user);
  const firmware = useSelector(({ firmware }) => firmware.data);
  const documents = useSelector(({ documents }) => documents.data);
  const documentsCourses = useSelector(
    ({ documents }) => documents.docsCourses
  );
  const distributorDocuments = useSelector(
    ({ documents }) => documents.distributorDocuments
  );
  const distributorDocumentsCourses = useSelector(
    ({ documents }) => documents.distributorDocsCourses
  );
  const software = useSelector(({ software }) => software.data);
  const [filterDocumentText, setFilterDocumentText] = useState("");
  const [filterDocumentCoursesText, setFilterDocumentCoursesText] =
    useState("");
  const [filterFirmwareText, setFilterFirmwareText] = useState("");
  const [filterSoftwareText, setFilterSoftwareText] = useState("");

  useEffect(() => {
    if (account?.role === "admin") {
      dispatch(getAllFirmware());
      dispatch(getAllDocuments());
      dispatch(getAllDocumentsCourses());
      dispatch(getAllSoftware());
    } else if (account?.role === "user") {
      dispatch(getFirmwareById(account.userId));
      dispatch(getDocumentsByDistributorId(account.userId));
      dispatch(getDocumentsCoursesByDistributorId(account.userId));
      dispatch(getSoftwareById(account.userId));
    }
  }, [account]);

  //Raggruppare i firmware con lo stesso firmwareCode
  const firmwareGrouped = firmware.reduce((acc, curr) => {
    const found = acc.find((item) => item.firmwareCode === curr.firmwareCode);
    if (found) {
      found.firmwareUrl = curr.firmwareUrl;
      if (found.productCode.includes(curr.productCode)) return acc;
      found.productCode = found.productCode + " & " + curr.productCode;
      return acc;
    } else {
      return [...acc, curr];
    }
  }, []);

  const FilteredDocuments = (
    account?.role === "admin" ? documents : distributorDocuments
  )?.filter((document) => {
    return (
      document.productCode
        ?.toString()
        ?.includes(filterDocumentText?.toString()) ||
      document.nameIt
        ?.toLowerCase()
        ?.includes(filterDocumentText?.toLowerCase()) ||
      document.nameEn?.toLowerCase().includes(filterDocumentText?.toLowerCase())
    );
  });
  const FilteredDocumentsCourses = (
    account?.role === "admin" ? documentsCourses : distributorDocumentsCourses
  )?.filter((document) => {
    return (
      document.productCode
        ?.toString()
        .includes(filterDocumentCoursesText?.toString()) ||
      document.nameIt
        ?.toLowerCase()
        .includes(filterDocumentCoursesText?.toLowerCase())
    );
  });
  const FilteredSoftware = software?.filter((software) => {
    return (
      software.productCode
        ?.toString()
        ?.includes(filterSoftwareText?.toString()) ||
      software.nameIt
        ?.toLowerCase()
        ?.includes(filterSoftwareText?.toLowerCase()) ||
      software.nameEn
        ?.toLowerCase()
        ?.includes(filterSoftwareText?.toLowerCase())
    );
  });
  const FilteredFirmware = firmwareGrouped?.filter((firmware) => {
    return (
      firmware.productCode
        ?.toString()
        ?.includes(filterFirmwareText?.toString()) ||
      firmware.nameIt
        ?.toLowerCase()
        ?.includes(filterFirmwareText?.toLowerCase()) ||
      firmware.nameEn
        ?.toLowerCase()
        ?.includes(filterFirmwareText?.toLowerCase())
    );
  });

  const handleSetDownload = (content) => {
    dispatch(insertUserHistoryDownload(content));
  };

  return (
    <div className="admin-panel">
      {/* DOCUMENTS ROW */}
      {documents?.length > 0 || distributorDocuments?.length > 0 ? (
        <div className="admin-docs-download" style={{ marginBottom: "35px" }}>
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("docsDownload", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterDocumentText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterDocumentText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredDocuments &&
            FilteredDocuments?.length < 6 &&
            FilteredDocuments?.length > 0 ? (
              FilteredDocuments.map((document, i) => (
                <DocsRow
                  document={document}
                  handleSetDownload={handleSetDownload}
                  key={`key_documents_${i}_${
                    isIta ? document.nameIt : document.nameEn
                  }`}
                />
              ))
            ) : FilteredDocuments && FilteredDocuments?.length >= 6 ? (
              <div className="admin-download-pagination">
                {FilteredDocuments.map((document, i) => (
                  <DocsRow
                    document={document}
                    handleSetDownload={handleSetDownload}
                    key={`key_documents_2_${
                      isIta ? document.nameIt : document.nameEn
                    }_${i}`}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* DOCUMENTS COURSES ROW */}
      {documentsCourses?.length > 0 ||
      distributorDocumentsCourses?.length > 0 ? (
        <div className="admin-docs-download" style={{ marginBottom: "35px" }}>
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("docsCoursesDownload", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterDocumentCoursesText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterDocumentCoursesText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredDocumentsCourses &&
            FilteredDocumentsCourses?.length < 6 &&
            FilteredDocumentsCourses?.length > 0 ? (
              FilteredDocumentsCourses.map((document, i) => (
                <DocsRow
                  document={document}
                  handleSetDownload={handleSetDownload}
                  key={`key_documents_courses_${i}_${
                    isIta ? document.nameIt : document.nameEn
                  }`}
                />
              ))
            ) : FilteredDocumentsCourses &&
              FilteredDocumentsCourses?.length >= 6 ? (
              <div className="overflow-pagination">
                {FilteredDocumentsCourses.map((document, i) => (
                  <DocsRow
                    document={document}
                    handleSetDownload={handleSetDownload}
                    key={`key_documents_courses_2_${i + 10}_${
                      isIta ? document.nameIt : document.nameEn
                    }`}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* FIRMWARE ROW */}
      {firmware?.length > 0 ? (
        <div
          className="admin-firmware-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("firmwareDownload", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterFirmwareText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterFirmwareText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredFirmware &&
            FilteredFirmware?.length < 6 &&
            FilteredFirmware?.length > 0 ? (
              FilteredFirmware?.map((firmware, i) => (
                <FirmwareRow
                  key={`key_firmware_${
                    isIta ? document.nameIt : document.nameEn
                  }_${i}`}
                  firmware={firmware}
                  handleSetDownload={handleSetDownload}
                />
              ))
            ) : FilteredFirmware && FilteredFirmware?.length >= 6 ? (
              <div className="admin-download-pagination">
                {FilteredFirmware.map((firmware, i) => (
                  <FirmwareRow
                    key={`key_firmware_2_${i}_${
                      isIta ? document.nameIt : document.nameEn
                    }`}
                    firmware={firmware}
                    handleSetDownload={handleSetDownload}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* SOFTWARE ROW */}
      {software?.length > 0 ? (
        <div
          className="admin-software-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("softwareDownload", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterSoftwareText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterSoftwareText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredSoftware &&
            FilteredSoftware?.length < 6 &&
            FilteredSoftware?.length > 0 ? (
              FilteredSoftware?.map((software, i) => (
                <SoftwareRow
                  key={`key_software_${
                    isIta ? document.nameIt : document.nameEn
                  }_${i}`}
                  software={software}
                  handleSetDownload={handleSetDownload}
                />
              ))
            ) : FilteredSoftware && FilteredSoftware?.length >= 6 ? (
              <div className="admin-download-pagination">
                {FilteredSoftware.map((software, i) => (
                  <SoftwareRow
                    key={`key_software_2_${i}_${
                      isIta ? document.nameIt : document.nameEn
                    }`}
                    software={software}
                    handleSetDownload={handleSetDownload}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Download;

//* DOCUMENTS ROW
const DocsRow = ({ document, ...props }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const account = useSelector(({ user }) => user.data.user);
  const dispatch = useDispatch();

  return (
    <div className="download-row">
      <div className="row-container">
        <div
          className="flex-row justify-between"
          style={{
            minWidth: "80%",
            maxWidth: "80%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* NOME */}
          <p style={{ width: "45%" }}>
            {isIta ? document.nameIt : document.nameEn}
          </p>
          {/* CODICE PRODOTTO */}
          <p style={{ width: "30%" }}>
            {document.productCode
              ? t("codiceProdotto", text) + document.productCode
              : t("documentoCorso", text)}
          </p>
          {/* CATEGORIA */}
          <p style={{ width: "25%", marginRight: "15px" }}>
            {isIta ? document.categoryIt : document.categoryEn}
          </p>
        </div>
        <div
          style={{
            width: "20%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          {/* DOWNLOAD */}
          <div style={{ maxWidth: "132px" }}>
            <div
              className="btn primary pad hover"
              onClick={() => {
                dispatch(
                  downloadDocument(document.documentCode, account.userId)
                );
                props.handleSetDownload({
                  userId: account.userId,
                  contentId: document.id,
                  contentType: "document",
                });
              }}
            >
              {t("download", text)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//* FIRMWARE ROW
const FirmwareRow = ({ firmware, ...props }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const account = useSelector(({ user }) => user.data.user);
  const isAdmin = account?.role === "admin";

  const timestamp = () => {
    return new Date().getTime();
  };

  return (
    <div className="download-row">
      <div className="row-container">
        <div
          className="flex-row justify-between"
          style={{
            minWidth: "80%",
            maxWidth: "80%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* NOME */}
          <p style={{ width: "45%" }}>
            {isIta ? firmware.nameIt : firmware.nameEn}
          </p>
          {/* CODICE PRODOTTO */}
          <p style={{ width: "30%" }}>
            {firmware?.productCode
              ? t("codiceProdotto", text) + firmware?.productCode
              : t("documentoCorso", text)}
          </p>
          {/* CATEGORIA */}
          <p
            style={{
              width: "25%",
              marginRight: "15px",
            }}
          >
            {isIta ? firmware.categoryIt : firmware.categoryEn}
          </p>
        </div>
        <div
          style={{
            width: "20%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          {/* DOWNLOAD */}
          <div style={{ maxWidth: "132px" }}>
            <a
              href={baseUrl + "/" + firmware.firmwareUrl + "?ts=" + timestamp()}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="btn primary pad hover"
              onClick={() =>
                props.handleSetDownload({
                  userId: account.userId,
                  contentId: firmware.id,
                  contentType: "firmware",
                })
              }
            >
              {t("download", text)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

//* SOFTWARE ROW
const SoftwareRow = ({ software, ...props }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const account = useSelector(({ user }) => user.data.user);
  const isAdmin = account?.role === "admin";

  const timestamp = () => {
    return new Date().getTime();
  };

  return (
    <div className="download-row">
      <div className="row-container">
        <div
          className="flex-row justify-between"
          style={{
            minWidth: "80%",
            maxWidth: "80%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* NOME */}
          <p style={{ width: "45%" }}>
            {isIta ? software.nameIt : software.nameEn}
          </p>
          {/* CODICE PRODOTTO */}
          <p style={{ width: "30%" }}>
            {software?.productCode
              ? t("codiceProdotto", text) + software?.productCode
              : t("documentoCorso", text)}
          </p>
          {/* CATEGORIA */}
          <p
            style={{
              width: "25%",
              marginRight: "15px",
            }}
          >
            {isIta ? software.categoryIt : software.categoryEn}
          </p>
        </div>
        <div
          style={{
            width: "20%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          {/* DOWNLOAD */}
          <div style={{ maxWidth: "132px" }}>
            <a
              href={baseUrl + "/" + software.softwareUrl + "?ts=" + timestamp()}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="btn primary pad hover"
              onClick={() =>
                props.handleSetDownload({
                  userId: account.userId,
                  contentId: software.id,
                  contentType: "software",
                })
              }
            >
              {t("download", text)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const text = [
  {
    id: "docsDownload",
    it: "Elenco Documenti Utente",
    en: "Documents User List",
  },
  {
    id: "docsCoursesDownload",
    it: "Elenco Documenti corsi",
    en: "Documents Courses List",
  },
  {
    id: "firmwareDownload",
    it: "Elenco Firmware",
    en: "Firmware",
  },
  {
    id: "softwareDownload",
    it: "Elenco Software",
    en: "Software",
  },
  {
    id: "utentiAttesa",
    it: "Utenti in attesa di approvazione",
    en: "Users waiting for approval",
  },
  {
    id: "codiceProdotto",
    it: "Cod: ",
    en: "Cod: ",
  },
  {
    id: "documentoCorso",
    it: "Documento Corso",
    en: "Document Course",
  },
  {
    id: "ultimaModifica",
    it: "Ultima Modifica: ",
    en: "Last Update: ",
  },
  {
    id: "categoria",
    it: "Categoria: ",
    en: "Category: ",
  },
  {
    id: "download",
    it: "Scarica",
    en: "Download",
  },
  {
    id: "storico",
    it: "Storico",
    en: "History",
  },
  {
    id: "nessunRisultato",
    it: "Nessun risultato trovato",
    en: "No results found",
  },
];
