import axios from "axios";
import api from "../api/index";

const CREATE_CONTACT_REQUEST = "SUEX_MILITARY/CREATE_CONTACT_REQUEST";
const CREATE_CONTACT_SUCCESS = "SUEX_MILITARY/CREATE_CONTACT_SUCCESS";
const CREATE_CONTACT_FAILURE = "SUEX_MILITARY/CREATE_CONTACT_FAILURE";

const UPDATE_CONTACT_REQUEST = "SUEX_MILITARY/UPDATE_CONTACT_REQUEST";
const UPDATE_CONTACT_SUCCESS = "SUEX_MILITARY/UPDATE_CONTACT_SUCCESS";
const UPDATE_CONTACT_FAILURE = "SUEX_MILITARY/UPDATE_CONTACT_FAILURE";

const GET_ALL_CONTACTS_REQUEST = "SUEX_MILITARY/GET_ALL_CONTACTS_REQUEST";
const GET_ALL_CONTACTS_SUCCESS = "SUEX_MILITARY/GET_ALL_CONTACTS_SUCCESS";
const GET_ALL_CONTACTS_FAILURE = "SUEX_MILITARY/GET_ALL_CONTACTS_FAILURE";

const GET_CONTACTS_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_CONTACTS_BY_DISTRIBUTOR_ID_REQUEST";
const GET_CONTACTS_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_CONTACTS_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_CONTACTS_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_CONTACTS_BY_DISTRIBUTOR_ID_FAILURE";

const initialState = {
  loading: false,
  data: null,
  error: false,
  contactsList: [],
};

export default function reducerContacts(state = initialState, action) {
  switch (action.type) {
    case CREATE_CONTACT_REQUEST:
      return { ...state, loading: true, error: false };

    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        contactsList: [...state.contactsList, action.payload],
      };

    case CREATE_CONTACT_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    case UPDATE_CONTACT_REQUEST:
      return { ...state, loading: true, error: false };

    case UPDATE_CONTACT_SUCCESS:
      const updatedContact = action.payload;
      let updatedContactList = [
        ...state.contactsList.filter(
          (contact) => contact.id !== updatedContact.id
        ),
        updatedContact,
      ];

      // sort contacts by creation date

      updatedContactList = updatedContactList.sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
      );

      return {
        ...state,
        loading: false,
        data: action.payload,
        contactsList: updatedContactList,
      };

    case UPDATE_CONTACT_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    case GET_ALL_CONTACTS_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactsList: action.payload,
      };

    case GET_ALL_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        contactsList: [],
        error: action.payload,
      };

    case GET_CONTACTS_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_CONTACTS_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        contactsList: action.payload,
      };

    case GET_CONTACTS_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        contactsList: [],
        error: action.payload,
      };

    default:
      return state;
  }
}

export const createContact = (newContact) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CONTACT_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.createContact(newContact);

      let contact = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: CREATE_CONTACT_SUCCESS,
        payload: contact.data,
      });

      return contact.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: CREATE_CONTACT_FAILURE,
        payload: error.response.data,
      });
    }
  };
};

export const updateContact = (contactId, status) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CONTACT_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.updateContact(
        contactId,
        status
      );

      let contact = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_CONTACT_SUCCESS,
        payload: contact.data,
      });

      return contact.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: UPDATE_CONTACT_FAILURE,
        payload: error,
      });
    }
  };
};

export const createRenewContact = (email) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CONTACT_REQUEST,
    });

    try {
      const { url, method, headers, data } = await api.createRenewContact(
        email
      );
      let renewContact = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: CREATE_CONTACT_SUCCESS,
        payload: renewContact.data,
      });

      return renewContact.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: CREATE_CONTACT_FAILURE,
        payload: error.response.data,
      });
    }
  };
};

export const getAllContacts = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_CONTACTS_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllContacts();

      let contacts = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_CONTACTS_SUCCESS,
        payload: contacts.data,
      });

      return contacts.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_CONTACTS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getContactsByDistributorId = (distributorId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CONTACTS_BY_DISTRIBUTOR_ID_REQUEST,
    });

    try {
      const { url, method, headers, data } =
        api.getContactsByDistributorId(distributorId);

      let contacts = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: GET_CONTACTS_BY_DISTRIBUTOR_ID_SUCCESS,
        payload: contacts.data,
      });

      return contacts.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_CONTACTS_BY_DISTRIBUTOR_ID_FAILURE,
        payload: error,
      });
    }
  };
};
