import "./Logo.scss";
import { Link } from "react-router-dom";
import logo from "assets/logo.png";

const Logo = () => (
  <Link className="logo-component sidebar__section" to="/">
    <img src={logo} alt="" style={{ height: "1.8rem", margin: 0 }} />
  </Link>
);

export default Logo;
