import axios from "axios";
import api from "../api/index";

const GET_ALL_LEVELS_REQUEST = "SUEX_MILITARY/GET_ALL_LEVELS_REQUEST";
const GET_ALL_LEVELS_SUCCESS = "SUEX_MILITARY/GET_ALL_LEVELS_SUCCESS";
const GET_ALL_LEVELS_FAILURE = "SUEX_MILITARY/GET_ALL_LEVELS_FAILURE";

const initialState = {
  error: false,
  loading: false,
  data: [],
};

export default function reducerLevels(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LEVELS_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_ALL_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ALL_LEVELS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const getAllLevels = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_LEVELS_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllLevels();

      let levels = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_ALL_LEVELS_SUCCESS,
        payload: levels.data,
      });

      return levels.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_LEVELS_FAILURE,
        payload: error,
      });
    }
  };
};
