import axios from "axios";
import api from "../api/index";
var CryptoJS = require("crypto-js");

const LOGIN_USER_REQUEST = "SUEX_MILITARY/LOGIN_USER_REQUEST";
const LOGIN_USER_SUCCESS = "SUEX_MILITARY/LOGIN_USER_SUCCESS";
const LOGIN_USER_FAILURE = "SUEX_MILITARY/LOGIN_USER_FAILURE";

const LOGIN_OTP_REQUEST = "SUEX_MILITARY/LOGIN_OTP_REQUEST";
const LOGIN_OTP_SUCCESS = "SUEX_MILITARY/LOGIN_OTP_SUCCESS";
const LOGIN_OTP_FAILURE = "SUEX_MILITARY/LOGIN_OTP_FAILURE";

const CHECK_EMAIL_REQUEST = "SUEX_MILITARY/CHECK_EMAIL_REQUEST";
const CHECK_EMAIL_SUCCESS = "SUEX_MILITARY/CHECK_EMAIL_SUCCESS";
const CHECK_EMAIL_FAILURE = "SUEX_MILITARY/CHECK_EMAIL_FAILURE";

const GET_ALL_USERS_REQUEST = "SUEX_MILITARY/GET_ALL_USERS_REQUEST";
const GET_ALL_USERS_SUCCESS = "SUEX_MILITARY/GET_ALL_USERS_SUCCESS";
const GET_ALL_USERS_FAILURE = "SUEX_MILITARY/GET_ALL_USERS_FAILURE";

const GET_USER_REQUEST = "SUEX_MILITARY/GET_USER_REQUEST";
const GET_USER_SUCCESS = "SUEX_MILITARY/GET_USER_SUCCESS";
const GET_USER_FAILURE = "SUEX_MILITARY/GET_USER_FAILURE";

const UPDATE_USER_REQUEST = "SUEX_MILITARY/UPDATE_USER_REQUEST";
const UPDATE_USER_SUCCESS = "SUEX_MILITARY/UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILURE = "SUEX_MILITARY/UPDATE_USER_FAILURE";

const RESET_USER_PASSWORD_REQUEST = "SUEX_MILITARY/RESET_USER_PASSWORD_REQUEST";
const RESET_USER_PASSWORD_SUCCESS = "SUEX_MILITARY/RESET_USER_PASSWORD_SUCCESS";
const RESET_USER_PASSWORD_FAILURE = "SUEX_MILITARY/RESET_USER_PASSWORD_FAILURE";

const GET_USER_BY_DISTRIBUTOR_ID_REQUEST =
  "SUEX_MILITARY/GET_USER_BY_DISTRIBUTOR_ID_REQUEST";
const GET_USER_BY_DISTRIBUTOR_ID_SUCCESS =
  "SUEX_MILITARY/GET_USER_BY_DISTRIBUTOR_ID_SUCCESS";
const GET_USER_BY_DISTRIBUTOR_ID_FAILURE =
  "SUEX_MILITARY/GET_USER_BY_DISTRIBUTOR_ID_FAILURE";

const LOGOUT_USER = "LOGOUT_USER";

const initialState = {
  loading: false,
  error: false,
  errorOtp: false,
  data: {
    authToken: null,
    user: null,
  },
  userList: [],
  emailCheck: false,
  resetPassword: false,
};

// const initialState = {
//   token: localStorage.getItem("token") ? true : false,
//   username: localStorage.getItem("token") ? username : null,
// };

const reducerUser = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        error: action.payload,
      };
    case LOGIN_OTP_REQUEST:
      return { ...state, loading: true, errorOtp: false };
    case LOGIN_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorOtp: false,
      };
    case LOGIN_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        errorOtp: action.payload,
      };
    case CHECK_EMAIL_REQUEST:
      return { ...state, loading: true, errorOtp: false };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailCheck: action.payload,
        errorOtp: false,
      };
    case CHECK_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        emailCheck: initialState.data,
        errorOtp: action.payload,
      };

    case GET_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          user: action.payload,
        },
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        error: action.payload,
      };
    case GET_USER_BY_DISTRIBUTOR_ID_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_USER_BY_DISTRIBUTOR_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
      };
    case GET_USER_BY_DISTRIBUTOR_ID_FAILURE:
      return {
        ...state,
        loading: false,
        userList: initialState.userList,
        error: action.payload,
      };
    case GET_ALL_USERS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        userList: initialState.userList,
        error: action.payload,
      };
    case UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case UPDATE_USER_SUCCESS:
      const updatedUser = action.payload;
      let updatedUserList = [
        ...state.userList.filter((user) => user.userId !== updatedUser.userId),
        updatedUser,
      ];

      //sort by last name and first name

      updatedUserList = updatedUserList.sort((a, b) => {
        if (a.lastname !== b.lastname) {
          return a.lastname > b.lastname ? 1 : b.lastname > a.lastname ? -1 : 0;
        } else {
          return a.firstname > b.firstname
            ? 1
            : b.firstname > a.firstname
            ? -1
            : 0;
        }
      });

      return {
        ...state,
        loading: false,
        userList: updatedUserList,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_USER_PASSWORD_REQUEST:
      return { ...state, resetLoading: true, error: false };
    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetLoading: false,
        resetPassword: action.payload,
      };
    case RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        resetLoading: false,
        resetPassword: initialState.resetPassword,
        error: action.payload,
      };

    case LOGOUT_USER:
      return {
        token: false,
        username: false,
      };

    default:
      return state;
  }
};

export default reducerUser;

export const loginUser = (credentials) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER_REQUEST,
  });

  try {
    if (!credentials) {
      const userLs = localStorage.getItem("user");
      if (userLs) {
        const user = JSON.parse(userLs);
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user,
        });
      } else {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: "NO_USER_IN_LOCAL_STORAGE",
        });
      }
    } else {
      const { url, method, headers, data } = api.login(credentials);

      const response = await axios({
        url,
        method,
        headers,
        data,
      });

      if (response) {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: response.data,
        });

        localStorage.setItem("user", JSON.stringify(response.data));
        // SET ACTIVITY COOKIE
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        document.cookie =
          "activityCookie=cookieValue;expires=" + date.toUTCString();

        // SET OTP COOKIE ONLY IF DOESN'T EXIST
      } else {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: "no_user_found",
        });
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === "Account expired"
    ) {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: "expired_account",
      });
    } else {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: "error_login",
      });
    }
  }
};
export const loginUserWithOtp =
  (credentials, isRememberChecked) => async (dispatch) => {
    dispatch({
      type: LOGIN_OTP_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.loginWithOtp(credentials);
      const response = await axios({
        url,
        method,
        headers,
        data,
      });

      if (response) {
        dispatch({
          type: LOGIN_OTP_SUCCESS,
          payload: response.data,
        });
        localStorage.setItem("user", JSON.stringify(response.data));
        // SET ACTIVITY COOKIE
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        document.cookie =
          "activityCookie=cookieValue;expires=" + date.toUTCString();

        // SET OTP COOKIE ONLY IF DOESN'T EXIST, remember me is checked
        if (!!isRememberChecked) {
          const cookie = document?.cookie;
          const cookieArr = cookie?.split(";");
          const otpChecked = cookieArr?.find((c) => c.includes("otpChecked"));
          if (!otpChecked) {
            date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
            // encrypt email and expires date
            const encryptedEmail = CryptoJS.AES.encrypt(
              credentials.email,
              "SU3XM1L1T4R1"
            ).toString();

            // set cookie with mail and expires date
            document.cookie =
              "otpChecked=" +
              encryptedEmail +
              "; expires=" +
              date.toUTCString() +
              "; path=/";
          } else {
            let otpCheckedDecrypted = otpChecked.split("=")[1];
            otpCheckedDecrypted = otpCheckedDecrypted + "=";
            const decryptedEmail = CryptoJS.AES.decrypt(
              otpCheckedDecrypted,
              "SU3XM1L1T4R1"
            ).toString(CryptoJS.enc.Utf8);
            if (decryptedEmail !== credentials?.email) {
              date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
              // encrypt email and expires date
              const encryptedEmail = CryptoJS.AES.encrypt(
                credentials.email,
                "SU3XM1L1T4R1"
              ).toString();
              // set cookie with mail and expires date
              document.cookie =
                "otpChecked=" +
                encryptedEmail +
                "; expires=" +
                date.toUTCString() +
                "; path=/";
            }
          }
        }
      } else {
        dispatch({
          type: LOGIN_OTP_FAILURE,
          payload: "no_user_found",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Account expired"
      ) {
        dispatch({
          type: LOGIN_OTP_FAILURE,
          payload: "expired_account",
        });
      } else {
        dispatch({
          type: LOGIN_OTP_FAILURE,
          payload: "error_login",
        });
      }
    }
  };

export const checkEmail = (credentials) => async (dispatch) => {
  dispatch({
    type: CHECK_EMAIL_REQUEST,
  });

  try {
    const { url, method, headers, data } = api.checkEmail(credentials);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });

    if (response) {
      dispatch({
        type: CHECK_EMAIL_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: CHECK_EMAIL_FAILURE,
        payload: "no_user_found",
      });
    }
  } catch (error) {
    dispatch({
      type: CHECK_EMAIL_FAILURE,
      payload: "error_login",
    });
  }
};

export const getUser = () => async (dispatch) => {
  dispatch({
    type: GET_USER_REQUEST,
  });
  try {
    const { url, method, headers } = api.getUser();

    const response = await axios({
      url,
      method,
      headers,
    });

    if (response) {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_USER_FAILURE,
        payload: "Couldn't find user",
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_FAILURE,
      payload: error,
    });
  }
};

export const getAllUsers = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_USERS_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.getAllUsers();

    const response = await axios({
      url,
      method,
      headers,
    });

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAILURE,
      payload: error,
    });
  }
};

export const getUsersByDistributorId = (distributorId) => async (dispatch) => {
  dispatch({
    type: GET_USER_BY_DISTRIBUTOR_ID_REQUEST,
  });
  try {
    const { url, method, headers, data } =
      api.getUsersByDistributorId(distributorId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: GET_USER_BY_DISTRIBUTOR_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_BY_DISTRIBUTOR_ID_FAILURE,
      payload: error,
    });
  }
};

export const updateUser = (userId, updateData) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.updateUser(userId, updateData);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};

export const activeUserAllCourses = (userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.activeUserAllCourses(userId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};
export const activeUserAllDocumentsUser = (userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } =
      api.activeUserAllDocumentsUser(userId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};
export const activeUserAllDocumentsCourses = (userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } =
      api.activeUserAllDocumentsCourses(userId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};
export const activeUserAllFirmware = (userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.activeUserAllFirmware(userId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};
export const activeUserAllSoftware = (userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.activeUserAllSoftware(userId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};
export const activeUserAllPricelists = (userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.activeUserAllPricelists(userId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};

export const updateUserCourse =
  (userCourseId, updateData) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.updateUserCourse(
        userCourseId,
        updateData
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const createUserCourse = (userId, courseId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.createUserCourse(
      userId,
      courseId
    );

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};

export const updateUserFirmware =
  (userFirmwareId, updateData) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.updateUserFirmware(
        userFirmwareId,
        updateData
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const createUserFirmware =
  (userId, firmwareCode) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.createUserFirmware(
        userId,
        firmwareCode
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const updateUserDocument =
  (userDocumentId, updateData) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.updateUserDocument(
        userDocumentId,
        updateData
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const createUserDocument =
  (userId, documentCode) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.createUserDocument(
        userId,
        documentCode
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const updateUserSoftware =
  (userSoftwareId, updateData) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.updateUserSoftware(
        userSoftwareId,
        updateData
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const createUserSoftware =
  (userId, softwareCode) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.createUserSoftware(
        userId,
        softwareCode
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };
export const updateUserPricelist =
  (userPricelistId, updateData) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.updateUserPricelist(
        userPricelistId,
        updateData
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const createUserPricelist =
  (userId, pricelistCode) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.createUserPricelist(
        userId,
        pricelistCode
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      });
    }
  };

export const createNewOtp = (email) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.createNewOtp(email);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};
export const resetUserPassword = (user) => async (dispatch) => {
  dispatch({
    type: RESET_USER_PASSWORD_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.resetUserPassword(user);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: RESET_USER_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: RESET_USER_PASSWORD_FAILURE,
      payload: error,
    });
  }
};

export const logoutUser = (history) => (dispatch) => {
  localStorage.clear();
  history.push("/");
  dispatch({
    type: LOGOUT_USER,
  });
};
