import "./OneDigitInput.scss";

export const SingleDigitInput = (props) => {
  return (
    <>
      <input
        className="single-digit-input"
        maxLength={props.maxLength}
        onChange={(e) => {
          props.handleFocus(e);
          props.handleChange(props.target, e.target.value, e);
        }}
        defaultValue={props.defaultValue}
        type={"number"}
        value={props.value}
        onKeyDown={props.onKeyPress}
        disabled={props.disabled}
        autocomplete="off"
      />
    </>
  );
};
