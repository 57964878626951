import "./Navbar.scss";
import RequestAccess from "./request-access/RequestAccess";
import Login from "./login/Login";
import SelectLanguage from "./select-language/SelectLanguage";
import useTranslation from "utils/useTranslation";
import { Email, User } from "assets/icons/icons";
import Logo from "shared/logo/Logo";
import { Close } from "assets/icons/icons";
import useComponentVisible from "utils/useComponentVisible";

const Navbar = () => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { t } = useTranslation();

  return (
    <div className="welcome-page-navbar">
      {isComponentVisible && (
        <div className="overlay">
          <div className="overlay__popup" ref={ref}>
            <Close onClick={() => setIsComponentVisible(false)} />
            {isComponentVisible}
          </div>
        </div>
      )}
      <div></div>
      {/* <Logo /> */}

      <div className="welcome-page-navbar__links">
        <div className="navbar-access">
          <div
            className="btn primary short-pad space"
            onClick={() =>
              setIsComponentVisible(
                <RequestAccess setIsComponentVisible={setIsComponentVisible} />
              )
            }
          >
            <Email />
            {t("request")}
          </div>

          <div
            className="btn secondary light short-pad space"
            onClick={() => setIsComponentVisible(<Login />)}
          >
            <User />
            {t("login")}
          </div>
        </div>

        <SelectLanguage />
      </div>
    </div>
  );
};

export default Navbar;
