import { ArrowDown, ArrowUp, Close } from "assets/icons/icons";
import { useState } from "react";
import useComponentVisible from "utils/useComponentVisible";
import "./Select.scss";

const Select = (props) => {
  const [active, setActive] = useState(props.placeholder);

  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false, true);

  const handleChange = (option) => {
    setIsComponentVisible(false);
    setActive(option.userLevel || option.label || option);
    if (props.target) {
      props.onChange(props.target, option);
    } else {
      props.setSelectedCategory(option.levelCode || option.value || option);
    }
  };

  const handleReset = (event) => {
    event.stopPropagation();
    setActive(props.placeholder);
    if (props.target) {
      props.onChange(props.target, "");
    } else {
      props.setSelectedCategory("");
    }
  };

  return (
    <div
      ref={ref}
      className={`select-component ${props.normalFont ? "normal-font" : ""} ${
        isComponentVisible ? "active" : ""
      }`}
    >
      <div className="placeholder bb-dark" onClick={toggling}>
        <span className="select-component-span">{active}</span>

        {active !== props.placeholder ? (
          <Close onClick={handleReset} />
        ) : isComponentVisible ? (
          <ArrowUp />
        ) : (
          <ArrowDown />
        )}
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {props.options.map((option, i) => (
            <div
              key={`key_options_${i}`}
              onClick={() => handleChange(option)}
              className="option"
            >
              {option.userLevel || option.label || option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
