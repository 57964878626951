import ProductList from "components/product-list/ProductList";
// import { items } from "debug-items/dpv";
import { useDispatch, useSelector } from "react-redux";
import Placeholders from "components/placeholder/Placeholder";
import { getAllProducts } from "reducers/products";
import { useEffect, useState } from "react";

const Dpd = () => {
  const dispatch = useDispatch();
  const [isAllVisible, setIsAllVisible] = useState(true);
  const [filteredDpd, setFilteredDpd] = useState([]);
  const isAdmin = useSelector(({ user }) => user.data.user.role === "admin");
  useEffect(() => {
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);

  const products = useSelector(({ products }) => products.data);

  // const singleProduct = useSelector(({ products }) => products.singleProduct);
  const loading = useSelector(({ products }) => products.loading);
  const dpd = products?.filter(
    (product) => product.productGroup === "DPD SYSTEM"
  );
  const orderedDpd = dpd?.sort((a, b) => {
    if (a.productCode === "71624") return -1;
    if (b.productCode === "71624") return 1;
    if (a.productCode === "72294") return -1;
    if (b.productCode === "72294") return 1;
    if (a.productCode === "72292") return -1;
    if (b.productCode === "72292") return 1;
    if (a.productCode === "72293") return -1;
    if (b.productCode === "72293") return 1;
    if (a.productCode === "72295") return -1;
    if (b.productCode === "72295") return 1;
    if (a.productCode === "72296") return -1;
    if (b.productCode === "72296") return 1;
    if (a.productCode === "71174") return -1;
    if (b.productCode === "71174") return 1;
    if (a.productCode === "71623") return -1;
    if (b.productCode === "71623") return 1;
    if (a.productCode === "71629") return -1;
    if (b.productCode === "71629") return 1;
    if (a.productCode === "71684") return -1;
    if (b.productCode === "71684") return 1;
    return 0;
  });

  const dpdFilter = orderedDpd?.filter((product) => product.isActive !== 0);

  const handleSetIsAllVisible = () => {
    setIsAllVisible(!isAllVisible);
  };

  useEffect(() => {
    if (!isAllVisible) {
      setFilteredDpd(dpd);
    } else {
      setFilteredDpd(dpdFilter);
    }
  }, [isAllVisible]);

  return (
    <div className="container">
      {loading ? (
        <>
          <div className="heading-portal">
            <h1>dpd</h1>
          </div>
          <Placeholders type={"productsList"} number={2} />
        </>
      ) : (
        <ProductList
          compare
          list={filteredDpd.length > 0 ? filteredDpd : dpdFilter}
          productGroup="dpd"
          title="dpd"
          isAllVisible={isAllVisible}
          handleSetIsAllVisible={handleSetIsAllVisible}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default Dpd;
