import { baseUrl } from "config/server";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "reducers/products";
import GoBack from "shared/go-back/GoBack";
import useTranslation from "utils/useTranslation";
import "./ComparisonPage.scss";

const specs = [
  "productCode",
  "unitOfMeasure",
  "width",
  "widthWithCases",
  "widthFolded",
  "length",
  "lengthWithCases",
  "height",
  "heightWithCases",
  "bodyDiameter",
  "weight",
  "weightMainPartsInAir",
  "weightWithoutBattery",
  "weightWithBattery",
  "weightWithBatterySaltWater",
  "weightWithCases",
  "materialType",
  "maxStaticThrust",
  "topSpeed",
  "cruiseSpeed",
  "rangeFullTrigger",
  "runTimeFullTrigger",
  "rangeCruiseSpeed",
  "runTimeCruiseSpeed",
  "maxOperationalDepth",
  "testDepth",
  "pressureSensorType",
  "accuracyCalibratedRange",
  "calibratedPressureRange",
  "buoyancyTrim",
  "dpvInterface",
  "inWaterUsageTemperature",
  "inAirUsageTemperature",
  "storageTemperature",
  "batteryType",
  "batterySize",
  "suitableBatteryType",
  "batteryOperatingLife",
  "maxVoltage",
  "maxChargingCurrent",
  "chargerCode",
  "nominalVoltage",
  "nominalCapacity",
  "chargerPowerSupply",
  "maxRechargingTime",
  "plugExternalSupply",
  "frequencyBands",
  "byPass",
  "diveLogCapacity",
  "connectivity",
  "bluetoothSwitch",
  "bluetoothConnectionMotor",
  "bluetoothConnectionBattery",
  "compassResolution",
  "compassAccuracy",
  "compassTiltCompensation",
  "cutOffInside",
  "wristAttachment",
  "oringSize",
  "oringMaterial",
  "display",
  "displayType",
  "displayDimension",
];

const ComparisonPage = ({ list }) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const products = useSelector(({ products }) => products.data);
  const dispatch = useDispatch();
  const comparedProducts = products.filter(({ productCode }) =>
    list.includes(productCode)
  );

  useEffect(() => {
    if (products?.length === 0) {
      dispatch(getAllProducts());
    }
  }, []);

  return (
    <div className="comparison-page">
      <GoBack />

      <div className="comparison-page__list-wrapper">
        <div
          className={
            comparedProducts.length > 2
              ? "comparison-three-page-list"
              : "comparison-two-page-list"
          }
        >
          {comparedProducts.map((product, index) => (
            <div
              key={`key_comparedProducts_${product.productCode}`}
              className="head-section"
            >
              <div className="image-container">
                <img
                  src={`${baseUrl}/images/${product.productCode}.png`}
                  alt={`${product.nameEn}_image`}
                />
              </div>
              {activeLanguage.includes("it") ? (
                <h3 className="no-mar">{product.nameIt}</h3>
              ) : (
                <h3 className="no-mar">{product.nameEn}</h3>
              )}
            </div>
          ))}
        </div>
        {comparedProducts.find((product) => !!product.description) && (
          <div
            className={
              comparedProducts.length > 2
                ? "comparison-three-page-list"
                : "comparison-two-page-list"
            }
          >
            {comparedProducts.map((product) => (
              <div
                key={`key_comparedProducts_2_${product.productCode}`}
                className="item-comparison__section"
              >
                <h3>{t("description", text)}</h3>

                <p>{product.description}</p>
              </div>
            ))}
          </div>
        )}
        {specs.map((element, i) => {
          return (
            <>
              {comparedProducts.find((product) => !!product[element]) && (
                <div
                  key={`key_comparedProducts_3_${element}`}
                  className={
                    comparedProducts.length > 2
                      ? "comparison-three-page-list"
                      : "comparison-two-page-list"
                  }
                >
                  {comparedProducts.map((product) => (
                    <div
                      key={`key_comparedProducts_4_${product.productCode}`}
                      className="item-comparison__section __specs"
                    >
                      <h3>{t(element, text)}</h3>

                      <p>{product[element]}</p>
                    </div>
                  ))}
                </div>
              )}
            </>
          );
        })}
        {/* <div
          className={
            products.filter(({ productCode }) => list.includes(productCode))
              .length > 2
              ? "comparison-three-page-list"
              : "comparison-two-page-list"
          }
        >
          {products
            .filter(({ productCode }) => list.includes(productCode))
            .map((product, i) => (
              <div key={i} className="item-comparison">
                <div className="head-section">
                  <div className="image-container">
                    <img
                      src={`${baseUrl}/images/${product.productCode}.png`}
                      alt={`${product.nameEn}_image`}
                    />
                  </div>
                  {activeLanguage.includes("it") ? (
                    <h3 className="no-mar">{product.nameIt}</h3>
                  ) : (
                    <h3 className="no-mar">{product.nameEn}</h3>
                  )}
                </div>
                {!!product.description && (
                  <div className="item-comparison__section">
                    <h3>{t("description", text)}</h3>

                    <p>{product.description}</p>
                  </div>
                )}

                {specs.map((element, i) => {
                  return (
                    <>
                      {product[element] && (
                        <div
                          key={i}
                          className="item-comparison__section __specs"
                        >
                          <h3>{t(element, text)}</h3>

                          <p>{product[element]}</p>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default ComparisonPage;

const text = [
  {
    id: "description",
    en: "description",
    it: "descrizione",
  },
  {
    id: "specs",
    en: "technical data",
    it: "specifiche",
  },
  { id: "width", en: "width", it: "Lunghezza" },
  { id: "length", en: "length", it: "Larghezza" },
  { id: "height", en: "height", it: "Altezza" },
  { id: "bodyDiameter", en: "body diameter", it: "Diametro corpo" },
  {
    id: "weightWithoutBattery",
    en: "weight without battery",
    it: "Peso senza batteria",
  },
  {
    id: "weightWithBattery",
    en: "weight with battery",
    it: "Peso con batteria",
  },
  { id: "materialType", en: "material type", it: "Materiale corpo" },
  {
    id: "maxStaticThrust",
    en: "max static thrust",
    it: "Forza di trazione statica max",
  },
  { id: "topSpeed", en: "top speed", it: "Velocità massima" },
  {
    id: "maxOperationalDepth",
    en: "max operational depth",
    it: "Massima profondità operativa",
  },
  { id: "buoyancyTrim", en: "buoyancy/Trim", it: "Galleggiabilità / Assetto" },
  {
    id: "rangeFullTrigger",
    en: "range full trigger",
    it: "Autonomia velocità massima",
  },
  {
    id: "rangeCruiseSpeed",
    en: "range cruise speed",
    it: "Autonomia velocità crociera",
  },
  {
    id: "runtTimeFullTrigger",
    en: "run time full trigger",
    it: "Durata velocità massima",
  },
  {
    id: "runTimeCruiseSpeed",
    en: "run time cruise speed",
    it: "Durata velocità crociera",
  },
  {
    id: "maxOperationDepth",
    en: "max operational depth",
    it: "massima profondità operativa",
  },
  { id: "testDepth", en: "test depth", it: "massima profondità testata" },
  {
    id: "inWaterUsageTemperature",
    en: "in water usage temperature",
    it: "temperatura acqua",
  },
  { id: "batteryType", en: "battery type", it: "Tipo di batteria" },
  { id: "nominalVoltage", en: "nominal voltage", it: "tensione nominale" },
  { id: "nominalCapacity", en: "nominal capacity", it: "capacità nominale" },
  {
    id: "chargerPowerSupply",
    en: "charger power supply",
    it: "caricatore di alimentazione",
  },
  {
    id: "maxRechargingTime",
    en: "max recharging time",
    it: "tempo di ricarica massimo",
  },
];
