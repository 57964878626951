import { useDispatch, useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "./Info.scss";
import { infoDealer } from "./Info.items";
import { Minus, User } from "assets/icons/icons";
import moment from "moment";
import { baseUrl } from "config/server";
import { useEffect } from "react";
import { getUser } from "reducers/user";
import { insertUserHistoryDownload } from "reducers/history";

const Info = () => {
  const user = useSelector(({ user }) => user.data.user);
  const userList = useSelector(({ user }) => user.userList);
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const isUser = user?.role === "user";
  const isAdmin = user?.role === "admin";
  const isDistributor = userList?.length > 0;
  const account = useSelector(({ user }) => user.data.user);
  const dispatch = useDispatch();
  const isPricelist = account?.pricelist?.every(
    (pricelist) => pricelist.isActive === 0
  );

  const timestamp = () => {
    return new Date().getTime();
  };

  useEffect(() => {
    if (!!user) return;
    console.log("get user from info");
    dispatch(getUser());
  }, []);

  useEffect(() => {
    console.log("pricelist", isPricelist);
  }, [isPricelist]);

  const handleSetDownload = (content) => {
    dispatch(insertUserHistoryDownload(content));
  };

  return (
    <div className="account-info">
      <div className="account-info__wrapper" style={{ width: "50%" }}>
        {/* USER INFO */}
        {!!user && (
          <div className="account-info__info info-section bb-dark">
            <User />

            <div className="account-info">
              <div className="account-info__username">
                {isAdmin
                  ? `${user.firstname} ${user.lastname}`
                  : isDistributor
                  ? `${user.companyName}`
                  : isUser
                  ? `${user.firstname} ${user.lastname}`
                  : ""}
              </div>

              {isAdmin ? (
                <div className="account-info__level">{t("admin", text)}</div>
              ) : isDistributor ? (
                <div className="account-info__level">
                  {t("distributor", text)}
                </div>
              ) : (
                <div className="account-info__level">
                  {t("level", text)} {user.level?.userLevel}
                </div>
              )}

              <div className="account-info__signup-from">
                {t("registeredFrom", text)}{" "}
                {moment(user.createdAt).format("DD-MM-YYYY")}
              </div>
            </div>
          </div>
        )}
        {/* DEALER */}
        {isDistributor && (
          <div className="account-info__dealer info-section bb-dark">
            {isDistributor ? (
              <h4>{t("datiRiferimento", text)}</h4>
            ) : (
              <h4>{t("dealer", text)}</h4>
            )}

            {isDistributor && (
              <div className="dealer-info-list">
                {infoDealer.map((element, i) => (
                  <div
                    key={`key_infodelear_${element.title}_${i}`}
                    className="dealer-info-list__item"
                  >
                    {element.img}
                    <p>{user[element.title]}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {/* PRICELIST */}
        {user?.pricelist?.length > 0 && !isPricelist && (
          <div className="account-info__download-pricelist info-section">
            <h4>{t("download", text)}</h4>
            <div className="download-list">
              {user?.pricelist?.map((pricelist) => {
                if (pricelist.isActive === 1) {
                  return (
                    <a
                      key={`key_pricelist_5_${pricelist.id}`}
                      href={`${baseUrl}/${
                        pricelist.pricelistUrl
                      }?ts=${timestamp()}`}
                      rel="noreferrer"
                      className="btn tertiary pad"
                      target={"_blank"}
                      style={{ maxWidth: "200px" }}
                      onClick={() =>
                        handleSetDownload({
                          userId: account.userId,
                          contentId: pricelist.id,
                          contentType: "pricelist",
                        })
                      }
                    >
                      {pricelist.nameIt}
                    </a>
                  );
                } else return "";
              })}
            </div>
          </div>
        )}
        {/* ABILITATIONS */}
        {isUser && user?.courses?.length > 0 && (
          <div className="account-info__abilitations info-section bb-dark">
            <h4>{t("abilitations", text)}</h4>
            <div className="abilitations__list">
              {user?.courses?.length > 0 &&
                user?.courses
                  ?.filter((course) => course.isActive)
                  ?.map((course, i) => (
                    <div
                      key={`key_course_6_${course.courseCode}`}
                      className="abilitations-list__abilitation"
                    >
                      <Minus />
                      <p style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                        {isIta ? course.nameIt : course.nameEn}
                      </p>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Info;

const text = [
  {
    id: "dealerName",
    en: "Dealer Name",
    it: "Nome rivenditore",
  },
  {
    id: "abilitations",
    en: "abilitations",
    it: "abilitazioni",
  },
  {
    id: "dealer",
    en: "reference dealer",
    it: "distributore di riferimento",
  },
  {
    id: "nameDealer",
    en: "Name Dealer",
    it: "Nome distributore",
  },
  {
    id: "download",
    en: "download new suex pricelist",
    it: "scarica il listino prezzi",
  },
  {
    id: "level",
    en: "level",
    it: "livello",
  },
  {
    id: "registeredFrom",
    en: "Registered from",
    it: "Registrato dal",
  },
  {
    id: "pricelist",
    en: "pricelist",
    it: "listino prezzi",
  },
  {
    id: "pricelistMaintenance",
    en: "pricelist maintenance",
    it: "listino prezzi manutenzione",
  },
  {
    id: "distributor",
    en: "distributor",
    it: "distributore",
  },
  {
    id: "admin",
    en: "admin",
    it: "amministratore",
  },
  {
    id: "datiRiferimento",
    it: "Dati di riferimento",
    en: "Reference data",
  },
];
